<template>
  <div class="index-container">{{appName}}运营管理后台~</div>
</template>

<script>
  export default {
    name: 'Welcome',
    data() {
      return {
        appName: process.env.VUE_APP_BASE_TITLE,
      }
    }
  }
</script>

<style lang="scss" scoped></style>
