var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-form",
        {
          attrs: {
            "label-width": "100px",
            model: _vm.listQuery,
            size: "small",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  attrs: {
                    md: { span: 8 },
                    sm: { span: 8 },
                    xl: { span: 8 },
                    xs: { span: 8 },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "配置项名称" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "请输入配置项名称",
                        },
                        model: {
                          value: _vm.listQuery.key,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "key", $$v)
                          },
                          expression: "listQuery.key",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    md: { span: 12 },
                    sm: { span: 12 },
                    xl: { span: 12 },
                    xs: { span: 12 },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-search",
                            size: "mini",
                            type: "primary",
                          },
                          on: { click: _vm.getList },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-refresh-left", size: "mini" },
                          on: { click: _vm.reset },
                        },
                        [_vm._v(" 重置 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-plus",
                            size: "mini",
                            type: "primary",
                          },
                          on: { click: _vm.handleAdd },
                        },
                        [_vm._v(" 新增 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { border: "", data: _vm.tableData, stripe: "" },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png"),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "序号", width: "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm.listQuery.pageNumber - 1) *
                              _vm.listQuery.pageSize +
                              scope.$index +
                              1
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "参数类型",
              prop: "type",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == 0
                      ? _c("span", [_vm._v("文本")])
                      : _vm._e(),
                    scope.row.type == 1
                      ? _c("span", [_vm._v("多行文本")])
                      : _vm._e(),
                    scope.row.type == 2
                      ? _c("span", [_vm._v("富文本编辑器")])
                      : _vm._e(),
                    scope.row.type == 3
                      ? _c("span", [_vm._v("图片")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "配置项名称",
              prop: "name",
              "show-overflow-tooltip": "",
              width: "200",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "配置key",
              prop: "key",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "参数值",
              prop: "value",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == 0 || 1
                      ? _c("span", [_vm._v(_vm._s(scope.row.value))])
                      : _vm._e(),
                    scope.row.type == 2
                      ? _c("span", [_vm._v("富文本（详情中查看）")])
                      : _vm._e(),
                    scope.row.type == 3
                      ? _c("span", [_vm._v("图片（详情中查看）")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "配置描述",
              prop: "remark",
              "show-overflow-tooltip": "",
              width: "200",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row.id)
                          },
                        },
                      },
                      [_vm._v(" 删除 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleCheckDetail(
                              scope.row.id,
                              scope.row.type
                            )
                          },
                        },
                      },
                      [_vm._v(" 详情 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Dialog", {
        attrs: {
          height: _vm.dailog.height,
          "model-value": _vm.dailog.visible,
          title: _vm.dailog.title,
          visible: _vm.dailog.visible,
          width: _vm.dailog.width,
        },
        on: {
          handleCloseDialog: _vm.handleCloseDialog,
          handleConfirm: _vm.handleConfirm,
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    ref: "formData",
                    attrs: {
                      "label-width": "100px",
                      model: _vm.formData,
                      size: "small",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "配置项类型" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "90%" },
                                attrs: { placeholder: "请配置项类型" },
                                on: { change: _vm.selectType },
                                model: {
                                  value: _vm.formData.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "type", $$v)
                                  },
                                  expression: "formData.type",
                                },
                              },
                              _vm._l(_vm.configType, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "配置项名称", prop: "name" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "90%" },
                              attrs: {
                                clearable: "",
                                placeholder: "请输入配置项名称",
                              },
                              model: {
                                value: _vm.formData.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "name", $$v)
                                },
                                expression: "formData.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "配置key", prop: "key" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "90%" },
                              attrs: {
                                clearable: "",
                                placeholder: "请输入配置key",
                              },
                              model: {
                                value: _vm.formData.key,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "key", $$v)
                                },
                                expression: "formData.key",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "配置描述", prop: "remark" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "90%" },
                              attrs: {
                                clearable: "",
                                placeholder: "请输入配置描述",
                              },
                              model: {
                                value: _vm.formData.remark,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "remark", $$v)
                                },
                                expression: "formData.remark",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.formData.type == 0
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "配置值", prop: "value" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "90%" },
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入配置描述",
                                  },
                                  model: {
                                    value: _vm.formData.value,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "value", $$v)
                                    },
                                    expression: "formData.value",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.formData.type == 1
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "配置值", prop: "value" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "90%" },
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入配置描述",
                                    rows: 3,
                                    type: "textarea",
                                  },
                                  model: {
                                    value: _vm.formData.value,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "value", $$v)
                                    },
                                    expression: "formData.value",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.formData.type == 2
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "配置值", prop: "value" } },
                              [
                                _c("vab-quill", {
                                  ref: "vab-quill",
                                  staticStyle: { width: "90%" },
                                  attrs: {
                                    "min-height": 300,
                                    options: _vm.options,
                                  },
                                  model: {
                                    value: _vm.formData.value,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "value", $$v)
                                    },
                                    expression: "formData.value",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.formData.type == 3
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "配置值", prop: "value" } },
                              [
                                _c("van-uploader", {
                                  attrs: {
                                    accept: "image/jpeg",
                                    "before-delete": _vm.deleteInsertPic,
                                    "before-read": _vm.beforeRead,
                                    "max-count": 30,
                                    multiple: "",
                                    "preview-full-image": _vm.showPic,
                                  },
                                  model: {
                                    value: _vm.updateFileList,
                                    callback: function ($$v) {
                                      _vm.updateFileList = $$v
                                    },
                                    expression: "updateFileList",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Dialog", {
        attrs: {
          height: _vm.dailog.height,
          "model-value": _vm.dailog.visible,
          showfoot: _vm.dailog.showfoot,
          title: _vm.dailog.title1,
          visible: _vm.dailog.visible1,
          width: _vm.dailog.width,
        },
        on: { handleCloseDialog: _vm.handleCloseDialog1 },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-descriptions",
                  {
                    staticClass: "margin-top",
                    attrs: { border: "", column: 2, size: _vm.size, title: "" },
                  },
                  [
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [
                          _vm._v("配置项类型"),
                        ]),
                        _vm.drawerData.type == 0
                          ? _c("span", [_vm._v("文本")])
                          : _vm._e(),
                        _vm.drawerData.type == 1
                          ? _c("span", [_vm._v("多行文本")])
                          : _vm._e(),
                        _vm.drawerData.type == 2
                          ? _c("span", [_vm._v("富文本编辑器")])
                          : _vm._e(),
                        _vm.drawerData.type == 3
                          ? _c("span", [_vm._v("图片")])
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [
                          _vm._v("配置项名称"),
                        ]),
                        _vm._v(" " + _vm._s(_vm.drawerData.name) + " "),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v("配置key")]),
                        _vm._v(" " + _vm._s(_vm.drawerData.key) + " "),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v("配置描述")]),
                        _vm._v(" " + _vm._s(_vm.drawerData.remark) + " "),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v("参数值")]),
                        _vm.drawerData_type == 0
                          ? _c("div", {}, [
                              _vm._v(" " + _vm._s(_vm.drawerData.value) + " "),
                            ])
                          : _vm._e(),
                        _vm.drawerData_type == 1
                          ? _c("div", {}, [
                              _vm._v(" " + _vm._s(_vm.drawerData.value) + " "),
                            ])
                          : _vm._e(),
                        _vm.drawerData_type == 2
                          ? _c("div", { staticClass: "value" }, [
                              _c("div", {
                                staticClass: "vab",
                                domProps: {
                                  innerHTML: _vm._s(_vm.drawerData.value),
                                },
                              }),
                            ])
                          : _vm._e(),
                        _vm.drawerData_type == 3
                          ? _c(
                              "div",
                              { staticClass: "value" },
                              _vm._l(_vm.drawerData_type_3, function (item) {
                                return _c("img", {
                                  key: item.id,
                                  attrs: { alt: "", src: item },
                                })
                              }),
                              0
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Pagination", {
        attrs: {
          limit: _vm.listQuery.pageSize,
          page: _vm.listQuery.pageNumber,
          "total-count": _vm.totalCount,
        },
        on: {
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "pageSize", $event)
          },
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "pageNumber", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }