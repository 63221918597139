<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          :model="queryParam"
          @submit.native.prevent
        >
          <el-form-item label="地区">
            <el-cascader
              v-model="areaCode"
              clearable
              :options="areaList"
              placeholder="请选择地区"
              :props="areaProps"
              style="width: 100%"
              @change="handleChange"
            />
          </el-form-item>
          <el-form-item label="商户名称" label-width="80px">
            <el-input
              v-model="queryParam.name"
              clearable
              placeholder="请输入商户名称/登录账户"
            />
          </el-form-item>
          <el-form-item label="联系方式" label-width="80px">
            <el-input
              v-model="queryParam.contactPhone"
              clearable
              placeholder="请输入联系方式"
            />
          </el-form-item>
          <el-form-item label="商户类型" label-width="80px">
            <el-select
              v-model="queryParam.merchantType"
              clearable
              placeholder="请选择商户类型"
            >
              <el-option
                v-for="item in optionsShopType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!--          <el-form-item label="平台审核" label-width="80px">-->
          <!--            <el-select-->
          <!--              v-model="queryParam.status"-->
          <!--              clearable-->
          <!--              placeholder="请选择审核状态"-->
          <!--            >-->
          <!--              <el-option-->
          <!--                v-for="item in optionsExamine"-->
          <!--                :key="item.value"-->
          <!--                :label="item.label"-->
          <!--                :value="item.value"-->
          <!--              />-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label="易票联商户状态" label-width="120px">-->
          <!--            <el-select-->
          <!--              v-model="queryParam.accountStatus"-->
          <!--              clearable-->
          <!--              placeholder="请选择易票联商户状态"-->
          <!--            >-->
          <!--              <el-option-->
          <!--                v-for="item in accountStatusList"-->
          <!--                :key="item.value"-->
          <!--                :label="item.label"-->
          <!--                :value="item.value"-->
          <!--              />-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
          <el-form-item label="商户审核状态" label-width="100px">
            <el-select
              v-model="queryParam.status"
              clearable
              placeholder="请选择商户审核状态"
            >
              <el-option
                v-for="item in auditStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="getList"
            >
              查询
            </el-button>

            <el-button icon="el-icon-refresh-left" @click="searchReset">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :data="dataSource"
      stripe
      @select="selectRow"
      @select-all="selectRow"
    >
      <el-table-column align="center" label="序号" width="50">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="登录账户"
        prop="adminPhone"
        width="180"
      />
      <el-table-column
        align="center"
        label="商户类型"
        prop="merchantType"
        width="150"
      >
        <template slot-scope="scope">
          <span>{{ merchantTypeFormat(scope.row.merchantType) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="商户全称"
        prop="name"
        width="180"
      />
      <el-table-column
        align="center"
        label="联系人姓名"
        prop="contactPerson"
        width="180"
      />
      <el-table-column
        align="center"
        label="联系人手机号"
        prop="contactPhone"
        width="150"
      />
      <!--      <el-table-column-->
      <!--        align="center"-->
      <!--        label="平台审核"-->
      <!--        prop="status"-->
      <!--        width="150"-->
      <!--      >-->
      <!--        <template slot-scope="scope">-->
      <!--          <span>{{ statusFormat(scope.row.status) }}</span>-->
      <!--        </template>-->
      <!--      </el-table-column>-->

      <!--      <el-table-column-->
      <!--        align="center"-->
      <!--        label="易票联商户状态"-->
      <!--        prop="accountStatus"-->
      <!--        width="160"-->
      <!--      >-->
      <!--        <template slot-scope="scope">-->
      <!--          <span>{{ shopStatusFormat(scope.row.accountStatus) }}</span>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column
        align="center"
        label="审核状态"
        prop="isAdmin"
        width="200"
      >
        <template v-if="statusFormat(scope.row.status)" slot-scope="scope">
          <el-tag v-if="scope.row.status === 0">
            {{ statusFormat(scope.row.status) }}
          </el-tag>
          <el-tag v-if="scope.row.status === 1" type="success">
            {{ statusFormat(scope.row.status) }}
          </el-tag>
          <el-tag v-if="scope.row.status === 2">
            {{ statusFormat(scope.row.status) }}
          </el-tag>
          <el-tag v-if="scope.row.status === 3" type="danger">
            {{ statusFormat(scope.row.status) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="注册时间" prop="createTime" />
      <!-- :min-width="is_true ? 70 : 170" width="is_true ? 70 : 170" -->
      <el-table-column align="center" fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="handleDetail(scope.row.id)"
          >
            详情
          </el-button>
          <!--          <el-button-->
          <!--            v-if="scope.row.status == 1 && scope.row.accountStatus !== 1"-->
          <!--            plain-->
          <!--            size="mini"-->
          <!--            style="margin-bottom: 10px"-->
          <!--            type="success"-->
          <!--            @click="merchantApply(scope.row.id)"-->
          <!--          >-->
          <!--            提交易票联开户-->
          <!--          </el-button>-->
          <!-- v-if="scope.row.status == 2" -->
          <el-button
            :disabled="scope.row.status != 2"
            size="mini"
            type="text"
            @click="updateStatusShowDialog(scope.row, 1)"
          >
            通过
          </el-button>
          <!-- 审核状态 平台审核状态 0-起草（未审核） 1-已审核 2-待审核 3-驳回 -->
          <!-- v-if="scope.row.status == 1 || scope.row.status == 2" -->
          <el-button
            :disabled="!(scope.row.status == 2 || scope.row.status == 1)"
            size="mini"
            type="text"
            @click="updateStatusShowDialog(scope.row, 3)"
          >
            驳回
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>

    <Pagination
      :limit.sync="queryParam.pageSize"
      :page.sync="queryParam.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />

    <Dialog
      :height="dialog.height"
      :model-value="dialog.visible"
      :title="dialog.title"
      :visible="dialog.visible"
      :width="dialog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="updateStatus"
    >
      <template #content>
        <el-form
          ref="queryParam"
          label-position="平台审核意见"
          label-width="100px"
          :model="queryParam"
          size="small"
        >
          <el-row>
            <el-row>
              <el-col :span="24">
                <div class="aa">
                  <el-input
                    v-model="queryParam.opinion"
                    placeholder="平台审核意见"
                    :rows="6"
                    style="width: 100%; resize: none"
                    type="textarea"
                  />
                </div>
              </el-col>
            </el-row>
          </el-row>
        </el-form>
      </template>
    </Dialog>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialog.vue'
  import { BasePostMixin } from '@/mixins/BasePostMixin'
  import { merchantApply } from '@/api/merchant/shopList'
  import { regionList } from '@/api/system/user'
  export default {
    name: 'ShopApplylist',
    components: {
      Pagination,
      Dialog,
    },
    mixins: [BasePostMixin],
    data() {
      return {
        // height: 600,
        disabled: false,
        isWidth: false,
        dialog: {
          height: 150,
          title: '',
          visible: false,
          width: '400px',
        },
        queryParam: {
          id: '',
          status: this.$route.query?.status == 2 ? 2 : '',
          newStatus: '',
          opinion: '',
          provinceCode: '',
          cityCode: '',
          districtCode: '',
          accountStatus: '',
          auditStatus: '',
          adminPhone: '',
          contactPhone: '',
        },

        optionsShopType: [
          // {
          //   value: '',
          //   label: '全部',
          // },
          {
            value: 1,
            label: '个体工商户',
          },
          {
            value: 2,
            label: '企业',
          },
          {
            value: 3,
            label: '个人(小微)',
          },
          {
            value: 4,
            label: '政府事业单位',
          },
          {
            value: 9,
            label: '其他组织',
          },
        ],
        optionsExamine: [
          // {
          //   value: '',
          //   label: '全部',
          // },
          {
            value: 0,
            label: '起草(未提交)',
          },
          {
            value: 1,
            label: '已审核',
          },
          {
            value: 2,
            label: '待审核',
          },
          {
            value: 3,
            label: '驳回',
          },
        ],
        optionsCoupon: [
          // {
          //   value: '',
          //   label: '全部',
          // },
          {
            value: 0,
            label: '未开户',
          },
          {
            value: 10,
            label: '申请中',
          },
          {
            value: 20,
            label: '已开户',
          },
          {
            value: 30,
            label: '未通过',
          },
        ],
        value: '',
        input: '',
        totalCount: 0,
        tableData: [],
        loading: false,
        selection: [],
        url: {
          list: '/admin/merchant/shopList/page',
          handleEditUrl: '/admin/merchant/shopList/updateStatus',
        },
        areaCode: '',
        areaList: [],
        areaProps: {
          value: 'code',
          label: 'name',
          children: 'children',
        },
        accountStatusList: [
          {
            value: 0,
            label: '未开通',
          },
          {
            value: 1,
            label: '已开通',
          },
          {
            value: 2,
            label: '冻结',
          },
          {
            value: 3,
            label: '注销',
          },
          {
            value: 4,
            label: '止付',
          },
        ],
        auditStatusList: [
          {
            value: 0,
            label: '起草（未审核）',
          },
          {
            value: 1,
            label: '已审核',
          },
          {
            value: 2,
            label: '待审核',
          },
          {
            value: 3,
            label: '驳回',
          },
        ],
      }
    },
    created() {
      this.getRegionList()
    },
    mounted() {},
    methods: {
      handleCloseDialog() {
        this.dialog.visible = false
      },
      // 确认新增
      handleConfirm() {
        console.log('handleConfirm:', this.queryParam, 588)
      },

      //查询
      getList() {
        this.loadData()
      },
      //表格选择
      selectRow(selection, row) {
        this.selection = selection
        // console.log('单选===', selection)
        console.log('单选===', row)
      },
      // 修改状态
      updateStatusShowDialog(row, newStatus) {
        console.log(newStatus)
        this.dialog.visible = true
        this.dialog.title = '平台审核意见'
        this.queryParam.id = row.id
        // this.queryParam.status = row.status
        this.queryParam.newStatus = newStatus
        console.log('row:', row)
      },
      updateStatus() {
        this.editParam = {
          id: this.queryParam.id,
          status: this.queryParam.newStatus,
          reason: this.queryParam.opinion,
        }
        // console.log('123', this.editParam)
        this.handleEdit()
        this.handleCloseDialog()
        this.queryParam.opinion = ''
      },
      // 修改状态
      merchantApply(row) {
        // console.log('row:', row)
        this.editParam = {
          id: row,
        }
        this.$confirm('确定要提交开户吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            // console.log('editParam:', this.editParam)
            this.loading = true
            merchantApply(this.editParam).then((res) => {
              console.log('merchantApply>>>', res)
              if (res.code === 0) {
                this.$baseMessage(
                  '开通成功！',
                  'success',
                  'vab-hey-message-success'
                )
                this.loading = false
                this.loadData()
              } else {
                this.loading = false
              }
            })
          })
          .catch(() => {})
      },

      openStatusFormat(status) {
        if (status === 0) {
          return '未开户'
        } else if (status === 10) {
          return '申请中'
        } else if (status === 20) {
          return '已开户'
        } else if (status === 30) {
          return '未通过'
        }
      },
      accountStatusFormat(status) {
        if (status === 0) {
          return '未提交易票联审核'
        } else if (status === 1) {
          return '待易票联审核'
        } else if (status === 2) {
          return '易票联审核成功'
        } else if (status === 3) {
          return '易票联审核失败'
        }
      },
      statusFormat(status) {
        if (status === 0) {
          return '起草(未提交)'
        } else if (status === 1) {
          return '已审核'
        } else if (status === 2) {
          return '待审核'
        } else if (status === 3) {
          return '驳回'
        }
      },
      shopStatusFormat(status) {
        //易票联商户状态 账户状态。0：未开通，1：已开通，2：冻结；3：注销；4：止付
        if (status === 0) {
          return '未开通'
        } else if (status === 1) {
          return '已开通'
        } else if (status === 2) {
          return '冻结'
        } else if (status === 3) {
          return '注销'
        } else if (status === 4) {
          return '止付'
        }
      },
      //刷新
      refresh() {
        this.loadData()
      },
      //查看详情
      handleDetail(row) {
        this.$nextTick(() => {
          this.$router.push({
            path: '/admin/merchant/detail',
            query: { id: row },
          })
        })
      },
      merchantTypeFormat(status) {
        if (status === 1) {
          return '个体工商户'
        } else if (status === 2) {
          return '企业'
        } else if (status === 3) {
          return '个人(小微)'
        } else if (status === 4) {
          return '政府事业单位'
        } else if (status === 9) {
          return '其他组织'
        }
      },
      getRegionList() {
        regionList().then((res) => {
          console.log(res)
          if (res.code === 0) {
            this.areaList = res.data
          }
        })
      },
      handleChange(val) {
        console.log(val)
        this.queryParam.provinceCode = val[0]
        this.queryParam.cityCode = val[1]
        this.queryParam.districtCode = val[2]
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-range-editor.el-input__inner {
    width: 105%;
  }

  .el-dialog .el-form-item__label {
    width: 150px !important;
  }

  .aa .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__labe {
    width: 100%;
    margin-left: 0px !important;
  }

  .el-form-item__content {
    margin-left: 0px !important;
  }
</style>
