<template>
  <div class="personal-center-container">
    <el-row :gutter="20">
      <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
        <div class="index-container">
          <el-page-header :content="'商户详情'" @back="goBack" />
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="商户信息" name="baseInfo">
              <el-card shadow="never">
                <div class="card-title">基本信息</div>
                <el-descriptions
                  border
                  class="margin-top"
                  :column="4"
                  size="medium"
                >
                  <el-descriptions-item>
                    <template slot="label">商户名称</template>
                    {{ baseInfo?.name }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">商户ID</template>
                    {{ baseInfo?.id }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">商户简称</template>
                    {{ baseInfo?.shortName }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">商户类型</template>
                    {{ merchantTypeFormat(baseInfo.merchantType) }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">易票联状态</template>
                    {{ baseInfo.acqMerId ? '已开通' : '未开通' }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">易票联商户号</template>
                    {{ baseInfo?.acqMerId }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">联系人</template>
                    {{ baseInfo?.contactPerson }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">联系电话</template>
                    {{ baseInfo?.contactPhone }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">微信状态</template>
                    {{ wetchatBindStatusFormat(baseInfo) }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">注册时间</template>
                    {{ baseInfo?.createTime }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">审核时间</template>
                    {{ baseInfo?.auditTime }}
                  </el-descriptions-item>
                </el-descriptions>
              </el-card>
              <el-card shadow="never">
                <div class="card-title">营业执照</div>
                <el-descriptions
                  border
                  class="margin-top"
                  :column="4"
                  size="medium"
                >
                  <el-descriptions-item>
                    <template slot="label">执照类型</template>
                    {{ isCcFormat(baseInfo.isCc) }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">营业执照号</template>
                    {{ baseInfo?.businessLicenseCode }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">法人姓名</template>
                    {{
                      baseInfo?.merchantType == 3
                        ? baseInfo?.certificateName
                        : baseInfo?.lawyerName
                    }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">营业执照有效期</template>
                    {{ baseInfo?.businessLicenseFrom }}/{{
                      baseInfo?.businessLicenseTo
                    }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">注册地址</template>
                    {{ baseInfo?.registerAddress }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">经营范围</template>
                    {{ baseInfo?.businessScope }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">营业执照照片</template>
                    <el-image
                      v-if="baseInfo.businessLicensePhoto"
                      :preview-src-list="[baseInfo.businessLicensePhoto]"
                      :src="baseInfo.businessLicensePhoto"
                      style="width: 200px; height: 100px"
                    />
                  </el-descriptions-item>
                </el-descriptions>
              </el-card>

              <el-card shadow="never">
                <div class="card-title">法人信息</div>
                <el-descriptions
                  border
                  class="margin-top"
                  :column="2"
                  size="medium"
                >
                  <el-descriptions-item>
                    <template slot="label">法人姓名</template>
                    {{
                      baseInfo?.merchantType == 3
                        ? baseInfo?.certificateName
                        : baseInfo?.lawyerName
                    }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">法人证件号</template>
                    {{ baseInfo?.lawyerCertNo }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">证件正面照</template>
                    <el-image
                      v-if="baseInfo.lawyerCertPhotoFront"
                      :preview-src-list="[baseInfo.lawyerCertPhotoFront]"
                      :src="baseInfo.lawyerCertPhotoFront"
                      style="width: 200px; height: 100px"
                    />
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">证件反面照</template>
                    <el-image
                      v-if="baseInfo.lawyerCertPhotoBack"
                      :preview-src-list="[baseInfo.lawyerCertPhotoBack]"
                      :src="baseInfo.lawyerCertPhotoBack"
                      style="width: 200px; height: 100px"
                    />
                  </el-descriptions-item>
                </el-descriptions>
              </el-card>
            </el-tab-pane>
            <el-tab-pane label="结算账号" name="settlementAccount">
              <el-card shadow="never">
                <div
                  class="card-title"
                  :style="{ width: payType === 'EPL' ? '240px' : '130px' }"
                >
                  易票联结算帐号信息
                  <span v-if="payType === 'EPL'" style="color: red">
                    当前收款平台
                  </span>
                </div>
                <el-table
                  v-loading="loading"
                  border
                  :data="shopBankDataYPL"
                  stripe
                >
                  <el-table-column
                    align="center"
                    label="银行卡号"
                    prop="bankCardNo"
                  />
                  <el-table-column
                    align="center"
                    label="易票联商户号"
                    prop="acqMerId"
                  />
                  <el-table-column
                    align="center"
                    label="默认账户"
                    prop="costMeterVal"
                  >
                    <template slot-scope="scope">
                      <span v-if="scope.row.isDefault == 1">是</span>
                      <span v-else>否</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    label="账户状态"
                    prop="accountStatus"
                  >
                    <template slot-scope="scope">
                      <span v-if="scope.row.accountStatus == 0">未开通</span>
                      <span v-if="scope.row.accountStatus == 1">已开通</span>
                      <span v-if="scope.row.accountStatus == 2">冻结</span>
                      <span v-if="scope.row.accountStatus == 3">注销</span>
                      <span v-if="scope.row.accountStatus == 4">止付</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    label="开户银行"
                    prop="openBank"
                    width="70"
                  />
                  <el-table-column
                    align="center"
                    label="开户支行"
                    prop="openSubBank"
                    width="80"
                  />
                  <el-table-column
                    align="center"
                    label="创建时间"
                    prop="createTime"
                  />
                  <el-table-column
                    align="center"
                    label="更新时间"
                    prop="updateTime"
                  />
                  <el-table-column
                    align="center"
                    fixed="right"
                    label="操作"
                    width="110"
                  >
                    <template slot-scope="scope">
                      <el-button
                        size="mini"
                        type="text"
                        @click="handleDetail(scope.row)"
                      >
                        详情
                      </el-button>
                    </template>
                  </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
                </el-table>
              </el-card>
              <el-card shadow="never">
                <div
                  class="card-title"
                  :style="{
                    width: payType === 'CITIC_BANK' ? '250px' : '140px',
                  }"
                >
                  中信银行结算帐号信息
                  <span v-if="payType === 'CITIC_BANK'" style="color: red">
                    当前收款平台
                  </span>
                </div>
                <el-table
                  v-loading="loading"
                  border
                  :data="shopBankDataZX"
                  stripe
                >
                  <el-table-column
                    align="center"
                    label="银行卡号"
                    prop="bankCardNo"
                  />
                  <el-table-column
                    align="center"
                    label="中信银行商户号"
                    prop="acqMerId"
                  />
                  <el-table-column align="center" label="默认账户">
                    <template slot-scope="scope">
                      <span v-if="scope.row.isDefault == 1">是</span>
                      <span v-else>否</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="账户状态">
                    <template slot-scope="scope">
                      <span v-if="scope.row.accountStatus == 0">未开通</span>
                      <span v-if="scope.row.accountStatus == 1">已开通</span>
                      <span v-if="scope.row.accountStatus == 2">冻结</span>
                      <span v-if="scope.row.accountStatus == 3">注销</span>
                      <span v-if="scope.row.accountStatus == 4">止付</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    label="开户银行"
                    prop="openBank"
                    width="70"
                  />
                  <el-table-column
                    align="center"
                    label="开户支行"
                    prop="openSubBank"
                    width="80"
                  />
                  <el-table-column
                    align="center"
                    label="创建时间"
                    prop="createTime"
                  />
                  <el-table-column
                    align="center"
                    label="更新时间"
                    prop="updateTime"
                  />
                  <el-table-column
                    align="center"
                    fixed="right"
                    label="操作"
                    width="110"
                  >
                    <template slot-scope="scope">
                      <el-button
                        size="mini"
                        type="text"
                        @click="handleDetail(scope.row)"
                      >
                        详情
                      </el-button>
                    </template>
                  </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
                </el-table>
              </el-card>
            </el-tab-pane>
            <el-tab-pane label="楼栋信息" name="building">
              <el-form
                ref="formPro"
                label-width="0"
                :model="buildingListQuery"
                size="small"
              >
                <el-row :gutter="10">
                  <el-col :span="5">
                    <el-form-item label="" prop="communityName">
                      <el-input
                        v-model="buildingListQuery.communityName"
                        clearable
                        placeholder="小区名称"
                        style="max-width: 300px"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="5">
                    <el-form-item label="" prop="name">
                      <el-input
                        v-model="buildingListQuery.name"
                        clearable
                        placeholder="楼栋名称"
                        style="max-width: 300px"
                      />
                    </el-form-item>
                  </el-col>

                  <el-button
                    icon="el-icon-search"
                    type="primary"
                    @click="buildingFetchData"
                  >
                    查询
                  </el-button>
                </el-row>
              </el-form>
              <el-table
                v-loading="loading"
                border
                :data="buildingTableData"
                stripe
              >
                <el-table-column
                  align="center"
                  label="序号"
                  sortable
                  width="80"
                >
                  <template #default="{ $index }">
                    {{ $index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  label="小区名字"
                  prop="communityName"
                />
                <el-table-column align="center" label="楼栋名称" prop="name" />
                <el-table-column
                  align="center"
                  label="省市区"
                  prop="costMeterVal"
                >
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.costMeterVal }}/{{ scope.row.city }}/{{
                        scope.row.district
                      }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  label="详细地址"
                  prop="address"
                />
                <el-table-column
                  align="center"
                  label="楼层数"
                  prop="floorNum"
                  width="70"
                />
                <el-table-column
                  align="center"
                  label="房间总数"
                  prop="houseCount"
                  width="80"
                />
                <el-table-column
                  align="center"
                  fixed="right"
                  label="操作"
                  width="110"
                >
                  <template slot-scope="scope">
                    <el-button
                      plain
                      size="mini"
                      type="primary"
                      @click="checkBuilding(scope.row)"
                    >
                      查看房源
                    </el-button>
                  </template>
                </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
              </el-table>
              <Pagination
                :limit.sync="buildingListQuery.pageSize"
                :page.sync="buildingListQuery.pageNumber"
                :total-count="buildingTotalCount"
                @pagination="buildingFetchData"
              />
            </el-tab-pane>

            <el-tab-pane label="合同" name="contract">
              <el-form
                ref="formPro"
                label-width="0"
                :model="contractListQuery"
                size="small"
              >
                <el-row :gutter="10">
                  <el-col :span="5">
                    <el-form-item label="" prop="code">
                      <el-input
                        v-model="contractListQuery.code"
                        clearable
                        placeholder="合同编号"
                        style="max-width: 300px"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="5">
                    <el-form-item label="" prop="memberName">
                      <el-input
                        v-model="contractListQuery.memberName"
                        clearable
                        placeholder="承租人"
                        style="max-width: 300px"
                      />
                    </el-form-item>
                  </el-col>
                  <el-button
                    icon="el-icon-search"
                    type="primary"
                    @click="contractFetchData"
                  >
                    查询
                  </el-button>
                </el-row>
              </el-form>
              <el-table
                v-loading="loading"
                border
                :data="contractTableData"
                stripe
              >
                <el-table-column
                  align="center"
                  label="序号"
                  sortable
                  width="80"
                >
                  <template #default="{ $index }">
                    {{ $index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  label="合同编号"
                  prop="code"
                  width="180"
                />
                <el-table-column
                  align="center"
                  label="承租人"
                  prop="tenantName"
                  width="80"
                />
                <el-table-column
                  align="center"
                  label="房源名称"
                  prop="houseName"
                />
                <el-table-column
                  align="center"
                  label="状态"
                  prop="status"
                  width="90"
                >
                  <template slot-scope="scope">
                    <span>{{ contractStatusFormat(scope.row.status) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  label="合同金额"
                  prop="rentalAmount"
                />

                <el-table-column
                  align="center"
                  label="签约方式"
                  prop="contractWay"
                >
                  <template slot-scope="scope">
                    <span>
                      {{ contractContractWayFormat(scope.row.contractWay) }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  label="签约时间"
                  prop="contractDate"
                >
                  <template slot-scope="scope">
                    <span>
                      {{ (scope.row.contractDate || '').split(' ')[0] }}
                    </span>
                  </template>
                  <!--
                                                                             {{ (detail.startDate || '').split(' ')[0] }} -->
                </el-table-column>
                <el-table-column
                  align="center"
                  label="租赁期限"
                  prop="costMeterVal"
                  width="200"
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.firstBillStartDate != undefined">
                      {{ scope.row.firstBillStartDate }}至{{
                        scope.row.firstBillEndDate
                      }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  label="操作"
                  prop="costMeterVal"
                  width="90"
                >
                  <template slot-scope="scope">
                    <el-button
                      plain
                      size="mini"
                      type="text"
                      @click="contractHandleDetail(scope.row.id)"
                    >
                      详情
                    </el-button>
                  </template>
                </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
              </el-table>
              <Pagination
                :limit.sync="contractListQuery.pageSize"
                :page.sync="contractListQuery.pageNumber"
                :total-count="contractTotalCount"
                @pagination="contractFetchData"
              />
            </el-tab-pane>
            <el-tab-pane label="商户配置" name="configure">
              <CostConfig :id="id" />
              <PermissionSetting :id="id" />
            </el-tab-pane>
            <el-tab-pane label="商户账号" name="account">
              <el-form
                ref="formPro"
                label-width="0"
                :model="accountListQuery"
                size="small"
              >
                <el-row :gutter="10">
                  <el-col :span="5">
                    <el-form-item label="" prop="phone">
                      <el-input
                        v-model="accountListQuery.phone"
                        clearable
                        placeholder="手机号"
                        style="max-width: 300px"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="5">
                    <el-form-item label="" prop="username">
                      <el-input
                        v-model="accountListQuery.username"
                        clearable
                        placeholder="姓名"
                        style="max-width: 300px"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="5">
                    <el-form-item label="" prop="status">
                      <el-select
                        v-model="accountListQuery.status"
                        placeholder="状态"
                      >
                        <el-option
                          v-for="item in optionsAccountStatus"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-button
                    icon="el-icon-search"
                    type="primary"
                    @click="accountFetchData"
                  >
                    查询
                  </el-button>
                </el-row>
              </el-form>
              <el-table
                v-loading="loading"
                border
                :data="accountTableData"
                stripe
              >
                <el-table-column
                  align="center"
                  label="序号"
                  sortable
                  width="80"
                >
                  <template #default="{ $index }">
                    {{ $index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  label="用户名"
                  prop="memberName"
                />
                <el-table-column align="center" label="手机号" prop="phone" />
                <el-table-column align="center" label="状态" prop="status">
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.status">
                      {{ accountDataStatusFormat(scope.row.status) }}
                    </el-tag>
                    <el-tag v-else type="warning">
                      {{ accountDataStatusFormat(scope.row.status) }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  label="创建时间"
                  prop="createTime"
                />
                <el-table-column
                  align="center"
                  label="是否是管理账号"
                  prop="isAdmin"
                >
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.isAdmin == 1">
                      {{ accountDataIsAdminFormat(scope.row.isAdmin) }}
                    </el-tag>
                    <el-tag v-else type="warning">
                      {{ accountDataIsAdminFormat(scope.row.isAdmin) }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  label="最近登录时间"
                  prop="lastLoginTime"
                />
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
              </el-table>
              <Pagination
                :limit.sync="accountListQuery.pageSize"
                :page.sync="accountListQuery.pageNumber"
                :total-count="accountTotalCount"
                @pagination="accountFetchData"
              />
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-row>
    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      title="结算账户详情"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
    >
      <template #content>
        <el-row style="margin: 15px 0">
          <el-col :span="12">更新时间：{{ formData.updateTime }}</el-col>
          <el-col :span="12">创建时间：{{ formData.createTime }}</el-col>
        </el-row>
        <el-descriptions border class="margin-top" :column="2" size="medium">
          <el-descriptions-item>
            <template slot="label">商户ID</template>
            {{ formData.shopId }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">商户名称</template>
            {{ formData.shopName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">结算平台</template>
            {{ formData.payChannel == 'CITIC_BANK' ? '中信银行' : '易票联' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">银行卡号</template>
            {{ formData.bankCardNo }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">开户银行</template>
            {{ formData.openBank }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">开户支行</template>
            {{ formData.openSubBank }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">开户行联行号</template>
            {{ formData.openBankCode }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">提现方式</template>
            {{ formData.settleTarget == 2 ? '手动提现' : '自动提现' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">默认账户</template>
            {{ formData.isDefault == 1 ? '是' : '否' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">账户状态</template>
            <span v-if="formData.accountStatus == 0">未开通</span>
            <span v-if="formData.accountStatus == 1">已开通</span>
            <span v-if="formData.accountStatus == 2">冻结</span>
            <span v-if="formData.accountStatus == 3">注销</span>
            <span v-if="formData.accountStatus == 4">止付</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">银行卡附件</template>
            <el-image
              v-if="formData.settleAttachment"
              :preview-src-list="[formData.settleAttachment]"
              :src="formData.settleAttachment"
              style="width: 50px; height: 50px"
            />
            <el-image
              v-else
              :preview-src-list="[defaultImg]"
              :src="defaultImg"
              style="width: 50px; height: 50px"
            />
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">审核意见</template>
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </Dialog>
    <settlement-account-dialog ref="settlementAccountDialog" />
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import { handleActivePath } from '@/utils/routes'
  import Pagination from '@/components/pagination.vue'
  import SettlementAccountDialog from './components/settlementAccountDialog.vue'
  import Dialog from '@/components/dialogOther.vue'
  import {
    shopListDetail,
    buildingPage,
    contractPage,
    billSubjectConfigList,
    paymentWayConfigList,
    noticeConfigList,
    shopuserPage,
    shopBankAccountList,
  } from '@/api/merchant/shopList'
  import { shopBank, payChannel } from '@/api/merchant/newIndex'
  import { detailCostFeeConfig } from '@/api/shop/shopbank'
  import PermissionSetting from './components/permissionSetting.vue'
  import CostConfig from './components/costConfig.vue'

  export default {
    name: 'DistributorMerchantDeatail',
    components: {
      Pagination,
      Dialog,
      SettlementAccountDialog,
      PermissionSetting,
      CostConfig,
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        //  这里的vm指的就是vue实例，可以用来当做this使用
        vm.formUrl = from.path //获取上一级路由的路径
      })
    },
    data() {
      return {
        dailog: {
          height: 360,
          title: '',
          visible: false,
          width: '700px',
        },
        labelCol: {
          md: { span: 12 },
          sm: { span: 12 },
          xl: { span: 12 },
          xs: { span: 24 },
        },
        loading: false,
        id: '',
        activeName: 'baseInfo',
        baseInfo: {
          shopName: '',
        },
        accountList: [],
        //楼栋信息
        buildingInfo: {
          cName: '',
        },
        buildingTableData: [],
        buildingListQuery: {
          pageNumber: 1,
          pageSize: 10,
          shopId: '',
        },
        buildingTotalCount: 0,
        baseconf: {},
        //合同
        contractInfo: {
          cName: '',
        },
        contractTableData: [],
        contractListQuery: {
          pageNumber: 1,
          pageSize: 10,
          shopId: '',
        },
        contractTotalCount: 0,
        //商户配置
        configureInfo: {},
        billExpenseTypeTableData: [
          //账单费用类型
        ],
        paymentMethodTableData: [
          //支付方式
        ],
        notificationTypeTableData: [
          //通知类型
        ],
        auditStatusList: [
          {
            value: 0,
            label: '提交易票联审核未成功',
          },
          {
            value: 1,
            label: '待易票联审核',
          },
          {
            value: 2,
            label: '易票联审核成功',
          },
          {
            value: 3,
            label: '易票联审核失败',
          },
        ],
        formData: {},
        //商户账号
        optionsAccountStatus: [
          {
            value: '',
            label: '全部',
          },
          {
            value: 0,
            label: '禁用',
          },
          {
            value: 1,
            label: '启用',
          },
        ],
        accountInfo: {
          cName: '',
        },
        defaultImg: require('@/assets/default.jpg'),
        accountTableData: [],
        accountListQuery: {
          shopId: '',
          pageNumber: 1,
          pageSize: 10,
        },
        accountTotalCount: 0,
        accountDetailInfo: {},
        shopBankDataYPL: [],
        shopBankDataZX: [],
        payType: '',
        formUrl: '',
      }
    },
    created() {
      this.id = this.$route.query.id
      this.activeName = this.$route.query.tab || 'baseInfo'
      this.buildingListQuery.shopId = this.$route.query.id
      this.contractListQuery.shopId = this.$route.query.id
      this.accountListQuery.shopId = this.$route.query.id
      this.getAccountList()
      this.getShopBank()
      this.getPayChannel()
      if (this.activeName == 'baseInfo') {
        this.baseInfoFetchData()
      } else if (this.activeName == 'building') {
        this.buildingFetchData()
      } else if (this.activeName == 'contract') {
        this.contractFetchData()
      } else if (this.activeName == 'configure') {
        this.configureFetchData()
      } else if (this.activeName == 'account') {
        this.accountFetchData()
      }
    },
    methods: {
      ...mapActions({
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      handleCloseDialog() {
        //this.$refs['formData'].resetFields()
        this.dailog.visible = false
      },
      handleDetail(row) {
        this.dailog.visible = true
        this.dailog.title = '商户结算账号信息'
        console.log(row)
        this.$nextTick(() => {
          this.formData = JSON.parse(JSON.stringify(row))
        })
      },
      getPayChannel() {
        payChannel({ shopId: this.id }).then((res) => {
          if (res.code === 0) {
            this.payType = res.data
          }
        })
      },
      getShopBank() {
        shopBank({ shopId: this.id, pageNumber: 1, pageSize: 20 }).then(
          (res) => {
            this.shopBankDataYPL = res.data.records.filter((ele) => {
              return ele.payChannel == 'EPL'
            })

            this.shopBankDataZX = res.data.records.filter((ele) => {
              return ele.payChannel == 'CITIC_BANK'
            })
          }
        )
      },

      async goBack() {
        const detailPath = await handleActivePath(this.$route, true)
        await this.$router.push(this.formUrl)
        await this.delVisitedRoute(detailPath)
      },
      onSubmit() {
        this.$baseMessage('模拟保存成功', 'success', 'vab-hey-message-success')
      },
      openDialog() {
        this.$refs['vabCropper'].dialogVisible = true
      },
      checkBuilding(row) {
        this.$router.push({
          path: '/admin/house/info',
          query: {
            shopName: row.shopName,
            buildingName: row.name,
          },
        })
      },
      handleClick(tab) {
        this.$router.push({
          query: { ...this.$route.query, tab: tab.name },
        })
      },
      async baseInfoFetchData() {
        console.log('基本信息')
        this.loading = true
        var idParams = {
          id: this.id,
        }
        shopListDetail(idParams).then((res) => {
          if (res.code === 0) {
            console.log('getDetail>>>', res.data)
            this.baseInfo = res.data
            this.loading = false
            detailCostFeeConfig({ id: this.baseInfo.id }).then((res) => {
              if (res.code === 0) {
                console.log('detailCostFeeConfig>>>', res.data)
                this.baseconf = res.data
              }
            })
          } else {
            this.loading = false
          }
        })
      },
      async getAccountList() {
        var idParams = {
          shopId: this.id,
        }
        shopBankAccountList(idParams).then((res) => {
          if (res.code === 0) {
            console.log('accountList>>>', res.data)
            this.accountList = res.data
          }
        })
      },
      async buildingFetchData() {
        console.log('楼栋信息')
        //楼栋信息
        this.loading = true
        buildingPage(this.buildingListQuery).then((res) => {
          if (res.code === 0) {
            console.log('res>>>', res.data)
            this.buildingTableData = res.data.list
            this.buildingTotalCount = res.data.totalRow
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      async contractFetchData() {
        console.log('合同信息')
        //合同信息
        this.loading = true
        contractPage(this.contractListQuery).then((res) => {
          if (res.code === 0) {
            console.log('res>>>', res.data)
            this.contractTableData = res.data.list
            this.contractTotalCount = res.data.totalRow
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      async configureFetchData() {
        console.log('商户配置')
        this.loading = true
        var idParams = {
          id: this.id,
        }
        billSubjectConfigList(idParams).then((res) => {
          // console.log('账单>>>', res)
          if (res.code === 0) {
            this.billExpenseTypeTableData = res.data
            this.loading = false
          } else {
            this.loading = false
          }
        })
        paymentWayConfigList(idParams).then((res) => {
          // console.log('支付>>>', res)
          if (res.code === 0) {
            this.paymentMethodTableData = res.data
            this.loading = false
          } else {
            this.loading = false
          }
        })
        noticeConfigList(idParams).then((res) => {
          // console.log('通知>>>', res)
          if (res.code === 0) {
            this.notificationTypeTableData = res.data
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      async accountFetchData() {
        console.log('商户账号')
        this.loading = true
        shopuserPage(this.accountListQuery).then((res) => {
          if (res.code === 0) {
            console.log('shopuserPage>>>', res.data)
            this.accountTableData = res.data.list
            this.accountTotalCount = res.data.totalRow
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      //查看合同详情
      contractHandleDetail(id) {
        console.log('合同详情:', id)
        this.$router.push({
          path: '/admin/house/contract/detail',
          query: { id },
        })
        // this.$nextTick(() => {
        //   this.$router.push({
        //     path: '/admin/merchant/contractDetail',
        //     query: { id: row },
        //   })
        // })
      },

      merchantTypeFormat(status) {
        if (status === 1) {
          return '个体工商户'
        } else if (status === 2) {
          return '企业'
        } else if (status === 3) {
          return '个人(小微)'
        } else if (status === 4) {
          return '政府事业单位'
        } else if (status === 9) {
          return '其他组织'
        }
      },
      auditStatusFormat(status) {
        let str = ''
        this.auditStatusList.some((el) => {
          if (el.value === status) {
            str = el.label
            return true
          }
        })
        return str
      },
      openStatusFormat(status) {
        // console.log('openStatusFormat:', status)
        if (status === 0) {
          return '未开户'
        } else if (status === 10) {
          return '申请中'
        } else if (status === 20) {
          return '已开户'
        } else if (status === 30) {
          return '未通过'
        }
      },
      accountStatusFormat(status) {
        if (status === 0) {
          return '未开通'
        } else if (status === 1) {
          return '已开通'
        } else if (status === 2) {
          return '冻结'
        } else if (status === 3) {
          return '注销'
        } else if (status === 4) {
          return '止付'
        }
      },
      isCcFormat(status) {
        if (status === 0) {
          return '未3证合一'
        } else if (status === 1) {
          return '已3证合一'
        }
      },
      settleAccountTypeFormat(status) {
        if (status === 1) {
          return '对公账户'
        } else if (status === 2) {
          return '法人账户'
        } else if (status === 3) {
          return '授权对公'
        } else if (status === 4) {
          return '授权对私'
        }
      },
      settleTargetTypeFormat(type) {
        if (type === 1) {
          return '自动提现'
        } else if (type === 2) {
          return '手动提现'
        }
      },
      contractStatusFormat(status) {
        if (status === 0) {
          return '已完结'
        } else if (status === 1) {
          return '租赁中'
        }
      },
      contractContractWayFormat(status) {
        if (status === 1) {
          return '纸质'
        } else if (status === 2) {
          return '电子'
        }
      },
      configureTypeFormat(status) {
        if (status === 1) {
          return '租赁费'
        } else if (status === 2) {
          return '押金'
        } else if (status === 3) {
          return '能源费'
        } else if (status === 4) {
          return '服务费'
        } else if (status === 5) {
          return '手续费'
        } else if (status === 6) {
          return '杂费'
        } else if (status === 99) {
          return '其他费用'
        }
      },
      configureCheckedFormat(status) {
        if (status === 1) {
          return '是'
        } else if (status === 0) {
          return '否'
        }
      },
      configurepaymentMethodCheckedFormat(status) {
        if (status === 1) {
          return '开启'
        } else if (status === 0) {
          return '关闭'
        }
      },
      configureNotificationTypeCheckedFormat(status) {
        if (status === 1) {
          return '开启'
        } else if (status === 0) {
          return '关闭'
        }
      },
      accountDataIsAdminFormat(status) {
        if (status == true) {
          return '是'
        } else {
          return '否'
        }
      },
      accountDataStatusFormat(status) {
        if (status === 0) {
          return '禁用'
        } else if (status === 1) {
          return '启用'
        }
      },
      wetchatBindStatusFormat(baseInfo) {
        if (baseInfo?.openid) {
          return baseInfo?.openid
        } else if (baseInfo?.bindStatus === 0) {
          return '未绑定'
        } else if (baseInfo?.bindStatus == 1) {
          return '已绑定'
        }
      },
      handleAccountInfoDetail(row) {
        this.$refs.settlementAccountDialog.showDialog(row)
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.personal-center';

  #{$base}-container {
    padding: 0 !important;
    background: $base-color-background !important;

    #{$base}-user-info {
      padding: $base-padding;
      text-align: center;

      :deep() {
        .el-avatar {
          img {
            cursor: pointer;
          }
        }
      }

      &-full-name {
        margin-top: 15px;
        font-size: 24px;
        font-weight: 500;
        color: #262626;
      }

      &-description {
        margin-top: 8px;
      }

      &-follow {
        margin-top: 15px;
      }

      &-list {
        margin-top: 18px;
        line-height: 30px;
        text-align: left;
        list-style: none;

        h5 {
          margin: -20px 0 5px 0;
        }

        :deep() {
          .el-tag {
            margin-right: 10px !important;
          }

          .el-tag + .el-tag {
            margin-left: 0;
          }
        }
      }
    }

    #{$base}-item {
      display: flex;

      i {
        font-size: 40px;
      }

      &-content {
        box-sizing: border-box;
        flex: 1;
        margin-left: $base-margin;

        &-second {
          margin-top: 8px;
        }
      }
    }
  }

  .card-title {
    width: 60px;
    border-bottom: 2px solid #000;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .imageInfo {
    width: 200px;
    height: 100px;
  }
</style>
