<template>
  <div>
    <el-form
      ref="formData"
      label-width="150px"
      :model="formData"
      :rules="rules"
      size="small"
    >
      <el-col :span="24" style="height: 50px">
        <el-form-item label="商户名称">
          {{ name }}
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="电子合同费用" prop="noPaperContractFee">
          <el-input
            v-model="formData.noPaperContractFee"
            clearable
            :disabled="disabled"
            min="0"
            style="max-width: 300px"
            type="number"
            @keyup.native="preventNeg($event)"
            @mousewheel.native.prevent
          >
            <template slot="append">元/笔</template>
          </el-input>
          <span class="tip-info_css">
            参考值： 0.00 || 取值区间: 0.00 - 0.00 || 单位: 0 || 备注: 0
          </span>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="平台服务费" prop="platformServiceFee">
          <el-input
            v-model="formData.platformServiceFee"
            clearable
            :disabled="disabled"
            min="0"
            style="max-width: 300px"
            type="number"
            @keyup.native="preventNeg($event)"
            @mousewheel.native.prevent
          >
            <template slot="append">元/笔</template>
          </el-input>
          <span class="tip-info_css">
            参考值: 3.00 || 取值区间: 0.10 - 2.00 || 单位: 元/笔 || 备注: xx
          </span>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="短信发送费用" prop="sendMsgFee">
          <el-input
            v-model="formData.sendMsgFee"
            :disabled="disabled"
            min="0"
            style="max-width: 300px"
            type="number"
            @keyup.native="preventNeg($event)"
            @mousewheel.native.prevent
          >
            <template slot="append">元/条</template>
          </el-input>
          <span class="tip-info_css">
            参考值: 0.10 || 取值区间: 0.10 - 0.20 || 单位: 元/条 || 备注: 元/条
          </span>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="支付分账服务费" prop="splitServiceFee">
          <el-input
            v-model="formData.splitServiceFee"
            :disabled="disabled"
            min="0"
            style="max-width: 300px"
            type="number"
            @keyup.native="preventNeg($event)"
            @mousewheel.native.prevent
          >
            <template slot="append">元/笔</template>
          </el-input>
          <span class="tip-info_css">
            参考值:0.10 || 取值区间: 0.01 - 1.00 || 单位: 元/笔 || 备注：百分比
          </span>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="支付手续费" prop="payServiceFee">
          <el-input
            v-model="formData.payServiceFee"
            :disabled="disabled"
            min="0"
            style="max-width: 300px"
            type="number"
            @keyup.native="preventNeg($event)"
            @mousewheel.native.prevent
          >
            <template slot="append">%/笔</template>
          </el-input>
          <span class="tip-info_css">
            参考值: 0.38 || 取值区间: 0.01 - 1.00 || 单位: % || 备注: 百分比
          </span>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="自动提现服务费" prop="withdrawServiceFee">
          <el-input
            v-model="formData.withdrawServiceFee"
            :disabled="disabled"
            min="0"
            style="max-width: 300px"
            type="number"
            @keyup.native="preventNeg($event)"
            @mousewheel.native.prevent
          >
            <template slot="append">元/笔</template>
          </el-input>
          <span class="tip-info_css">
            参考值: 1.00 || 取值区间: 0.10 - 1.00 || 单位: 元/笔 || 备注: xx
          </span>
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item>
          <el-button size="mini" @click="cancel()">取消</el-button>
          <el-button size="mini" @click="getCostData('reset')">重置</el-button>
          <el-button size="mini" type="primary" @click="submitData()">
            确定
          </el-button>
        </el-form-item>
      </el-col>
    </el-form>
  </div>
</template>

<script>
  import {
    costDetail,
    costReset,
    costUpdate,
    costAdd,
  } from '@/api/merchant/index'
  import { CommonMixin } from '@/mixins/CommonMixin'
  export default {
    name: 'CostSetting',
    mixins: [CommonMixin],
    props: {
      id: {
        type: String,
        default: '',
      },
      name: {
        type: String,
        default: '',
      },
    },
    data() {
      var checkNumber = (rule, value, callback) => {
        if (value < 0) {
          callback(new Error('请输入大于0的数字!'))
        }
        callback()
      }
      return {
        disabled: false,
        dateId: '',
        dataInfo: {},
        formData: {},
        rules: {
          shopId: { required: true, message: '请选择', trigger: 'blur' },
          noPaperContractFee: [
            {
              required: true,
              message: '请输入',
              trigger: 'blur',
            },
            { validator: checkNumber, trigger: 'blur' },
          ],
          platformServiceFee: [
            {
              required: true,
              message: '请输入',
              trigger: 'blur',
            },
            { validator: checkNumber, trigger: 'blur' },
          ],
          sendMsgFee: [
            { required: true, message: '请输入', trigger: 'blur' },
            { validator: checkNumber, trigger: 'blur' },
          ],
          splitServiceFee: [
            {
              required: true,
              message: '请输入',
              trigger: 'blur',
            },
            { validator: checkNumber, trigger: 'blur' },
          ],
          payServiceFee: [
            { required: true, message: '请输入', trigger: 'blur' },
            { validator: checkNumber, trigger: 'blur' },
          ],
          withdrawServiceFee: [
            {
              required: true,
              message: '请输入',
              trigger: 'blur',
            },
            { validator: checkNumber, trigger: 'blur' },
          ],
        },
      }
    },
    created() {
      this.getCostData('')
    },
    methods: {
      async getCostData(type) {
        let param = {
          id: this.id,
        }
        let res =
          type == 'reset' ? await costReset(param) : await costDetail(param)
        if (res.data == null && type != 'reset') {
          // res = await costReset(param)
        }
        if (res.code == 0 && type == 'reset') {
          this.$message.success('重置成功')
        }
        let data = res.data || {}
        this.dataInfo = Object.assign({}, data)
        this.formData = data
        this.dateId = data.id
      },
      async cancel() {
        this.formData = Object.assign({}, this.dataInfo)
        await this.$router.push('/admin/merchant/shopList')
      },
      submitData() {
        this.$refs['formData'].validate(async (valid) => {
          if (valid) {
            this.formData.shopId = this.id
            if (this.dateId) {
              this.formData.id = this.dateId
              let res = await costUpdate(this.formData)
              if (res.code == 0) {
                this.$message.success('提交成功')
              } else {
                this.$message.error(res.msg)
              }
            } else {
              let res = await costAdd(this.formData)
              if (res.code == 0) {
                this.$message.success('新增成功')
              } else {
                this.$message.error(res.msg)
              }
            }
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .tip-info_css {
    background-color: #e9ecef;
    height: calc(1.5em + 0.75rem + 2px);
    margin-left: 0.375rem;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    color: gray;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
</style>
