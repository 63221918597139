<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    :title="dialogType === 'add' ? '新增短信套餐' : '修改短信套餐'"
    :visible="smsComboVisible"
    width="500px"
  >
    <el-form
      ref="ruleForm"
      class="demo-ruleForm"
      label-width="100px"
      :model="ruleForm"
      :rules="rules"
    >
      <el-form-item label="套餐名称" prop="name">
        <el-input v-model="ruleForm.name" placeholder="请输入套餐名称" />
      </el-form-item>
      <el-form-item label="充值金额(元)" prop="price">
        <el-input v-model="ruleForm.price" placeholder="请输入充值金额" />
      </el-form-item>
      <el-form-item label="短信数量" prop="quantity">
        <el-input v-model="ruleForm.quantity" placeholder="请输入短信数量" />
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="ruleForm.remark"
          placeholder="请输入备注"
          type="textarea"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    name: 'Dialog',
    props: {
      dialogType: {
        type: String,
        default: '',
      },
      smsComboVisible: {
        type: Boolean,
        default: false,
      },
      rowData: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data() {
      return {
        ruleForm: {
          name: '',
          price: '',
          quantity: '',
          remark: '',
        },
        rules: {
          name: [
            { required: true, message: '请输入套餐名称', trigger: 'blur' },
          ],
          quantity: [
            { required: true, message: '请输入短信数量', trigger: 'blur' },
          ],
          price: [
            { required: true, message: '请输入充值金额', trigger: 'blur' },
          ],
        },
      }
    },
    mounted() {
      this.ruleForm = {
        ...this.ruleForm,
        ...this.rowData,
        price: this.rowData?.price ? this.rowData?.price / 100 : '',
      }
    },
    methods: {
      // 确定新增/修改
      handleConfirm() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.$emit('confirm', {
              ...this.ruleForm,
              actualPrice: this.ruleForm.price,
              totalQuantity: this.ruleForm.quantity,
              giftQuantity: 0,
              dialogType: this.dialogType,
            })
            this.$emit('update:smsComboVisible', false)
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      // 取消
      handleClose() {
        this.$emit('update:smsComboVisible', false)
      },
    },
  }
</script>

<style lang="scss">
  .container {
    overflow-x: initial;
    overflow-y: auto;
  }

  .el-dialog {
    border-top-left-radius: 7px !important;
    border-top-right-radius: 7px !important;

    .el-dialog__header {
      margin-right: 0;
      background-color: #1890ff !important;
      border-top-left-radius: 7px !important;
      border-top-right-radius: 7px !important;

      .el-dialog__title {
        font-size: 16px;
        font-weight: 600;
        color: #fff;
      }

      .el-dialog__close {
        color: #fff !important;
      }
    }

    .el-dialog__body {
      padding: 30px 10px;
    }

    .el-dialog__footer {
      padding: 10px;
      border-top: 1px solid #e8eaec !important;
    }

    .el-icon svg {
      color: #fff;
    }
  }
</style>
