<template>
  <div>
    <el-card shadow="never">
      <div class="card-title">操作开关类型</div>
      <el-form ref="formData" label-width="200px" size="small">
        <el-col v-for="(scope, index) in formDataList" :key="index" :span="24">
          <el-form-item :label="scope.name">
            <el-radio-group v-model="scope.defaultValue">
              <el-radio
                v-for="item in enabledList"
                :key="item.value"
                :label="item.value"
              >
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="24">
          <el-form-item label="分账功能">
            <el-radio-group v-model="formData.refundEnabled">
              <el-radio
                v-for="item in enabledList"
                :key="item.value"
                :label="item.value"
              >
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="分账接收方添加">
            <el-radio-group v-model="formData.creditcardsEnabled">
              <el-radio
                v-for="item in enabledList"
                :key="item.value"
                :label="item.value"
              >
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="多结算账户添加">
            <span slot="label">
              多结算账户添加
              <el-tooltip
                content="最多绑定3张银行卡"
                effect="dark"
                placement="top"
              >
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </span>
            <el-radio-group v-model="formData.creditcardsEnabled">
              <el-radio
                v-for="item in enabledList"
                :key="item.value"
                :label="item.value"
              >
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="分账接收方多结算账户添加">
            <span slot="label">
              分账接收方多结算账户添加
              <el-tooltip
                content="最多绑定3张银行卡"
                effect="dark"
                placement="top"
              >
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </span>
            <el-radio-group v-model="formData.creditcardsEnabled">
              <el-radio
                v-for="item in enabledList"
                :key="item.value"
                :label="item.value"
              >
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col> -->
        <el-col>
          <el-form-item>
            <el-button size="mini" @click="cancel()">取消</el-button>
            <el-button size="mini" @click="getParamData('reset')">
              重置
            </el-button>
            <el-button size="mini" type="primary" @click="submitData()">
              确定
            </el-button>
          </el-form-item>
        </el-col>
      </el-form>
    </el-card>
  </div>
</template>

<script>
  import { paramList, paramReset, paramUpdate } from '@/api/merchant/index'
  export default {
    name: 'PermissionSetting',
    props: {
      id: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        dataInfoList: [],
        formDataList: {},
        enabledList: [
          {
            label: '关闭 ',
            value: 0,
          },
          {
            label: '开启 ',
            value: 1,
          },
        ],
      }
    },
    created() {
      this.getParamData()
    },
    methods: {
      async getParamData(type) {
        let param = {
          shopId: this.id,
        }
        let res =
          type === 'reset' ? await paramReset(param) : await paramList(param)
        let data = res.data || []
        if (res.code == 0 && type == 'reset') {
          this.$message.success('重置成功')
        }
        this.dataInfoList = JSON.parse(JSON.stringify(data))
        this.formDataList = data
      },
      async cancel() {
        this.formDataList = JSON.parse(JSON.stringify(this.dataInfoList))
        await this.$router.push('/admin/merchant/shopList')
      },
      async submitData() {
        let settingIds = []
        let defaultValues = []
        this.formDataList.forEach((item) => {
          settingIds.push(item.id)
          defaultValues.push(item.defaultValue)
        })

        let param = {
          shopId: this.id,
          settingIds: settingIds.join(','),
          defaultValues: defaultValues.join(','),
        }
        let res = await paramUpdate(param)
        if (res.code == 0) {
          this.$message.success('提交成功')
        } else {
          this.$message.error(res.msg)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .card-title {
    width: 84px;
    border-bottom: 2px solid #000;
    padding-bottom: 10px;
    margin-bottom: 28px;
  }
</style>
