<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="商户名称" prop="shopName">
            <el-input
              v-model="listQuery.shopName"
              clearable
              placeholder="请输入商户名称"
              style="max-width: 300px"
            />
          </el-form-item>

          <el-form-item
            label="商户用户账号"
            label-width="120px"
            prop="userName"
          >
            <el-input
              v-model="listQuery.userName"
              clearable
              placeholder="请输入商户用户账号"
              style="max-width: 300px"
            />
          </el-form-item>

          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="getList">
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="reset">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>
    <!-- <div class="top">
      <div class="left">
        <el-input
          v-model="listQuery.shopName"
          clearable
          placeholder="请输入商户名称"
          style="margin-right: 20px"
        />
        <el-input
          v-model="listQuery.userName"
          clearable
          placeholder="请输入商户用户账号"
          style="margin-right: 20px"
        />
        <el-button
          icon="el-icon-search"
          size="mini"
          type="primary"
          @click="getList"
        >
          查询
        </el-button>
      </div>
    </div> -->
    <el-table v-loading="loading" border :data="tableData">
      <!-- <el-table-column align="center" type="selection" width="50" /> -->
      <el-table-column align="center" label="序号" width="50">
        <template slot-scope="scope">
          <span>
            {{
              (listQuery.pageNumber - 1) * listQuery.pageSize + scope.$index + 1
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="商户名称" prop="shopName" />
      <el-table-column align="center" label="商户用户账号" prop="username" />
      <el-table-column align="center" label="浏览器ua" prop="ua" />
      <el-table-column align="center" label="访问IP" prop="ip" />
      <el-table-column align="center" label="登录时间" prop="loginTime" />
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />
  </div>
</template>

<script>
  import { shopUserLoginLogList } from '@/api/log/shopuserlogin'
  import Pagination from '@/components/pagination.vue'
  export default {
    name: 'ShopUserLoginLog',
    components: {
      Pagination,
    },
    data() {
      return {
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          shopName: '',
          userName: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      reset() {
        this.listQuery.shopName = ''
        this.listQuery.userName = ''
        this.getList()
      },
      // 列表
      getList() {
        this.loading = true
        shopUserLoginLogList(this.listQuery).then((res) => {
          if (res.code === 0) {
            const { records, total } = res.data
            this.tableData = records
            this.totalCount = +total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .index-container {
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .left {
        display: flex;
      }
    }
  }

  :deep() {
    .el-table--border .el-table__cell:first-child .cell {
      padding-left: 0;
    }
    .el-table th.el-table__cell > .cell {
      padding-right: 0;
    }
    .el-table .cell {
      padding: 0;
    }
  }
</style>
