<template>
  <div class="index-container sms-stencil-management">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="80px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="模版ID">
            <el-input
              v-model="listQuery.templateId"
              clearable
              placeholder="模版ID"
            />
          </el-form-item>
          <el-form-item label="业务场景">
            <el-select
              v-model="listQuery.scenes"
              clearable
              filterable
              :loading="optionLoading"
              placeholder="请输入关键词"
              remote
              :remote-method="remoteMethod"
              reserve-keyword
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.scenes"
                :value="item.scenesCode"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="接收方">
            <el-select v-model="listQuery.target" placeholder="请选择">
              <el-option label="全部" value="" />
              <el-option label="平台" value="PLATFORM" />
              <el-option label="商户" value="SHOP" />
              <el-option label="租户" value="MEMBER" />
            </el-select>
          </el-form-item>
          <el-form-item label="发送方">
            <el-select v-model="listQuery.origin" placeholder="请选择">
              <el-option label="全部" value="" />
              <el-option label="平台" value="PLATFORM" />
              <el-option label="商户" value="SHOP" />
              <el-option label="租户" value="MEMBER" />
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="listQuery.status" placeholder="请选择">
              <el-option label="全部" value="" />
              <el-option label="启用" value="ENABLED" />
              <el-option label="禁用" value="DISABLED" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetQuery">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @selection-change="setSelectRows"
    >
      <!-- <el-table-column align="center" type="selection" width="50" /> -->
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          <span>
            {{ (listQuery.pageNumber - 1) * listQuery.pageSize + $index + 1 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="模版ID" prop="templateId" />
      <el-table-column
        align="center"
        label="业务场景"
        prop="scenes"
        width="180"
      />
      <el-table-column align="center" label="发送方">
        <template slot-scope="scope">
          <span v-if="scope.row.origin === 'PLATFORM'">平台</span>
          <span v-if="scope.row.origin === 'SHOP'">商户</span>
          <span v-if="scope.row.origin === 'MEMBER'">租户</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="接收方">
        <template slot-scope="scope">
          <span v-if="scope.row.target === 'PLATFORM'">平台</span>
          <span v-if="scope.row.target === 'SHOP'">商户</span>
          <span v-if="scope.row.target === 'MEMBER'">租户</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="模版内容" width="300">
        <template slot-scope="scope">
          <div v-html="scope.row.templateContent"></div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="启用数量" prop="enabledCount" />
      <el-table-column align="center" label="禁用数量" prop="disableCount" />
      <el-table-column align="center" label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 'ENABLED'" type="success">
            启用
          </el-tag>
          <el-tag v-if="scope.row.status === 'DISABLED'" type="danger">
            禁用
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="80">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleview(scope.row)">
            {{ scope.row.status === 'ENABLED' ? '禁用' : '启用' }}
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="fetchData"
    />
  </div>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import {
    getSmsConfig,
    updateSmsConfig,
  } from '@/api/smsStencilManagement/index'
  export default {
    name: 'SmsStencilManagement',
    components: {
      Pagination,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        optionLoading: false,
        options: [],
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          templateId: '',
          scenes: '',
          status: '',
          target: '',
          origin: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        fold: false,
      }
    },
    beforeMount() {
      window.addEventListener('resize', this.handleHeight)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleHeight)
    },
    created() {
      this.fetchData()
    },
    methods: {
      remoteMethod(query) {
        if (query !== '') {
          this.optionLoading = true
          getSmsConfig({ scenes: query, pageSize: 1000, pageNumber: 1 }).then(
            (res) => {
              if (res.code === 0) {
                this.options = res.records
              }
            }
          )
        } else {
          this.options = []
        }
      },
      handleFold() {
        this.fold = !this.fold
        this.handleHeight()
      },
      handleHeight() {
        if (this.fold) this.height = this.$baseTableHeight(2) - 47
        else this.height = this.$baseTableHeight(3) - 30
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleview(row) {
        updateSmsConfig({
          id: row.id,
          status: row.status === 'ENABLED' ? 'DISABLED' : 'ENABLED',
        }).then((res) => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.fetchData()
          }
        })
      },

      handleQuery() {
        this.listQuery.pageNumber = 1
        this.fetchData()
      },
      async fetchData() {
        if (this.loading) return
        try {
          this.loading = true
          const {
            data: { records, total },
          } = await getSmsConfig({ ...this.queryForm, ...this.listQuery })
          this.tableData = records
          this.totalCount = +total
        } finally {
          this.loading = false
        }
      },
      toggleSelection(rows) {
        if (rows) {
          rows.forEach((row) => {
            this.$refs.tableSort.toggleRowSelection(row)
          })
        } else {
          this.$refs.tableSort.clearSelection()
        }
      },
      //重置
      resetQuery() {
        this.listQuery = {
          pageNumber: 1,
          pageSize: 20,
          templateId: '',
          scenes: '',
          status: '',
          target: '',
          origin: '',
        }
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .box-card.el-card {
    border: none !important;
  }
</style>
