<template>
  <div class="personal-center-container">
    <el-row :gutter="20">
      <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
        <el-card>
          <el-page-header :content="'商户配置'" @back="goBack" />
          <el-tabs v-model="activeName">
            <el-tab-pane
              v-for="(item, index) in tabList"
              :key="index"
              :label="item.label"
              :name="item.name"
            >
              <component
                :is="item.name"
                v-if="activeName == item.name"
                :id="id"
                :name="name"
              />
            </el-tab-pane>
            <!-- <el-tab-pane label="商户参数权限配置" name="permission">
              <PermissionSetting :id="id" />
            </el-tab-pane>
            <el-tab-pane label="商户成本费用配置" name="cost">
              <CostSetting :id="id" />
            </el-tab-pane>
            <el-tab-pane label="商户业务费率配置" name="rate">
              <RateSetting :shopid="id" />
            </el-tab-pane> -->
          </el-tabs>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import { handleActivePath } from '@/utils/routes'
  import PermissionSetting from './components/permissionSetting.vue'
  import CostSetting from './components/costSetting.vue'
  import RateSetting from './components/rateSetting.vue'
  export default {
    name: 'MerchantDispose',
    components: {
      PermissionSetting,
      CostSetting,
      RateSetting,
    },
    data() {
      return {
        id: '',
        name: '',
        activeName: 'PermissionSetting',
        tabList: [
          { label: '商户参数权限配置', name: 'PermissionSetting' },
          { label: '商户成本费用配置', name: 'CostSetting' },
          // { label: '商户业务费率配置', name: 'RateSetting' },
        ],
      }
    },
    created() {
      this.id = this.$route.query.id
      this.name = this.$route.query.name
    },
    methods: {
      ...mapActions({
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      async goBack() {
        const detailPath = await handleActivePath(this.$route, true)
        await this.$router.push('/admin/merchant/shopList')
        await this.delVisitedRoute(detailPath)
      },
    },
  }
</script>
