<template>
  <el-dialog append-to-body title="结算账户详情" :visible.sync="dialogVisible">
    <el-descriptions border class="margin-top" :column="2" size="medium">
      <el-descriptions-item>
        <template slot="label">易票联审核状态</template>
        {{ auditStatusFormat(accountDetailInfo?.auditStatus) }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">易票联商户编号</template>
        {{ accountDetailInfo?.acqMerId }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">结算账号类型</template>
        {{ settleAccountTypeFormat(accountDetailInfo?.settleAccountType) }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">开户人</template>
        {{ accountDetailInfo?.settleAccount }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">银行卡号</template>
        {{ accountDetailInfo?.bankCardNo }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">开户银行</template>
        {{ accountDetailInfo?.openBank }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">开户支行</template>
        {{ accountDetailInfo?.openSubBank }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">开户银联号</template>
        {{ accountDetailInfo?.openBankCode }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">结算方式</template>
        {{ settleTargetTypeFormat(accountDetailInfo?.settleTarget) }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">创建时间</template>
        {{ accountDetailInfo?.createTime }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">银行卡附件</template>
        <el-image
          v-if="accountDetailInfo.settleAttachment"
          :preview-src-list="[accountDetailInfo.settleAttachment]"
          :src="accountDetailInfo.settleAttachment"
          style="width: 200px; height: 100px"
        />
      </el-descriptions-item>
    </el-descriptions>
  </el-dialog>
</template>

<script>
  import { shopBankAccountDetail } from '@/api/merchant/shopList'
  export default {
    name: 'SettlementAccountDialog',
    data() {
      return {
        dialogVisible: false,
        accountDetailInfo: {},
        auditStatusList: [
          {
            value: 0,
            label: '提交易票联审核未成功',
          },
          {
            value: 1,
            label: '待易票联审核',
          },
          {
            value: 2,
            label: '易票联审核成功',
          },
          {
            value: 3,
            label: '易票联审核失败',
          },
        ],
      }
    },
    methods: {
      //显示对话框
      showDialog(row) {
        this.dialogVisible = true
        this.shopBankAccountDetailData(row)
      },
      shopBankAccountDetailData(row) {
        let params = {
          id: row.id,
        }
        shopBankAccountDetail(params).then((res) => {
          if (res.code === 0) {
            this.accountDetailInfo = res.data || {}
          }
        })
      },
      auditStatusFormat(status) {
        let str = ''
        this.auditStatusList.some((el) => {
          if (el.value === status) {
            str = el.label
            return true
          }
        })
        return str
      },
      accountStatusFormat(status) {
        if (status === 0) {
          return '未开通'
        } else if (status === 1) {
          return '已开通'
        } else if (status === 2) {
          return '冻结'
        } else if (status === 3) {
          return '注销'
        } else if (status === 4) {
          return '止付'
        }
      },
      settleTargetTypeFormat(type) {
        if (type === 1) {
          return '自动提现'
        } else if (type === 2) {
          return '手动提现'
        }
      },
      settleAccountTypeFormat(status) {
        if (status === 1) {
          return '对公账户'
        } else if (status === 2) {
          return '法人账户'
        } else if (status === 3) {
          return '授权对公'
        } else if (status === 4) {
          return '授权对私'
        }
      },
    },
  }
</script>
