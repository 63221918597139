var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "before-close": _vm.handleClose,
        title: "详情",
        visible: _vm.detailVisible,
        width: "800px",
      },
    },
    [
      _c(
        "el-descriptions",
        {
          staticClass: "margin-top",
          attrs: { border: "", column: 2, size: "medium", title: "" },
        },
        [
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("用户ID")]),
              _vm._v(" " + _vm._s(_vm.rowData.memberId) + " "),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("用户名称")]),
              _vm._v(" " + _vm._s(_vm.rowData.memberName) + " "),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("访问名称")]),
              _vm._v(" " + _vm._s(_vm.rowData.name) + " "),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("访问路径")]),
              _vm._v(" " + _vm._s(_vm.rowData.actionUrl) + " "),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("访问参数")]),
              _vm._v(" " + _vm._s(_vm.rowData.params) + " "),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("ua")]),
              _vm._v(" " + _vm._s(_vm.rowData.ua) + " "),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("访问IP")]),
              _vm._v(" " + _vm._s(_vm.rowData.ip) + " "),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("访问时间")]),
              _vm._v(" " + _vm._s(_vm.rowData.createTime) + " "),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleOk } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }