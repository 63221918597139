<template>
  <div class="index-container sms-combo-management">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="80px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="套餐名称">
            <el-input
              v-model="listQuery.name"
              clearable
              placeholder="套餐名称"
            />
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="creatTime"
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
            />
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="listQuery.statusEnum" placeholder="请选择">
              <el-option label="全部" value="" />
              <el-option label="启用" value="ENABLED" />
              <el-option label="禁用" value="DISABLED" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetQuery">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel :span="24">
        <el-form ref="form" :inline="true">
          <el-form-item>
            <el-button icon="el-icon-plus" type="primary" @click="addData">
              新增
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-delete"
              native-type="submit"
              type="danger"
              @click="deleteData"
            >
              删除
            </el-button>
          </el-form-item>

          <el-form-item />
        </el-form>
      </vab-query-form-left-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @selection-change="setSelectRows"
    >
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          <span>
            {{ (listQuery.pageNumber - 1) * listQuery.pageSize + $index + 1 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="套餐名称" prop="name" />
      <el-table-column align="center" label="套餐内容">
        <template slot-scope="scope">
          {{ scope.row.price / 100 }}元/{{ scope.row.quantity }}条
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态" prop="status">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 'ENABLED'" type="success">
            启用
          </el-tag>
          <el-tag v-else type="danger">禁用</el-tag>
        </template>
      </el-table-column>

      <el-table-column align="center" label="购买次数" prop="soldCount" />
      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        width="200"
      />
      <el-table-column align="center" label="备注" prop="remark" width="100" />
      <el-table-column align="center" label="短信充值" width="120">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleview(scope.row)">
            {{ scope.row.status === 'ENABLED' ? '禁用' : '启用' }}
          </el-button>
          <el-button size="mini" type="text" @click="updateData(scope.row)">
            修改
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="fetchData"
    />
    <AddSmsComboVue
      v-if="smsComboVisible"
      :dialog-type="dialogType"
      :row-data="rowData"
      :sms-combo-visible.sync="smsComboVisible"
      @confirm="confirm"
    />
  </div>
</template>

<script>
  import {
    getProduct,
    addProduct,
    updateProduct,
    deleteProduct,
  } from '@/api/smsComboManagement'
  import Pagination from '@/components/pagination.vue'
  import AddSmsComboVue from './components/addSmsCombo.vue'
  const dayjs = require('dayjs')
  export default {
    name: 'SmsComboManagement',
    components: {
      Pagination,
      AddSmsComboVue,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        creatTime: null,
        smsComboVisible: false,
        rowData: {},
        selectRows: [],
        dialogType: 'add',
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          name: '',
          statusEnum: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        options: [],
        fold: false,
      }
    },
    beforeMount() {
      window.addEventListener('resize', this.handleHeight)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleHeight)
    },
    created() {
      this.fetchData()
    },
    methods: {
      confirm(val) {
        let params = {
          name: val.name,
          price: val.price * 100,
          quantity: val.quantity,
          remark: val.remark,
          id: val?.id ? val?.id : undefined,
          actualPrice: val.actualPrice * 100,
          totalQuantity: val.totalQuantity,
          giftQuantity: val.giftQuantity,
          status: val.dialogType === 'add' ? 'ENABLED' : val.status,
        }
        console.log(
          '🚀 ~ file: smsComboManagement.vue:204 ~ confirm ~ params',
          params
        )
        if (val.dialogType === 'add') {
          addProduct(params).then((res) => {
            if (res.code === 0) {
              this.fetchData()
              this.$message.success(res.msg)
            }
          })
        } else {
          updateProduct(params).then((res) => {
            if (res.code === 0) {
              this.fetchData()
              this.$message.success(res.msg)
            }
          })
        }
      },
      addData() {
        this.smsComboVisible = true
        this.rowData = {}
        this.dialogType = 'add'
      },
      updateData(row) {
        this.smsComboVisible = true
        this.dialogType = 'edit'
        this.rowData = { ...row }
      },
      deleteData() {
        if (this.selectRows.length === 0) {
          return this.$message.warning('请选择要删除的数据')
        }
        this.$confirm('是否删除选中的数据?', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          const ids = this.selectRows
            .map((ele) => {
              return ele.id
            })
            .join(',')
          deleteProduct(ids).then((res) => {
            if (res.code === 0) {
              this.fetchData()
              this.$message.success(res.msg)
            }
          })
        })
      },
      handleFold() {
        this.fold = !this.fold
        this.handleHeight()
      },
      handleHeight() {
        if (this.fold) this.height = this.$baseTableHeight(2) - 47
        else this.height = this.$baseTableHeight(3) - 30
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleview(row) {
        updateProduct({
          id: row.id,
          status: row.status === 'ENABLED' ? 'DISABLED' : 'ENABLED',
        }).then((res) => {
          if (res.code === 0) {
            this.fetchData()
            this.$message.success(res.msg)
          }
        })
      },

      handleQuery() {
        this.listQuery.pageNumber = 1
        this.fetchData()
      },
      async fetchData() {
        let params = {
          ...this.listQuery,
          geCreatTime: this.creatTime
            ? dayjs(this.creatTime[0]).format('YYYY-MM-DD')
            : undefined,
          leCreatTime: this.creatTime
            ? dayjs(this.creatTime[1]).format('YYYY-MM-DD')
            : undefined,
        }
        if (this.loading) return
        try {
          this.loading = true
          const {
            data: { records, total },
          } = await getProduct({ ...this.queryForm, ...params })
          this.tableData = records
          this.totalCount = +total
        } finally {
          this.loading = false
        }
      },
      toggleSelection(rows) {
        if (rows) {
          rows.forEach((row) => {
            this.$refs.tableSort.toggleRowSelection(row)
          })
        } else {
          this.$refs.tableSort.clearSelection()
        }
      },
      //重置
      resetQuery() {
        this.listQuery = {
          pageNumber: 1,
          pageSize: 20,
          name: '',
          statusEnum: '',
        }
        this.creatTime = null
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .box-card.el-card {
    border: none !important;
  }
</style>
