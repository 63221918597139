<template>
  <el-dialog
    :before-close="handleClose"
    title="详情"
    :visible="detailVisible"
    width="800px"
  >
    <el-descriptions
      border
      class="margin-top"
      :column="2"
      size="medium"
      title=""
    >
      <el-descriptions-item>
        <template slot="label">用户ID</template>
        {{ rowData.memberId }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">用户名称</template>
        {{ rowData.memberName }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">访问名称</template>
        {{ rowData.name }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">访问路径</template>
        {{ rowData.actionUrl }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">访问参数</template>
        {{ rowData.params }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">ua</template>
        {{ rowData.ua }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">访问IP</template>
        {{ rowData.ip }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">访问时间</template>
        {{ rowData.createTime }}
      </el-descriptions-item>
    </el-descriptions>

    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleOk">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
  export default {
    props: {
      detailVisible: {
        type: Boolean,
        default: () => {
          return false
        },
      },
      rowData: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    methods: {
      handleClose() {
        this.$emit('update:detailVisible', false)
      },
      handleOk() {
        this.$emit('update:detailVisible', false)
      },
    },
  }
</script>
