<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="90px"
          :model="queryParam"
          @submit.native.prevent
        >
          <el-form-item label="商户名称">
            <el-input
              v-model="queryParam.shopName"
              clearable
              placeholder="请选择商户名称"
            />
          </el-form-item>
          <el-form-item label="手机号">
            <el-input
              v-model="queryParam.phone"
              clearable
              placeholder="请选择手机号"
            />
          </el-form-item>
          <el-form-item label="状态">
            <el-select
              v-model="queryParam.status"
              clearable
              placeholder="请选择状态"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="getList"
            >
              查询
            </el-button>

            <el-button icon="el-icon-refresh-left" @click="resetQuery">
              重置
            </el-button>

            <!-- <el-button icon="el-icon-refresh" type="primary" @click="refresh">
              刷新
            </el-button> -->
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :data="dataSource"
      stripe
      @select="selectRow"
      @select-all="selectRow"
    >
      <!-- <el-table-column align="center" type="selection" width="50" /> -->
      <el-table-column align="center" label="序号" width="50">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="商户名称"
        prop="shopName"
        width="200"
      />
      <el-table-column align="center" label="手机号" prop="phone" width="200" />
      <el-table-column
        align="center"
        label="是否admin"
        prop="isAdmin"
        width="160"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isAdmin == 1">
            {{ isAdminFormat(scope.row.isAdmin) }}
          </el-tag>
          <el-tag v-else type="warning">
            {{ isAdminFormat(scope.row.isAdmin) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态" prop="status" width="160">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status">
            {{ statusFormat(scope.row.status) }}
          </el-tag>
          <el-tag v-else type="warning">
            {{ statusFormat(scope.row.status) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="创建时间" prop="createTime" />
      <el-table-column
        align="center"
        label="最后一次登录时间"
        prop="lastLoginTime"
      />
      <!-- <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status == 0"
            plain
            size="mini"
            type="primary"
            @click="handStatus(scope.row)"
          >
            启用
          </el-button>
          <el-button
            v-if="scope.row.status == 1"
            plain
            size="mini"
            type="danger"
            @click="handStatus(scope.row)"
          >
            禁用
          </el-button>
        </template>
      </el-table-column> -->
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>

    <Pagination
      :limit.sync="queryParam.pageSize"
      :page.sync="queryParam.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />
  </div>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import { BasePostMixin } from '@/mixins/BasePostMixin'
  export default {
    name: 'Shopuser',
    components: {
      Pagination,
    },
    mixins: [BasePostMixin],
    data() {
      return {
        disabled: false,
        ddd: 'zc',

        options: [
          {
            value: 0,
            label: '禁用',
          },
          {
            value: 1,
            label: '启用',
          },
        ],
        queryParam: {
          pageNumber: 1,
          pageSize: 20,
          shopName: '',
          phone: '',
          status: '',
        },
        value: '',
        input: '',
        totalCount: 0,
        tableData: [],
        loading: false,
        selection: [],
        url: {
          list: '/admin/merchant/shopuser/page',
          handleEditUrl: '/admin/merchant/shopuser/handStatus',
        },
      }
    },
    created() {
      // this.getList()
    },
    methods: {
      //查询
      getList() {
        this.loadData()
      },
      //表格选择
      selectRow(selection, row) {
        this.selection = selection
        // console.log('单选===', selection)
        console.log('单选===', row)
      },
      // 修改状态
      handStatus(row) {
        console.log('row:', row)
        var tipsWords = ''
        this.editParam = {
          id: row.id,
        }
        if (row.status == 0) {
          tipsWords = '确定启用该账号吗?'
          this.editParam.status = 1
        } else if (row.status == 1) {
          tipsWords = '确定启用该账号吗?'
          this.editParam.status = 0
        }
        this.$confirm(tipsWords, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            console.log('editParam:', this.editParam)
            this.handleEdit()
          })
          .catch(() => {})
      },
      isAdminFormat(status) {
        if (status == true) {
          return '是'
        } else {
          return '否'
        }
      },
      statusFormat(status) {
        if (status === 0) {
          return '禁用'
        } else if (status === 1) {
          return '启用'
        }
      },
      //刷新
      refresh() {
        this.loadData(1)
      },
      //重置
      resetQuery() {
        this.queryParam = {
          pageNumber: 1,
          pageSize: 20,
          shopName: '',
          phone: '',
          status: '',
        }
        this.getList()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-range-editor.el-input__inner {
    width: 105%;
  }
  .el-dialog .el-form-item__label {
    width: 150px !important;
  }
</style>
