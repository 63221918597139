<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="80px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="用户姓名">
            <el-input
              v-model="listQuery.memberName"
              clearable
              placeholder="用户姓名"
            />
          </el-form-item>
          <el-form-item label="商户名称">
            <el-input
              v-model="listQuery.shopName"
              clearable
              placeholder="商户名称"
            />
          </el-form-item>
          <!--<el-form-item label="签约状态" prop="status">
            <el-select
              v-model="listQuery.status"
              clearable
              placeholder="签约状态"
            >
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>-->
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="getList">
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetQuery">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-card class="box-card">
      <el-table v-loading="loading" border :data="tableData" stripe>
        <el-table-column align="center" label="序号" width="50">
          <template #default="{ $index }">
            {{ $index + 1 }}
          </template>
        </el-table-column>
        <el-table-column align="left" label="商户名称" prop="shopName" />
        <el-table-column align="center" label="用户姓名" prop="memberName" />
        <!--<el-table-column align="center" label="是否签约">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status">
              {{ fotmat(scope.row.status, statusList) }}
            </el-tag>
            <el-tag v-else type="warning">
              {{ fotmat(scope.row.status, statusList) }}
            </el-tag>
          </template>
        </el-table-column>-->
        <el-table-column align="center" label="创建时间" prop="createTime" />
        <el-table-column v-if="false" align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              icon="el-icon-more-outline"
              size="mini"
              type="text"
              @click="handleDetail(scope.row, 0)"
            >
              查看
            </el-button>
          </template>
        </el-table-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>

      <Pagination
        :limit.sync="listQuery.pageSize"
        :page.sync="listQuery.pageNumber"
        :total-count="totalCount"
        @pagination="getList"
      />

      <Dialog
        :height="dailog.height"
        :model-value="dailog.visible"
        :title="dailog.title"
        :visible="dailog.visible"
        :width="dailog.width"
        @handleCloseDialog="handleCloseDialog"
        @handleConfirm="handleConfirm"
      >
        <template #content>
          <el-form
            ref="formData"
            label-width="100px"
            :model="formData"
            :rules="rules"
            size="small"
          >
            <el-col :span="12">
              <el-form-item label="商户名称" prop="shopName">
                <el-input
                  v-model="formData.shopName"
                  clearable
                  :disabled="disabled"
                  style="max-width: 300px"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="用户姓名" prop="memberName">
                <el-input
                  v-model="formData.memberName"
                  clearable
                  :disabled="disabled"
                  style="max-width: 300px"
                />
              </el-form-item>
            </el-col>
            <!--   <el-col :span="12">
              <el-form-item label="签约状态" prop="status">
                <el-select
                  v-model="formData.status"
                  clearable
                  :disabled="disabled"
                >
                  <el-option
                    v-for="item in statusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>-->
          </el-form>
        </template>
      </Dialog>
    </el-card>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialog.vue'
  import { vipList } from '@/api/merchant'
  export default {
    name: 'Vip',
    components: {
      Pagination,
      Dialog,
    },
    data() {
      return {
        disabled: false,
        dailog: {
          height: 400,
          title: '商户用户绑定信息',
          visible: false,
          width: '1100px',
        },
        formData: {},
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          memberName: '',
          shopName: '',
          status: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        rules: {},
        statusList: [
          {
            label: '否',
            value: 0,
          },
          {
            label: '是',
            value: 1,
          },
        ],
      }
    },
    created() {
      this.getList()
    },
    methods: {
      //查询
      async getList() {
        this.loading = true
        const {
          data: { records, total },
        } = await vipList(this.listQuery)
        this.loading = false
        this.tableData = records
        this.totalCount = +total
      },
      //编辑,查看
      handleDetail(row, val) {
        this.dailog.visible = true
        if (val === 0) {
          this.disabled = true
        } else {
          this.disabled = false
        }
        this.formData = row
      },
      // dialog确认
      handleConfirm() {
        if (this.disabled) {
          // 查看
          this.handleCloseDialog()
          return
        }
      },
      //打开添加dialog
      showDialog() {
        this.dailog.visible = true
        this.disabled = false
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.formData = {}
      },
      //重置
      resetQuery() {
        this.listQuery = {
          pageNumber: 1,
          pageSize: 20,
          memberName: '',
          shopName: '',
          status: '',
        }
        this.getList()
      },
      // 格式化
      fotmat(val, list) {
        const res = list.filter((item) => item.value === val)
        return res[0].label
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-range-editor.el-input__inner {
    width: 105%;
  }
  .el-card.is-always-shadow {
    box-shadow: none !important;
  }
  .box-card.el-card {
    border: none !important;
  }
  .el-dialog__body .el-form .el-col-12 {
    .el-input,
    .el-select {
      width: 300px;
    }
  }
</style>
