<template>
  <div v-if="defaultFee">
    <el-form ref="formData" label-width="150px" :model="formData" size="small">
      <el-card :body-style="{ border: '0' }" shadow="never">
        <div class="card-title">消耗成本</div>
        <el-row :gutter="20">
          <el-col :span="5">
            <div class="label">
              短信发送费用（可选{{ defaultFee.sendMsgFee.minVal }}~{{
                defaultFee.sendMsgFee.maxVal
              }}）
            </div>
          </el-col>
          <el-col :span="5">
            <el-form-item label="" label-width="0" prop="sendMsgFee">
              <el-input
                v-model="formData.sendMsgFee"
                clearable
                :disabled="disabled"
                min="0"
                style="max-width: 300px"
                type="number"
                @change="
                  (e) =>
                    handleConfirm(e, {
                      businessType: 0,
                      payChannel: 'OFFLINE',
                      key: 'sendMsgFee',
                    })
                "
                @keyup.native="preventNeg($event)"
                @mousewheel.native.prevent
              >
                <template slot="append">
                  {{ defaultFee.sendMsgFee.unit }}
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <div class="label">
              电子合同费用（可选{{ defaultFee.sendMsgFee.minVal }}~{{
                defaultFee.sendMsgFee.maxVal
              }}）
            </div>
          </el-col>
          <el-col :span="5">
            <el-form-item label="" label-width="0" prop="noPaperContractFee">
              <el-input
                v-model="formData.noPaperContractFee"
                clearable
                :disabled="disabled"
                min="0"
                style="max-width: 300px"
                type="number"
                @change="
                  (e) =>
                    handleConfirm(e, {
                      businessType: 0,
                      payChannel: 'OFFLINE',
                      key: 'noPaperContractFee',
                    })
                "
                @keyup.native="preventNeg($event)"
                @mousewheel.native.prevent
              >
                <template slot="append">
                  {{ defaultFee.noPaperContractFee.unit }}
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card :body-style="{ border: '0' }" shadow="never">
        <div class="card-title">房租账单</div>
        <el-row :gutter="20" style="margin-bottom: 18px">
          <el-col :span="4"><div class="label">收款方式</div></el-col>
          <el-col :span="5">
            <div class="label">
              平台服务费（可选{{ defaultFee.platformServiceFee.minVal }}~{{
                defaultFee.platformServiceFee.maxVal
              }}）
            </div>
          </el-col>
          <el-col :span="5">
            <div class="label">
              支付手续费（可选{{ defaultFee.payServiceFee.minVal }}~{{
                defaultFee.payServiceFee.maxVal
              }}）
            </div>
          </el-col>
          <el-col :span="5">
            <div class="label">
              自动提现手续费（可选{{ defaultFee.withdrawServiceFee.minVal }}~{{
                defaultFee.withdrawServiceFee.maxVal
              }}）
            </div>
          </el-col>
          <el-col :span="5">
            <div class="label">
              支付分账服务费{{ defaultFee.splitServiceFee.minVal }}~{{
                defaultFee.splitServiceFee.maxVal
              }}）
            </div>
          </el-col>
        </el-row>
        <el-row
          v-for="(item, index) in formData.rental"
          :key="index"
          :gutter="20"
        >
          <el-col :span="4">
            <div class="label">{{ channel[index].label }}</div>
          </el-col>
          <el-col :span="5">
            <el-form-item
              label=""
              label-width="0"
              :prop="`rental.${index}.platformServiceFee`"
            >
              <el-input
                v-model="formData.rental[index].platformServiceFee"
                clearable
                :disabled="disabled"
                min="0"
                style="max-width: 300px"
                type="number"
                @change="
                  (e) =>
                    handleConfirm(e, {
                      businessType: 1,
                      payChannel: channel[index].value,
                      key: 'platformServiceFee',
                    })
                "
                @keyup.native="preventNeg($event)"
                @mousewheel.native.prevent
              >
                <template slot="append">
                  {{ defaultFee.platformServiceFee.unit }}
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <div v-if="index === 0" class="label">-</div>
            <el-form-item
              v-else
              label=""
              label-width="0"
              :prop="`rental[${index}].payServiceFee`"
            >
              <el-input
                v-model="formData.rental[index].payServiceFee"
                clearable
                :disabled="disabled"
                min="0"
                style="max-width: 300px"
                type="number"
                @change="
                  (e) =>
                    handleConfirm(e, {
                      businessType: 1,
                      payChannel: channel[index].value,
                      key: 'payServiceFee',
                    })
                "
                @keyup.native="preventNeg($event)"
                @mousewheel.native.prevent
              >
                <template slot="append">
                  {{ defaultFee.payServiceFee.unit }}
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <div v-if="index === 0" class="label">-</div>
            <el-form-item
              v-else
              label=""
              label-width="0"
              :prop="`rental[${index}].withdrawServiceFee`"
            >
              <el-input
                v-model="formData.rental[index].withdrawServiceFee"
                clearable
                :disabled="disabled"
                min="0"
                style="max-width: 300px"
                type="number"
                @change="
                  (e) =>
                    handleConfirm(e, {
                      businessType: 1,
                      payChannel: channel[index].value,
                      key: 'withdrawServiceFee',
                    })
                "
                @keyup.native="preventNeg($event)"
                @mousewheel.native.prevent
              >
                <template slot="append">
                  {{ defaultFee.withdrawServiceFee.unit }}
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <div v-if="index === 0" class="label">-</div>
            <el-form-item
              v-else
              label=""
              label-width="0"
              :prop="`rental[${index}].splitServiceFee`"
            >
              <el-input
                v-model="formData.rental[index].splitServiceFee"
                clearable
                :disabled="disabled"
                min="0"
                style="max-width: 300px"
                type="number"
                @change="
                  (e) =>
                    handleConfirm(e, {
                      businessType: 1,
                      payChannel: channel[index].value,
                      key: 'splitServiceFee',
                    })
                "
                @keyup.native="preventNeg($event)"
                @mousewheel.native.prevent
              >
                <template slot="append">
                  {{ defaultFee.splitServiceFee.unit }}
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>

      <el-card :body-style="{ border: '0' }" shadow="never">
        <div class="card-title">费用账单</div>
        <el-row :gutter="20" style="margin-bottom: 18px">
          <el-col :span="4"><div class="label">收款方式</div></el-col>
          <el-col :span="5">
            <div class="label">
              平台服务费（可选{{ defaultFee.platformServiceFee.minVal }}~{{
                defaultFee.platformServiceFee.maxVal
              }}）
            </div>
          </el-col>
          <el-col :span="5">
            <div class="label">
              支付手续费（可选{{ defaultFee.payServiceFee.minVal }}~{{
                defaultFee.payServiceFee.maxVal
              }}）
            </div>
          </el-col>
          <el-col :span="5">
            <div class="label">
              自动提现手续费（可选{{ defaultFee.withdrawServiceFee.minVal }}~{{
                defaultFee.withdrawServiceFee.maxVal
              }}）
            </div>
          </el-col>
          <el-col :span="5">
            <div class="label">
              支付分账服务费{{ defaultFee.splitServiceFee.minVal }}~{{
                defaultFee.splitServiceFee.maxVal
              }}）
            </div>
          </el-col>
        </el-row>
        <el-row
          v-for="(item, index) in formData.cost"
          :key="index"
          :gutter="20"
        >
          <el-col :span="4">
            <div class="label">{{ channel[index].label }}</div>
          </el-col>
          <el-col :span="5">
            <el-form-item
              label=""
              label-width="0"
              :prop="`cost.${index}.platformServiceFee`"
            >
              <el-input
                v-model="formData.cost[index].platformServiceFee"
                clearable
                :disabled="disabled"
                min="0"
                style="max-width: 300px"
                type="number"
                @change="
                  (e) =>
                    handleConfirm(e, {
                      businessType: 2,
                      payChannel: channel[index].value,
                      key: 'platformServiceFee',
                    })
                "
                @keyup.native="preventNeg($event)"
                @mousewheel.native.prevent
              >
                <template slot="append">
                  {{ defaultFee.platformServiceFee.unit }}
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <div v-if="index === 0" class="label">-</div>
            <el-form-item
              v-else
              label=""
              label-width="0"
              :prop="`cost[${index}].payServiceFee`"
            >
              <el-input
                v-model="formData.cost[index].payServiceFee"
                clearable
                :disabled="disabled"
                min="0"
                style="max-width: 300px"
                type="number"
                @change="
                  (e) =>
                    handleConfirm(e, {
                      businessType: 2,
                      payChannel: channel[index].value,
                      key: 'payServiceFee',
                    })
                "
                @keyup.native="preventNeg($event)"
                @mousewheel.native.prevent
              >
                <template slot="append">
                  {{ defaultFee.payServiceFee.unit }}
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <div v-if="index === 0" class="label">-</div>
            <el-form-item
              v-else
              label=""
              label-width="0"
              :prop="`cost[${index}].withdrawServiceFee`"
            >
              <el-input
                v-model="formData.cost[index].withdrawServiceFee"
                clearable
                :disabled="disabled"
                min="0"
                style="max-width: 300px"
                type="number"
                @change="
                  (e) =>
                    handleConfirm(e, {
                      businessType: 2,
                      payChannel: channel[index].value,
                      key: 'withdrawServiceFee',
                    })
                "
                @keyup.native="preventNeg($event)"
                @mousewheel.native.prevent
              >
                <template slot="append">
                  {{ defaultFee.withdrawServiceFee.unit }}
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <div v-if="index === 0" class="label">-</div>
            <el-form-item
              v-else
              label=""
              label-width="0"
              :prop="`cost[${index}].splitServiceFee`"
            >
              <el-input
                v-model="formData.cost[index].splitServiceFee"
                clearable
                :disabled="disabled"
                min="0"
                style="max-width: 300px"
                type="number"
                @change="
                  (e) =>
                    handleConfirm(e, {
                      businessType: 2,
                      payChannel: channel[index].value,
                      key: 'splitServiceFee',
                    })
                "
                @keyup.native="preventNeg($event)"
                @mousewheel.native.prevent
              >
                <template slot="append">
                  {{ defaultFee.splitServiceFee.unit }}
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
  </div>
</template>

<script>
  import { chargeList } from '@/api/basicssetting/index'
  import {
    putCostConfig,
    saveCostConfig,
    getCostConfig,
  } from '@/api/merchant/index'
  import { CommonMixin } from '@/mixins/CommonMixin'
  /**
   * 支付渠道
   */
  const channel = [
    {
      label: '线下支付',
      value: 'OFFLINE',
    },
    {
      label: '易票联',
      value: 'EPL',
    },
    {
      label: '中信银行',
      value: 'CITIC_BANK',
    },
  ]
  const feeKey = [
    'platformServiceFee',
    'payServiceFee',
    'withdrawServiceFee',
    'splitServiceFee',
  ]
  export default {
    name: 'CostSetting',
    mixins: [CommonMixin],
    props: {
      id: {
        type: String,
        default: '',
      },
      name: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        feeKey,
        channel,
        disabled: false,
        formData: {
          sendMsgFee: '',
          noPaperContractFee: '',
          rental: [],
          cost: [],
        },
        defaultFee: null, // 全局默认费用
        shopConfigFee: [], // 商户配置收费标准
      }
    },
    async created() {
      await this.getChargeList()
      await this.getCostConfig()
      this.init()
    },
    methods: {
      // 字符串下划线转驼峰
      formatToHump(value) {
        return value.replace(/_(\w)/g, (_, letter) => letter.toUpperCase())
      },
      /* 获取全局默认费用 */
      async getChargeList() {
        const res = await chargeList({
          shopId: this.id,
        })
        if (res.code == 0 && res.data) {
          console.log('getChargeList res==>', res)
          const defaultFee = Object.fromEntries(
            res.data.records.map((item) => [this.formatToHump(item.code), item])
          )
          defaultFee.platformServiceFee = defaultFee.billServiceFee
          console.log('defaultFee', defaultFee)
          this.defaultFee = defaultFee
        }
      },
      /* 获取商户配置费用 */
      async getCostConfig() {
        const res = await getCostConfig({
          shopId: this.id,
        })
        if (res.code == 0 && res.data && res.data.records) {
          this.shopConfigFee = res.data.records
          console.log('this.shopConfigFee', this.shopConfigFee)
        }
      },
      init() {
        const platform = this.shopConfigFee.find(
          (item) => item.businessType === 0
        )
        const shopRental = this.shopConfigFee.filter(
          (item) => item.businessType === 1
        )
        const shopCost = this.shopConfigFee.filter(
          (item) => item.businessType === 2
        )
        // 消耗成本
        this.formData.sendMsgFee =
          platform && platform.sendMsgFee !== null
            ? platform.sendMsgFee
            : this.defaultFee.sendMsgFee.defaultVal
        this.formData.noPaperContractFee =
          platform && platform.noPaperContractFee !== null
            ? platform.noPaperContractFee
            : this.defaultFee.noPaperContractFee.defaultVal
        // 房租账单
        const rental = channel.map((ritem) => {
          const current = {}
          feeKey.forEach((item) => {
            const currentFee = shopRental.find(
              (v) => v.payChannel === ritem.value
            )
            current[`${item}`] =
              currentFee && currentFee[item] !== null
                ? currentFee[item]
                : this.defaultFee[item].defaultVal
          })
          return current
        })
        console.log('rental', rental)
        this.formData.rental = rental
        // 费用账单
        const cost = channel.map((ritem) => {
          const current = {}
          feeKey.forEach((item) => {
            const currentFee = shopCost.find(
              (v) => v.payChannel === ritem.value
            )
            current[`${item}`] =
              currentFee && currentFee[item] !== null
                ? currentFee[item]
                : this.defaultFee[item].defaultVal
          })
          return current
        })
        console.log('cost', cost)
        this.formData.cost = cost
      },
      async handleConfirm(val, { businessType, payChannel, key }) {
        if (!val) return
        const { minVal, maxVal, name } = this.defaultFee[key]
        if (Number(val) < minVal || Number(val) > maxVal) {
          this.$message.error(`${name}必须在${minVal}~${maxVal}之间`)
          return
        }
        const params = {
          shopId: this.id,
          [key]: Number(val),
          payChannel,
          businessType, // 0 消耗成本、 1 房租账单、 2费用账单
        }
        const exits = this.shopConfigFee.find(
          (item) =>
            item.businessType === businessType && item.payChannel === payChannel
        )
        const res = exits
          ? await putCostConfig({ ...params, id: exits.id })
          : await saveCostConfig(params)
        if (res.code == 0) {
          await this.getCostConfig()
          this.$message.success('保存成功')
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .card-title {
    width: 60px;
    border-bottom: 2px solid #000;
    padding-bottom: 10px;
    margin-bottom: 28px;
  }
  .label {
    line-height: 32px;
  }
</style>
