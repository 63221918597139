<template>
  <div class="index-container">
    <div class="content">
      <div class="left">
        <div class="table">
          <span>数据分类</span>
          <el-input
            v-model="listQuery.name"
            clearable
            placeholder="关键词检索名称,KEY"
            style="width: 200px; margin: 0 20px"
          />
          <el-button
            icon="el-icon-search"
            size="mini"
            type="primary"
            @click="getList"
          >
            查询
          </el-button>
          <el-button
            icon="el-icon-plus"
            size="mini"
            type="primary"
            @click="handleAdd(1)"
          >
            新增
          </el-button>
        </div>
        <el-table
          v-loading="loading"
          border
          :data="tableData"
          stripe
          @cell-click="tableClick"
        >
          <el-table-column align="center" label="序号" width="50">
            <template slot-scope="scope">
              <span>
                {{
                  (listQuery.pageNumber - 1) * listQuery.pageSize +
                  scope.$index +
                  1
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="名称" prop="name" />
          <el-table-column align="center" label="标识KEY" prop="typeKey" />
          <el-table-column
            align="center"
            label="操作"
            prop="address"
            width="100"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                @click="handleEdit(scope.row, 1)"
              >
                编辑
              </el-button>
              <el-button
                size="mini"
                type="text"
                @click="handleDelete(scope.row.id, 1)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
          <template #empty>
            <el-image
              class="vab-data-empty"
              :src="require('@/assets/empty_images/data_empty.png')"
            />
          </template>
        </el-table>
        <Pagination
          :limit.sync="listQuery.pageSize"
          :page.sync="listQuery.pageNumber"
          :total-count="totalCount"
          @pagination="getList"
        />
      </div>
      <div class="right">
        <div v-if="listQuery_sn.typeKey" class="table">
          <span>数据项</span>
          <el-input
            v-model="listQuery_sn.name"
            clearable
            placeholder="关键词检索名称,KEY"
            style="width: 200px; margin: 0 20px"
          />
          <el-button
            icon="el-icon-search"
            size="mini"
            type="primary"
            @click="getList_sn"
          >
            查询
          </el-button>
          <el-button
            icon="el-icon-plus"
            size="mini"
            type="primary"
            @click="handleAdd(2)"
          >
            新增
          </el-button>
          <!-- <el-button
            icon="el-icon-plus"
            size="mini"
            type="primary"
            @click="handleDelete('', 3)"
          >
            清空所有
          </el-button> -->
        </div>
        <el-table v-loading="loading_sn" border :data="tableData_sn" stripe>
          <el-table-column align="center" label="名称" prop="name" />
          <el-table-column align="center" label="编号(SN)" prop="sn" />
          <el-table-column
            align="center"
            label="操作"
            prop="address"
            width="100"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                @click.stop.prevent="handleEdit(scope.row, 2)"
              >
                编辑
              </el-button>
              <el-button
                size="mini"
                type="text"
                @click="handleDelete(scope.row.id, 2)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
          <template #empty>
            <el-image
              class="vab-data-empty"
              :src="require('@/assets/empty_images/data_empty.png')"
            />
          </template>
        </el-table>
        <Pagination
          v-if="tableData_sn.length > 0"
          :limit.sync="listQuery_sn.pageSize"
          :page.sync="listQuery_sn.pageNumber"
          :total-count="totalCount_sn"
          @pagination="getList_sn"
        />
      </div>
    </div>
    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form
          ref="formData_type"
          label-width="100px"
          :model="formData_type"
          :rules="rules_type"
          size="small"
        >
          <el-row>
            <el-form-item label="字典类名称" prop="name">
              <el-input
                v-model="formData_type.name"
                clearable
                placeholder="请输入名称"
                style="max-width: 200px"
              />
            </el-form-item>
            <el-form-item label="标识Key" prop="typeKey">
              <el-input
                v-model="formData_type.typeKey"
                clearable
                placeholder="请输入标识Key"
                style="max-width: 200px"
              />
            </el-form-item>
          </el-row>
        </el-form>
      </template>
    </Dialog>
    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :visible="dailog.visibleSon"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form
          ref="formData_son"
          label-width="100px"
          :model="formData_son"
          :rules="rules_son"
          size="small"
        >
          <el-row>
            <el-form-item label="字典值名称" prop="name">
              <el-input
                v-model="formData_son.name"
                clearable
                placeholder="请输入名称"
                style="max-width: 200px"
              />
            </el-form-item>
            <el-form-item label="编号(SN)" prop="sn">
              <el-input
                v-model="formData_son.sn"
                clearable
                placeholder="请输入标识Key"
                style="max-width: 200px"
              />
            </el-form-item>
            <!-- <el-form-item label="编码" prop="sn">
              <el-input
                v-model="formData_son.sn"
                clearable
                placeholder="请输入标识Key"
                style="max-width: 200px"
              />
            </el-form-item> -->
          </el-row>
        </el-form>
      </template>
    </Dialog>
  </div>
</template>

<script>
  import {
    getDictsAPI,
    addDictsAPI,
    updateDictsAPI,
    deleteDictsAPI,
    getTypesAPI,
    addTypesAPI,
    updateTypesAPI,
    deleteTypesAPI,
  } from '@/api/system/newdic'
  import Dialog from '@/components/dialog.vue'
  import Pagination from '@/components/pagination.vue'
  export default {
    name: 'Dic',
    components: {
      Dialog,
      Pagination,
    },
    data() {
      return {
        listQuery: {
          name: '',
          pageNumber: 1,
          pageSize: 20,
        },
        listQuery_sn: {
          name: '',
          typeKey: '',
          pageNumber: 1,
          pageSize: 20,
        },
        totalCount: 0,
        totalCount_sn: 0,
        // 字典类型
        formData_type: {
          name: '',
          typeKey: '',
        },
        // 字典值
        formData_son: {
          sn: '',
          name: '',
        },
        rules_type: {
          name: [
            { required: true, message: '请输入字典类名称', trigger: 'blur' },
          ],
          typeKey: [
            { required: true, message: '请输入标识Key', trigger: 'blur' },
          ],
        },
        rules_son: {
          name: [
            { required: true, message: '请输入字典值名称', trigger: 'blur' },
          ],
          sn: [{ required: true, message: '请输入编号(SN)', trigger: 'blur' }],
        },
        dailog: {
          height: 130,
          title: '',
          visible: false,
          visibleSon: false,
          width: '400px',
        },
        tableData: [],
        tableData_sn: [],
        options: [
          { value: 1, label: '是' },
          { value: 0, label: '否' },
        ],
        loading: false,
        loading_sn: false,
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      // 列表
      getList() {
        this.loading = true
        getTypesAPI(this.listQuery).then((res) => {
          if (res.code === 0) {
            const { records, total } = res.data
            this.tableData = records
            this.totalCount = +total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      getList_sn() {
        this.loading_sn = true
        getDictsAPI(this.listQuery_sn).then((res) => {
          if (res.code === 0) {
            const { records, total } = res.data
            this.tableData_sn = records
            this.totalCount_sn = +total
            console.log(this.tableData_sn)
            this.loading_sn = false
          } else {
            this.loading_sn = false
          }
        })
      },
      // 点击左侧的单元格
      tableClick(res) {
        console.log('🚀 ~ file: dic.vue:347 ~ tableClick ~ res', res)
        this.listQuery_sn.typeKey = res.typeKey
        this.listQuery_sn.typeId = res.id
        this.getList_sn()
      },
      // 新增
      handleAdd(type) {
        if (type === 1) {
          this.dailog.visible = true
          this.dailog.title = '添加新数据分类'
          this.formData_type = { name: '', typeKey: '' }
        } else {
          this.dailog.visibleSon = true
          this.dailog.title = '添加分类数据项'
          this.formData_son = {
            sn: '',
            name: '',
          }
        }
      },
      // 删除
      handleDelete(id, type) {
        const warning =
          type === 3 ? '是否删除该分类下的所有字典?' : '是否删除该字典?'
        this.$confirm(warning, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          console.log(id)
          if (type === 1) {
            deleteTypesAPI(id).then((res) => {
              this.commonMessage(res)
              this.dailog.visible = false
              this.tableData_sn = []
              this.totalCount_sn = 0
            })
          } else {
            deleteDictsAPI(id).then((res) => {
              this.commonMessage(res)
              this.dailog.visibleSon = false
              this.getList_sn()
            })
          }
        })
      },
      // 编辑
      handleEdit(row, type) {
        if (type === 1) {
          this.dailog.visible = true
          this.dailog.title = '编辑新数据分类'
          this.formData_type = {
            name: row.name,
            id: row.id,
            typeKey: row.typeKey,
          }
        } else {
          this.dailog.visibleSon = true
          this.dailog.title = '编辑字典值'
          this.formData_son = {
            name: row.name,
            id: row.id,
            sn: row.sn,
          }
        }
      },

      handleCloseDialog() {
        if (
          this.dailog.title === '添加新数据分类' ||
          this.dailog.title === '编辑新数据分类'
        ) {
          this.dailog.visible = false
          this.$refs.formData_type.resetFields()
        } else if (
          this.dailog.title === '添加分类数据项' ||
          this.dailog.title === '编辑字典值'
        ) {
          this.dailog.visibleSon = false
          this.$refs.formData_son.resetFields()
        }
      },
      handleConfirm() {
        if (this.dailog.title === '添加新数据分类') {
          this.$refs.formData_type.validate((valid) => {
            if (valid) {
              addTypesAPI(this.formData_type).then((res) => {
                this.commonMessage(res)
                this.handleCloseDialog()
              })
            }
          })
        }
        if (this.dailog.title === '编辑新数据分类') {
          this.$refs.formData_type.validate((valid) => {
            if (valid) {
              updateTypesAPI(this.formData_type).then((res) => {
                this.commonMessage(res)
                this.handleCloseDialog()
              })
            }
          })
        }
        if (this.dailog.title === '添加分类数据项') {
          this.$refs.formData_son.validate((valid) => {
            if (valid) {
              addDictsAPI({
                ...this.formData_son,
                typeKey: this.listQuery_sn.typeKey,
                typeId: this.listQuery_sn.typeId,
              }).then((res) => {
                this.commonMessage(res)
                this.handleCloseDialog()
                this.getList_sn()
              })
            }
          })
        }
        if (this.dailog.title === '编辑字典值') {
          this.$refs.formData_son.validate((valid) => {
            if (valid) {
              updateDictsAPI(this.formData_son).then((res) => {
                this.commonMessage(res)
                this.handleCloseDialog()
                this.getList_sn()
              })
            }
          })
        }
      },
      commonMessage(res) {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.msg,
          })
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .index-container {
    .content {
      // background-color: aqua;
      display: flex;

      // box-sizing: border-box;
      .left {
        flex: 1;
        padding: 20px;
        margin-right: 10px;
        border: 1px solid #ccc;

        .table {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
        }
      }

      .right {
        flex: 1;
        width: 300px;
        padding: 20px;
        // padding-top: 20px;
        // padding-right: 20px;
        border: 1px solid #ccc;

        .table {
          // display: flex;
          align-items: center;
          margin-bottom: 20px;
        }
      }
    }
  }

  :deep() {
    .el-table--border .el-table__cell:first-child .cell {
      padding-left: 0;
    }

    .el-table th.el-table__cell > .cell {
      padding-right: 0;
    }

    .el-table .cell {
      padding: 0;
    }

    .el-table--enable-row-hover .el-table__body tr:hover > td {
      cursor: pointer;
    }
  }
</style>
