<template>
  <div class="index-container">
    <div class="top">
      <div class="left">
        <el-button
          icon="el-icon-plus"
          size="mini"
          type="primary"
          @click="handleAdd"
        >
          新增
        </el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      border
      :data="tableData"
      :default-expand-all="false"
      row-key="id"
      stripe
      :tree-props="{ children: 'children' }"
    >
      <el-table-column
        align="center"
        label="序号"
        prop="sortIndex"
        width="100"
      />
      <el-table-column label="资源" min-width="140" prop="name" />
      <!-- <el-table-column align="center" label="编码" prop="id" /> -->
      <el-table-column align="center" label="资源类型" prop="name">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.type === 1">菜单</el-tag>
          <el-tag v-else type="success">按钮</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="前端路由"
        min-width="180"
        prop="path"
      />
      <el-table-column
        align="center"
        label="后端PATH"
        min-width="180"
        prop="actionPath"
      />
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="handleAddNextSource(scope.row)"
          >
            添加下级资源
          </el-button>
          <el-button
            size="mini"
            type="text"
            @click="handleSort(scope.row.id, scope.row.name)"
          >
            排序
          </el-button>
          <el-button size="mini" type="text" @click="handleEdit(scope.row)">
            编辑
          </el-button>
          <el-button
            size="mini"
            type="text"
            @click="handleDelete(scope.row.id)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form
          ref="formData"
          label-width="120px"
          :model="formData"
          :rules="rules"
          size="small"
        >
          <el-row>
            <el-form-item label="上级资源" prop="parentName">
              <el-select
                v-model="formData.parentName"
                clearable
                placeholder="请选择"
                style="width: 300px"
                @clear="handleClear"
              >
                <el-option
                  style="height: auto; padding: 0; overflow-y: auto"
                  :value="tableData"
                >
                  <el-tree
                    ref="tree"
                    check-on-click-node
                    :data="treeList"
                    default-expand-all
                    :highlight-current="true"
                    node-key="id"
                    :props="{
                      label: 'name',
                      children: 'children',
                    }"
                    @node-click="handleCheck"
                  />
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="资源名称" prop="name">
              <el-input
                v-model="formData.name"
                clearable
                placeholder="请输入资源名称(例:系统管理)"
                style="max-width: 300px"
              />
            </el-form-item>
            <el-form-item label="资源图标" prop="icon">
              <el-input
                v-model="formData.icon"
                clearable
                placeholder="请输入资源图标(例:admin-fill)"
                style="max-width: 300px"
              >
                <el-button slot="append" icon="el-icon-search">
                  <a
                    href="https://vue-admin-beautiful.com/admin-plus/#/vab/icon/remixIcon"
                    style="text-decoration: none"
                    target="_blank"
                  >
                    搜索
                  </a>
                </el-button>
              </el-input>
            </el-form-item>
            <el-form-item label="前端路由" prop="path">
              <el-input
                v-model="formData.path"
                clearable
                placeholder="请输入前端的路由路径"
                style="max-width: 300px"
              />
            </el-form-item>
            <el-form-item label="后端path" prop="actionPath">
              <el-input
                v-model="formData.actionPath"
                clearable
                placeholder="后端controller的path"
                style="max-width: 300px"
              />
            </el-form-item>
            <el-form-item label="资源类型" prop="type">
              <el-select
                v-model="formData.type"
                placeholder="请选择资源类型"
                style="width: 300px"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="排序" prop="sortRank">
              <el-input
                v-model="formData.sortRank"
                clearable
                placeholder="请输入序号( 比如挪到第一,就输入1)"
                style="max-width: 300px"
              />
            </el-form-item> -->
            <!-- <el-form-item label="图标">
              <el-popover
                popper-class="icon-selector-popper"
                trigger="hover"
                width="292"
              >
                <template #reference>
                  <el-input v-model="formData.icon" />
                </template>
                <vab-icon-selector @handle-icon="handleIcon" />
              </el-popover>
            </el-form-item> -->
          </el-row>
        </el-form>
      </template>
    </Dialog>
    <Dialog
      :height="dailog.height"
      :model-value="dailog.visibleSort"
      :title="dailog.title"
      :visible="dailog.visibleSort"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form label-width="100px" size="small">
          <el-row>
            <el-form-item label="排序">
              <el-input
                v-model="sortData.sortRank"
                clearable
                placeholder="请输入序号( 比如挪到第一,就输入1)"
                style="max-width: 300px"
              />
            </el-form-item>
            <div
              style="
                padding-left: 100px;
                color: #ccc;
                margin-top: -10px;
                font-size: 14px;
                margin-bottom: 4px;
              "
            >
              提示：按排序号数字降序排序，数字越大，排序越前
            </div>
          </el-row>
        </el-form>
      </template>
    </Dialog>
  </div>
</template>

<script>
  import {
    getMenuAPI,
    addMenuAPI,
    putMenuAPI,
    deleteMenuAPI,
  } from '@/api/system/newMenu'
  // import VabIconSelector from '@/extra/VabIconSelector'
  import Dialog from '@/components/dialog.vue'
  import store from '@/store'
  export default {
    name: 'Menu',
    components: {
      Dialog,
      // VabIconSelector,
    },
    data() {
      return {
        sortData: {
          id: '',
          sortRank: null,
        },
        dialogVisibleSort: false,
        dailog: {
          height: null,
          title: '',
          visible: false,
          width: '600px',
          visibleSort: false,
        },
        listQuery: {
          name: '',
          pageNumber: 1,
          pageSize: 10,
        },
        tableData: [],
        loading: false,
        formData: {
          parentName: '',
          name: '',
          pid: '',
          path: '',
          actionPath: '',
          type: null, // 1菜单 2按钮
          icon: '',
          sortRank: '',
        },
        name: '',
        code: '',
        list: [],
        options: [
          { value: 1, label: '菜单' },
          { value: 0, label: '按钮' },
        ],
        treeList: [
          {
            id: '0',
            name: '主菜单',
            children: [],
          },
        ],
        rules: {
          parentName: [
            {
              required: true,
              message: '请选择上级资源菜单',
              trigger: 'change',
            },
          ],
          name: [
            { required: true, message: '请输入资源名称', trigger: 'blur' },
          ],
          path: [
            { required: true, message: '请输入资源地址', trigger: 'blur' },
          ],
          actionPath: [
            { required: true, message: '请输入后端path', trigger: 'change' },
          ],
          type: [
            { required: true, message: '请选择资源类型', trigger: 'change' },
          ],
          // sortRank: [
          //   { required: true, message: '请选择资源类型', trigger: 'blur' },
          // ],
        },
        route: {},
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      // 递归树给他加索引
      treeData(data) {
        for (let i = 0; i < data.length; i++) {
          data[i].sortIndex = data.length - i
          if (data[i].children && data[i].children.length > 0) {
            this.treeData(data[i].children)
          }
        }
        return data
      },
      handleSort(id, name) {
        console.log(id)
        this.dailog.title = `${name}-菜单排序`
        this.dailog.visibleSort = true
        this.sortData.id = id
      },
      handleIcon(item) {
        this.formData.icon = item
      },
      getList() {
        this.loading = true
        getMenuAPI({}).then((res) => {
          if (res.code === 0) {
            this.tableData = this.treeData(res.data)
            // console.log(this.tableData)
            this.treeList[0].children = this.tableData
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      // 根据pid找出对应的路由的名字
      findParentName(tree, pid) {
        tree.forEach((item) => {
          if (item.id === pid) {
            this.route = item
          } else if (item.children && item.children.length) {
            this.findParentName(item.children, pid)
          }
        })
      },
      // 编辑
      handleEdit(row) {
        console.log(row, this.route.name)
        this.dailog.visible = true
        this.dailog.title = '编辑资源菜单'
        this.$nextTick(() => {
          this.formData = { ...row, parentName: '' }
          const { pid, id } = row
          if (pid === '0') {
            this.formData.parentName = '主菜单'
          } else {
            this.findParentName(this.tableData, pid)
            this.formData.parentName = this.route.name
          }
          this.$refs.tree.setCurrentKey(id)
        })
      },
      // 删除菜单
      handleDelete(id) {
        this.$confirm('是否删除该菜单?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          deleteMenuAPI(id).then((res) => {
            this.commonMessage(res)
            this.getList()
          })
        })
      },
      // 添加资源
      handleAdd(row) {
        console.log(row)
        this.dailog.visible = true
        this.dailog.title = '新增资源'
      },
      // 添加下级资源
      handleAddNextSource(row) {
        this.dailog.visible = true
        this.dailog.title = '添加下级资源'
        console.log(row)
        const { id, name } = row
        this.formData.pid = id
        this.formData.parentName = name
        // 默认高亮选中节点
        this.$nextTick(() => {
          this.$refs.tree.setCurrentKey(id)
        })
      },
      // 确认新增
      handleConfirm() {
        if (this.dailog.title.indexOf('排序') !== -1) {
          if (!this.sortData.sortRank) {
            this.$message({
              type: 'warning',
              message: '排序号不能为空!',
            })
            return
          } else {
            this.sortData.sortRank = Number(this.sortData.sortRank)
          }
          console.log(this.sortData)
          putMenuAPI(this.sortData).then((res) => {
            this.commonMessage(res)
          })
        } else {
          this.$refs['formData'].validate((valid) => {
            console.log(valid)
            if (valid) {
              if (
                this.dailog.title === '新增资源' ||
                this.dailog.title === '添加下级资源'
              ) {
                addMenuAPI({
                  parentName: this.formData.parentName,
                  icon: this.formData.icon,
                  path: this.formData.path,
                  type: this.formData.type,
                  actionPath: this.formData.actionPath,
                  name: this.formData.name,
                  sortRank: '',
                  pid: this.formData.pid,
                }).then((res) => {
                  this.commonMessage(res)
                })
              } else {
                putMenuAPI(this.formData).then((res) => {
                  this.commonMessage(res)
                })
              }
            }
          })
        }
      },
      async commonMessage(res) {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.handleCloseDialog()
          this.getList()
          if (this.dailog.title.indexOf('排序') === -1) {
            this.$refs['formData'].resetFields()
          }
          const reload = await store.dispatch('routes/getMenuList') // (新改造)
          await store.dispatch('routes/setRoutes', reload)
        } else {
          this.$message({
            type: 'error',
            message: res.msg,
          })
        }
      },
      handleCloseDialog() {
        if (this.dailog.title.indexOf('排序') !== -1) {
          this.dailog.visibleSort = false
          this.sortData.id = ''
          this.sortData.sortRank = null
        } else {
          this.$refs['formData'].resetFields()
          this.dailog.visible = false
        }
      },
      // 点击节点
      handleCheck(val) {
        console.log(val)
        const { id, name } = val
        this.formData.pid = id
        this.formData.parentName = name
        // this.formData.path = path
      },
      // 清空
      handleClear(val) {
        console.log(val)
        if (!val || val === '') {
          this.code = ''
        }
      },
    },
  }
</script>

<style lang="scss">
  .index-container {
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .left {
        display: flex;
      }
    }
  }
</style>
