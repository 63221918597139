<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="90px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="商户名称">
            <el-input
              v-model="listQuery.shopName"
              clearable
              placeholder="请输入商户名称"
            />
          </el-form-item>
          <el-form-item label="楼栋名称">
            <el-input
              v-model="listQuery.buildingName"
              clearable
              placeholder="请输入楼栋名称"
            />
          </el-form-item>
          <el-form-item label="房源名称">
            <el-input
              v-model="listQuery.houseName"
              clearable
              placeholder="请输入房源名称"
            />
          </el-form-item>
          <el-form-item label="用户姓名">
            <el-input
              v-model="listQuery.memberName"
              clearable
              placeholder="请输入用户姓名"
            />
          </el-form-item>
          <el-form-item label="合同编号">
            <el-input
              v-model="listQuery.code"
              clearable
              placeholder="请输入合同编号"
            />
          </el-form-item>
          <el-form-item label="业务类型">
            <el-select v-model="listQuery.type" clearable>
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="业务状态">
            <el-select v-model="listQuery.status" clearable>
              <el-option
                v-for="item in orderStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="getList">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>
    <el-table v-loading="loading" border :data="tableData" stripe>
      <el-table-column align="center" label="序号" width="50">
        <template slot-scope="scope">
          {{
            (listQuery.pageNumber - 1) * listQuery.pageSize + scope.$index + 1
          }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="用户姓名"
        prop="memberName"
        width="200"
      />
      <el-table-column
        align="center"
        label="商户名称"
        prop="shopName"
        width="250"
      />
      <el-table-column align="center" label="业务类型" width="150">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.type">
            {{ format(scope.row.type, typeList) }}
          </el-tag>
          <el-tag v-else type="warning">
            {{ format(scope.row.sex, typeList) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="处理状态" width="150">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 1" type="success">
            {{ format(scope.row.status, orderStatus) }}
          </el-tag>
          <el-tag v-if="scope.row.status === 2">
            {{ format(scope.row.status, orderStatus) }}
          </el-tag>
          <el-tag v-if="scope.row.status === 3">
            {{ format(scope.row.status, orderStatus) }}
          </el-tag>
          <el-tag v-if="scope.row.status === 4" type="danger">
            {{ format(scope.row.status, orderStatus) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="合同编号"
        prop="code"
        width="300"
      />
      <el-table-column
        align="center"
        label="楼栋名称"
        prop="buildingName"
        width="150"
      />
      <el-table-column
        align="center"
        label="房源名称"
        prop="houseName"
        width="150"
      />
      <el-table-column
        align="center"
        label="申请原因"
        prop="applyContent"
        width="250"
      />
      <el-table-column
        align="center"
        label="审批备注"
        prop="auditContent"
        width="250"
      />
      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        width="200"
      />
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />
  </div>
</template>

<script>
  import { memberServiceOrderList } from '@/api/member/memberserviceorder'
  import Pagination from '@/components/pagination.vue'
  export default {
    name: 'MemberServiceOrder',
    components: {
      Pagination,
    },
    data() {
      return {
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          shopName: '',
          buildingName: '',
          houseName: '',
          memberName: '',
          code: '',
          type: '',
          status: '',
        },
        totalCount: 0,
        tableData: [],
        tableData1: [],
        typeList: [
          {
            label: '换房',
            value: 1,
          },
          {
            label: '退房',
            value: 2,
          },
          {
            label: '续租',
            value: 3,
          },
        ],
        orderStatus: [
          {
            label: '通过',
            value: 1,
          },
          {
            label: '待处理',
            value: 2,
          },
          {
            label: '用户取消',
            value: 3,
          },
          {
            label: '驳回',
            value: 4,
          },
        ],
        loading: false,
        // 格式化
        format(val, list) {
          const res = list.filter((item) => item.value === val)
          if (res.length > 0) {
            return res[0].label
          } else {
            return '未知'
          }
        },
      }
    },
    created() {
      this.getList()
    },
    methods: {
      //查询
      async getList() {
        this.loading = true
        const {
          data: { records, total },
        } = await memberServiceOrderList(this.listQuery)
        this.loading = false
        this.tableData = records
        this.totalCount = +total
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-range-editor.el-input__inner {
    width: 105%;
  }
</style>
