<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="80px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="商户名称">
            <el-input
              v-model="listQuery.shopName"
              clearable
              placeholder="商户名称"
            />
          </el-form-item>
          <el-form-item label="交易类型" prop="amountType">
            <el-select
              v-model="listQuery.amountType"
              clearable
              placeholder="交易类型"
            >
              <el-option
                v-for="item in amountTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="业务类型" prop="amountType">
            <el-select
              v-model="listQuery.businessType"
              clearable
              placeholder="业务类型"
            >
              <el-option
                v-for="item in businessTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="getList">
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetQuery">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-card class="box-card">
      <el-row style="margin-bottom: 10px">
        <!-- <el-button icon="el-icon-refresh" type="primary" @click="getList">
          刷新
        </el-button> -->
      </el-row>
      <el-table v-loading="loading" border :data="tableData" stripe>
        <el-table-column align="center" label="序号" width="50">
          <template #default="{ $index }">
            {{ $index + 1 }}
          </template>
        </el-table-column>
        <el-table-column align="left" label="商户名称" prop="shopName" />
        <el-table-column align="left" label="上次余额" prop="beforeBalance" />
        <el-table-column
          align="left"
          :formatter="amountFormat"
          label="本次交易金额"
          prop="amount"
          width="120"
        />
        <el-table-column align="left" label="本次余额" prop="afterBalance" />
        <el-table-column align="center" label="交易类型" width="100">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.amountType === 1">
              {{ fotmat(scope.row.amountType, amountTypeList) }}
            </el-tag>
            <el-tag v-else type="success">
              {{ fotmat(scope.row.amountType, amountTypeList) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="账单编号"
          prop="orderNo"
          width="200"
        />
        <el-table-column align="center" label="业务类型">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.businessType">
              {{ fotmat(scope.row.businessType, businessTypeList) }}
            </el-tag>
            <el-tag v-else type="warning">
              {{ fotmat(scope.row.businessType, businessTypeList) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="支付方式" prop="payWay" />
        <el-table-column align="center" label="创建时间" prop="createTime" />
        <el-table-column align="center" fixed="right" label="操作" width="80">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="handleDetail(scope.row, 0)"
            >
              详情
            </el-button>
          </template>
        </el-table-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>

      <Pagination
        :limit.sync="listQuery.pageSize"
        :page.sync="listQuery.pageNumber"
        :total-count="totalCount"
        @pagination="getList"
      />

      <Dialog
        :height="dailog.height"
        :model-value="dailog.visible"
        :title="dailog.title"
        :visible="dailog.visible"
        :width="dailog.width"
        @handleCloseDialog="handleCloseDialog"
        @handleConfirm="handleConfirm"
      >
        <template #content>
          <el-descriptions border class="margin-top" :column="2" size="medium">
            <el-descriptions-item>
              <template slot="label">商户名称</template>
              {{ formData.shopName }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">上次金额</template>
              {{ formData.beforeBalance }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">本次交易金额</template>
              {{ formData.amountFormat }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">本次余额</template>
              {{ formData.afterBalance }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">消费类型</template>
              {{ $arrFindItem(formData.amountType, amountTypeList) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">业务类型</template>
              {{ $arrFindItem(formData.businessType, businessTypeList) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">支付方式</template>
              {{ formData.payWay }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">账单编号</template>
              {{ formData.orderNo }}
            </el-descriptions-item>
          </el-descriptions>
        </template>
      </Dialog>
    </el-card>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialog.vue'
  import { payRecordList } from '@/api/merchant'
  export default {
    name: 'PayRecord',
    components: {
      Pagination,
      Dialog,
    },
    data() {
      return {
        disabled: false,
        dailog: {
          height: 170,
          title: '商户钱包消费记录',
          visible: false,
          width: '700px',
        },
        formData: {},
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          amountType: '',
          shopName: '',
          businessType: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        rules: {},
        amountTypeList: [
          {
            label: '充值',
            value: 1,
          },
          {
            label: '扣费',
            value: 2,
          },
        ],
        businessTypeList: [
          {
            label: '充值',
            value: 1,
          },
          {
            label: '短信发送费用',
            value: 2,
          },
          {
            label: '电子合同费用',
            value: 3,
          },
          {
            label: '支付手续费',
            value: 4,
          },
          {
            label: '平台服务费',
            value: 5,
          },
          {
            label: '支付分账服务费',
            value: 6,
          },
          {
            label: '自动提现服务费',
            value: 7,
          },
          {
            label: '短信充值费用',
            value: 8,
          },
          {
            label: '电子合同充值费',
            value: 9,
          },
        ],
      }
    },
    created() {
      this.getList()
    },
    methods: {
      // 本次交易金额  1 充值 2 扣费
      amountFormat(row) {
        if (row.amountType == 1) {
          return '+' + row.amount
        } else {
          return '-' + row.amount
        }
      },
      //查询
      async getList() {
        this.loading = true
        const {
          data: { records, total },
        } = await payRecordList(this.listQuery)
        this.loading = false
        this.tableData = records
        this.totalCount = +total
      },
      //编辑,查看
      handleDetail(row, val) {
        this.dailog.visible = true
        if (val === 0) {
          this.disabled = true
        } else {
          this.disabled = false
        }
        this.formData = row
        if (row.amountType == 2) {
          this.formData.amountFormat = '-' + row.amount
        } else {
          this.formData.amountFormat = '+' + row.amount
        }
      },
      // dialog确认
      handleConfirm() {
        if (this.disabled) {
          // 查看
          this.handleCloseDialog()
          return
        }
      },
      //打开添加dialog
      showDialog() {
        this.dailog.visible = true
        this.disabled = false
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.formData = {}
      },
      //重置
      resetQuery() {
        this.listQuery = {
          pageNumber: 1,
          pageSize: 20,
          amountType: '',
          shopName: '',
          businessType: '',
        }
        this.getList()
      },
      // 格式化
      fotmat(val, list) {
        const res = list.filter((item) => item.value === val)
        return res[0].label
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-range-editor.el-input__inner {
    width: 105%;
  }

  .el-card.is-always-shadow {
    box-shadow: none !important;
  }

  .box-card.el-card {
    border: none !important;
  }

  .el-dialog__body .el-form .el-col-12 {
    .el-input,
    .el-select {
      width: 300px;
    }
  }
</style>
