<template>
  <div class="index-container sms-sending-record">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="80px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="商户名称">
            <el-input
              v-model="listQuery.shopName"
              clearable
              placeholder="商户名称"
            />
          </el-form-item>
          <el-form-item label="登录帐号">
            <el-input
              v-model="listQuery.adminPhone"
              clearable
              placeholder="登录帐号"
            />
          </el-form-item>
          <el-form-item label="通知类型">
            <el-select
              v-model="listQuery.configId"
              clearable
              filterable
              :loading="optionLoading"
              placeholder="请输入关键词"
              remote
              :remote-method="remoteMethod"
              reserve-keyword
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.scenes"
                :value="item.scenesCode"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="发送状态">
            <el-select
              v-model="listQuery.status"
              clearable
              placeholder="请选择"
            >
              <el-option label="全部" value="" />
              <el-option label="发送成功" value="SUCCESS" />
              <el-option label="发送失败" value="FAILED" />
              <el-option label="未发送" value="UNSENT" />
            </el-select>
          </el-form-item>

          <el-form-item label="发送时间">
            <el-date-picker
              v-model="sendTime"
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetQuery">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @selection-change="setSelectRows"
    >
      <!-- <el-table-column align="center" type="selection" width="50" /> -->
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          <span>
            {{ (listQuery.pageNumber - 1) * listQuery.pageSize + $index + 1 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="商户名称" prop="shopName" />
      <el-table-column align="center" label="登录帐号" prop="adminPhone" />
      <el-table-column align="center" label="用户名称" prop="memberName" />
      <el-table-column align="center" label="接收人手机号" prop="sendPhone" />
      <el-table-column
        align="center"
        label="通知事项"
        prop="scenes"
        width="100"
      />
      <el-table-column align="center" label="发送内容">
        <template slot-scope="scope">
          <div v-html="scope.row.smsContent"></div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="发送状态" prop="status">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 'SUCCESS'" type="success">
            发送成功
          </el-tag>
          <el-tag v-if="scope.row.status === 'FAILED'" type="danger">
            发送失败
          </el-tag>
          <el-tag v-if="scope.row.status === 'UNSENT'" type="primary">
            未发送
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="发送时间" prop="sendTime" />
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="fetchData"
    />
  </div>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import { getSendDetails } from '@/api/smsSendingRecord/index'
  import { getSmsConfig } from '@/api/smsStencilManagement/index'
  const dayjs = require('dayjs')
  export default {
    name: 'SmsSendingRecord',
    components: {
      Pagination,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        options: [],
        sendTime: null,
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          shopName: '',
          adminPhone: '',
          configId: '',
          status: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        optionLoading: false,
        fold: false,
      }
    },
    beforeMount() {
      window.addEventListener('resize', this.handleHeight)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleHeight)
    },
    created() {
      if (this.$route.query?.adminPhone) {
        this.listQuery.adminPhone = this.$route.query?.adminPhone
      }
      this.fetchData()
    },
    methods: {
      remoteMethod(query) {
        if (query !== '') {
          this.optionLoading = true
          getSmsConfig({ scenes: query, pageSize: 1000, pageNumber: 1 })
            .then((res) => {
              if (res.code === 0) {
                console.log(res, 'this.options')
                this.options = res.data.records
              }
            })
            .finally(() => {
              this.optionLoading = false
            })
        } else {
          this.options = []
        }
      },
      handleFold() {
        this.fold = !this.fold
        this.handleHeight()
      },
      handleHeight() {
        if (this.fold) this.height = this.$baseTableHeight(2) - 47
        else this.height = this.$baseTableHeight(3) - 30
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleQuery() {
        this.listQuery.pageNumber = 1
        this.fetchData()
      },
      async fetchData() {
        let params = {
          ...this.listQuery,
          geSendTime: this.sendTime
            ? dayjs(this.sendTime[0]).format('YYYY-MM-DD')
            : undefined,

          leSendTime: this.sendTime
            ? dayjs(this.sendTime[1]).format('YYYY-MM-DD')
            : undefined,
        }
        if (this.loading) return
        try {
          this.loading = true
          const {
            data: { records, total },
          } = await getSendDetails({ ...this.queryForm, ...params })
          this.tableData = records
          this.totalCount = +total
        } finally {
          this.loading = false
        }
      },
      toggleSelection(rows) {
        if (rows) {
          rows.forEach((row) => {
            this.$refs.tableSort.toggleRowSelection(row)
          })
        } else {
          this.$refs.tableSort.clearSelection()
        }
      },
      //重置
      resetQuery() {
        this.listQuery = {
          pageNumber: 1,
          pageSize: 20,
          shopName: '',
          adminPhone: '',
          configId: '',
          status: '',
        }
        this.sendTime = null
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .box-card.el-card {
    border: none !important;
  }
</style>
