<template>
  <div class="index-container sms-management">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="80px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="商户名称">
            <el-input
              v-model="listQuery.shopName"
              clearable
              placeholder="商户名称"
            />
          </el-form-item>
          <el-form-item label="登录帐号">
            <el-input
              v-model="listQuery.adminPhone"
              clearable
              placeholder="登录帐号"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetQuery">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @selection-change="setSelectRows"
    >
      <!-- <el-table-column align="center" type="selection" width="50" /> -->
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          <span>
            {{ (listQuery.pageNumber - 1) * listQuery.pageSize + $index + 1 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="商户名称" prop="shopName" />
      <el-table-column align="center" label="登录帐号" prop="adminPhone" />
      <el-table-column align="center" label="短信余量(条)" prop="smsQuantity" />
      <el-table-column align="center" label="使用数量(条)" prop="smsUsage" />
      <!-- <el-table-column align="center" label="短信充值" width="100">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleview(scope.row)">
            充值
          </el-button>
        </template>
      </el-table-column> -->
      <el-table-column align="center" fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <!-- <el-button size="mini" type="text" @click="handleview(scope.row)">
            充值
          </el-button> -->
          <el-button size="mini" type="text" @click="smsbuy(scope.row)">
            购买记录
          </el-button>
          <el-button size="mini" type="text" @click="smsSend(scope.row)">
            发送记录
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <rechargeVue
      v-if="rechargeVisible"
      :recharge-visible.sync="rechargeVisible"
      :row-data="rowData"
    />
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="fetchData"
    />
  </div>
</template>

<script>
  import { getSmsUsage } from '@/api/smsManagement/index'
  import Pagination from '@/components/pagination.vue'
  import rechargeVue from './components/recharge.vue'
  export default {
    name: 'SmsManagement',
    components: {
      Pagination,
      rechargeVue,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        rechargeVisible: false,
        rowData: {},
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          shopName: '',
          adminPhone: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        fold: false,
      }
    },
    beforeMount() {
      window.addEventListener('resize', this.handleHeight)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleHeight)
    },
    created() {
      this.fetchData()
    },
    methods: {
      handleFold() {
        this.fold = !this.fold
        this.handleHeight()
      },
      handleHeight() {
        if (this.fold) this.height = this.$baseTableHeight(2) - 47
        else this.height = this.$baseTableHeight(3) - 30
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleview(row) {
        this.rechargeVisible = true
        this.rowData = { ...row }
      },
      smsbuy(row) {
        this.$router.push({
          name: 'SmsBuy',
          query: { adminPhone: row.adminPhone },
        })
      },
      smsSend(row) {
        this.$router.push({
          name: 'SmsSendingRecord',
          query: { adminPhone: row.adminPhone },
        })
      },
      handleQuery() {
        this.listQuery.pageNumber = 1
        this.fetchData()
      },
      async fetchData() {
        if (this.loading) return
        try {
          this.loading = true
          const {
            data: { records, total },
          } = await getSmsUsage({ ...this.queryForm, ...this.listQuery })
          this.tableData = records
          this.totalCount = +total
        } finally {
          this.loading = false
        }
      },
      toggleSelection(rows) {
        if (rows) {
          rows.forEach((row) => {
            this.$refs.tableSort.toggleRowSelection(row)
          })
        } else {
          this.$refs.tableSort.clearSelection()
        }
      },
      //重置
      resetQuery() {
        this.listQuery = {
          pageNumber: 1,
          pageSize: 20,
          shopName: '',
          adminPhone: '',
        }
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .box-card.el-card {
    border: none !important;
  }
</style>
