<template>
  <Dialog
    :height="dailog.height"
    :model-value="dailog.visible"
    :title="title + dailog.title"
    :visible="dailog.visible"
    :width="dailog.width"
    @handleCloseDialog="handleCloseDialog"
    @handleConfirm="handleCloseDialog"
  >
    <template #content>
      <vab-query-form>
        <vab-query-form-top-panel>
          <el-form
            ref="form"
            :inline="true"
            :model="queryParam"
            @submit.native.prevent
          >
            <el-form-item label="业务类型">
              <el-select
                v-model="queryParam.businessType"
                placeholder="账单状态"
              >
                <el-option
                  v-for="item in boptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="开始与截止时间" label-width="110px">
              <el-date-picker
                v-model="queryParam.time"
                clearable
                end-placeholder="创建截止日期"
                range-separator="至"
                start-placeholder="创建开始日期"
                type="daterange"
              />
            </el-form-item>
            <el-form-item>
              <el-button
                icon="el-icon-search"
                native-type="submit"
                type="primary"
                @click="queryList"
              >
                查询
              </el-button>

              <el-button icon="el-icon-refresh" type="primary" @click="refresh">
                刷新
              </el-button>
            </el-form-item>
          </el-form>
        </vab-query-form-top-panel>
      </vab-query-form>
      <el-table v-loading="loading" border :data="dataSource" stripe>
        <el-table-column align="center" label="序号" sortable width="80">
          <template #default="{ $index }">
            {{ $index + 1 }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="业务类型" prop="amountType">
          <template slot-scope="scope">
            <span>{{ scope.row.amountType == 1 ? '充值' : '扣款' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="业务事项" prop="businessType">
          <template slot-scope="scope">
            <span>{{ btFormat(scope.row.businessType) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="金额（元）" prop="realAmount">
          <template slot-scope="scope">
            <span v-if="scope.row.amountType == 1">
              {{ scope.row.realAmount }}
            </span>
            <span v-else>- {{ scope.row.amount }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="赠送金额（元）"
          prop="givingAmount"
        />
        <el-table-column
          align="center"
          label="余额(元)"
          prop="afterBalance"
          sortable
        />
        <el-table-column
          align="center"
          label="创建时间"
          prop="createTime"
          sortable
          width="200"
        />
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>
      <Pagination
        :limit.sync="queryParam.pageSize"
        :page.sync="queryParam.pageNumber"
        :total-count="totalCount"
        @pagination="loadData"
      />
    </template>
  </Dialog>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialog.vue'
  import { BasePostMixin } from '@/mixins/BasePostMixin'
  export default {
    name: 'Detail',
    components: {
      Pagination,
      Dialog,
    },
    mixins: [BasePostMixin],
    data() {
      return {
        id: '',
        title: '',
        boptions: [
          {
            value: '',
            label: '请选择业务类型',
          },
          {
            value: 1,
            label: '充值',
          },
          {
            value: 2,
            label: '短信发送费用',
          },
          {
            value: 3,
            label: '电子合同费用',
          },
          {
            value: 4,
            label: '支付手续费',
          },
          {
            value: 5,
            label: '平台服务费',
          },
          {
            value: 6,
            label: '支付分账服务费',
          },
          {
            value: 7,
            label: '自动提现服务费',
          },
        ],
        dailog: {
          height: 500,
          title: '钱包明细',
          visible: false,
          width: '940px',
        },
        url: {
          list: '/admin/merchant/wallet/walletRecordPage',
        },
      }
    },
    created() {},
    methods: {
      refresh() {
        if (this.queryParam.time && this.queryParam.time.length > 0) {
          this.queryParam.createTimeStart = this.queryParam.time[0]
          this.queryParam.createTimeEnd = this.queryParam.time[1]
        } else {
          this.queryParam.createTimeStart = ''
          this.queryParam.createTimeEnd = ''
        }
        this.loadData(1)
      },
      queryList() {
        console.log('this.queryParam.time', this.queryParam.time)
        if (this.queryParam.time && this.queryParam.time.length > 0) {
          this.queryParam.createTimeStart = this.queryParam.time[0]
          this.queryParam.createTimeEnd = this.queryParam.time[1]
        } else {
          this.queryParam.createTimeStart = ''
          this.queryParam.createTimeEnd = ''
        }
        this.loadData()
      },
      showDetail(row) {
        this.formData = row
        this.dailog.visible = true
        this.queryParam.shopId = row.shopId
        this.loadData()
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.$refs['form'].resetFields()
      },
      btFormat(type) {
        if (type === 1) {
          return '充值'
        } else if (type === 2) {
          return '短信费'
        } else if (type === 3) {
          return '电子合同服务费'
        } else if (type === 4) {
          return '支付手续费'
        } else if (type === 5) {
          return '平台服务费'
        } else if (type === 6) {
          return '分账服务费'
        } else if (type === 7) {
          return '提现服务费'
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  ::v-deep(.container) {
    padding: 0 20px;
  }
  .card-title {
    margin-bottom: 18px;
    font-size: 16px;
  }
</style>
