<template>
  <div class="index-container">
    <el-form label-width="100px" :model="listQuery" size="small">
      <el-row>
        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 8 }"
        >
          <el-form-item label="配置项名称">
            <el-input
              v-model="listQuery.key"
              clearable
              placeholder="请输入配置项名称"
            />
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 12 }"
          :sm="{ span: 12 }"
          :xl="{ span: 12 }"
          :xs="{ span: 12 }"
        >
          <el-form-item>
            <el-button
              icon="el-icon-search"
              size="mini"
              type="primary"
              @click="getList"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" size="mini" @click="reset">
              重置
            </el-button>
            <el-button
              icon="el-icon-plus"
              size="mini"
              type="primary"
              @click="handleAdd"
            >
              新增
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table v-loading="loading" border :data="tableData" stripe>
      <el-table-column align="center" label="序号" width="50">
        <template slot-scope="scope">
          <span>
            {{
              (listQuery.pageNumber - 1) * listQuery.pageSize + scope.$index + 1
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="参数类型" prop="type" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.type == 0">文本</span>
          <span v-if="scope.row.type == 1">多行文本</span>
          <span v-if="scope.row.type == 2">富文本编辑器</span>
          <span v-if="scope.row.type == 3">图片</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="配置项名称"
        prop="name"
        show-overflow-tooltip
        width="200"
      />
      <el-table-column
        align="center"
        label="配置key"
        prop="key"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="参数值"
        prop="value"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span v-if="scope.row.type == 0 || 1">{{ scope.row.value }}</span>
          <span v-if="scope.row.type == 2">富文本（详情中查看）</span>
          <span v-if="scope.row.type == 3">图片（详情中查看）</span>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="配置描述"
        prop="remark"
        show-overflow-tooltip
        width="200"
      />
      <el-table-column align="center" fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleEdit(scope.row)">
            编辑
          </el-button>
          <el-button
            size="mini"
            type="text"
            @click="handleDelete(scope.row.id)"
          >
            删除
          </el-button>
          <el-button
            size="mini"
            type="text"
            @click="handleCheckDetail(scope.row.id, scope.row.type)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form
          ref="formData"
          label-width="100px"
          :model="formData"
          size="small"
        >
          <el-row>
            <el-form-item label="配置项类型">
              <el-select
                v-model="formData.type"
                placeholder="请配置项类型"
                style="width: 90%"
                @change="selectType"
              >
                <el-option
                  v-for="item in configType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="配置项名称" prop="name">
              <el-input
                v-model="formData.name"
                clearable
                placeholder="请输入配置项名称"
                style="width: 90%"
              />
            </el-form-item>
            <el-form-item label="配置key" prop="key">
              <el-input
                v-model="formData.key"
                clearable
                placeholder="请输入配置key"
                style="width: 90%"
              />
            </el-form-item>
            <el-form-item label="配置描述" prop="remark">
              <el-input
                v-model="formData.remark"
                clearable
                placeholder="请输入配置描述"
                style="width: 90%"
              />
            </el-form-item>
            <el-form-item v-if="formData.type == 0" label="配置值" prop="value">
              <el-input
                v-model="formData.value"
                clearable
                placeholder="请输入配置描述"
                style="width: 90%"
              />
            </el-form-item>
            <el-form-item v-if="formData.type == 1" label="配置值" prop="value">
              <el-input
                v-model="formData.value"
                clearable
                placeholder="请输入配置描述"
                :rows="3"
                style="width: 90%"
                type="textarea"
              />
            </el-form-item>
            <el-form-item v-if="formData.type == 2" label="配置值" prop="value">
              <vab-quill
                ref="vab-quill"
                v-model="formData.value"
                :min-height="300"
                :options="options"
                style="width: 90%"
              />
            </el-form-item>
            <el-form-item v-if="formData.type == 3" label="配置值" prop="value">
              <van-uploader
                v-model="updateFileList"
                accept="image/jpeg"
                :before-delete="deleteInsertPic"
                :before-read="beforeRead"
                :max-count="30"
                multiple
                :preview-full-image="showPic"
              />
            </el-form-item>
          </el-row>
        </el-form>
      </template>
    </Dialog>
    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :showfoot="dailog.showfoot"
      :title="dailog.title1"
      :visible="dailog.visible1"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog1"
    >
      <template #content>
        <el-descriptions
          border
          class="margin-top"
          :column="2"
          :size="size"
          title=""
        >
          <el-descriptions-item>
            <template slot="label">配置项类型</template>
            <span v-if="drawerData.type == 0">文本</span>
            <span v-if="drawerData.type == 1">多行文本</span>
            <span v-if="drawerData.type == 2">富文本编辑器</span>
            <span v-if="drawerData.type == 3">图片</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">配置项名称</template>
            {{ drawerData.name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">配置key</template>
            {{ drawerData.key }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">配置描述</template>
            {{ drawerData.remark }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">参数值</template>
            <div v-if="drawerData_type == 0" class="">
              {{ drawerData.value }}
            </div>
            <div v-if="drawerData_type == 1" class="">
              {{ drawerData.value }}
            </div>
            <div v-if="drawerData_type == 2" class="value">
              <div class="vab" v-html="drawerData.value"></div>
            </div>
            <div v-if="drawerData_type == 3" class="value">
              <img
                v-for="item in drawerData_type_3"
                :key="item.id"
                alt=""
                :src="item"
              />
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </Dialog>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />
  </div>
</template>

<script>
  import {
    configList,
    configSave,
    configUpdate,
    configDelete,
    configDetail,
  } from '@/api/system/config'
  import axios from 'axios'
  import Dialog from '@/components/dialog.vue'
  import VabQuill from '@/extra/VabQuill'
  import { getUploadToken } from '@/api/common/index'
  import Pagination from '@/components/pagination.vue'

  export default {
    name: 'Setting',
    components: {
      Dialog,
      VabQuill,
      Pagination,
    },
    data() {
      return {
        drawer: false,
        drawerData: {},
        size: '',
        direction: 'rtl',
        drawerData_type: null,
        drawerData_type_3: [],
        updateFileList: [],
        showPic: false,
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          key: '',
        },
        totalCount: 0,
        formData: {
          type: '',
          name: '',
          key: '',
          required: '1', // 是否必填 默认=1 是
          value: '',
          remark: '',
        },
        tableData: [],
        loading: false,
        dailog: {
          height: null,
          title: '新增系统配置',
          title1: '系统配置详情',
          visible: false,
          visible1: false,
          width: '700px',
        },
        configType: [
          { value: 0, label: '普通字符' },
          { value: 1, label: '多行文本' },
          { value: 2, label: '富文本编辑器' },
          { value: 3, label: '图片' },
        ],
        options: {
          theme: 'snow',
          bounds: document.body,
          debug: 'warn',
          modules: {
            toolbar: {
              container: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ size: ['small', false, 'large', 'huge'] }],
                [{ color: [] }, { background: [] }],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ script: 'sub' }, { script: 'super' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ align: [] }],
                [{ direction: 'rtl' }],
                [{ font: [] }],
                ['clean'],
                ['link', 'image', 'vab-upload-image'],
              ],
              handlers: {
                'vab-upload-image': () => {
                  this.$baseConfirm(
                    '演示环境未使用真实文件服务器，故图片上传回显不会生效，开发时请修改为正式文件服务器地址',
                    '开发注意事项！！！',
                    () => {
                      this.$refs['vabUpload'].handleShow()
                    },
                    () => {
                      this.handleAddImg()
                    },
                    '模拟打开文件上传',
                    '模拟添加一张文件服务器图片'
                  )
                },
              },
            },
          },
          placeholder: '内容...',
          readOnly: false,
        },
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      reset() {
        for (let key in this.listQuery) {
          this.listQuery[key] = ''
        }
        this.listQuery.pageNumber = 1
        this.listQuery.pageSize = 20
        this.getList()
      },
      beforeRead(file) {
        if (file.type === 'image/png') {
          this.$notify.warning('请上传 jpg 格式图片')
          return false
        }
        return true
      },
      deleteInsertPic(file, detail) {
        this.updateFileList.splice(detail.index, 1)
      },
      // 列表
      getList() {
        this.loading = true
        configList(this.listQuery).then((res) => {
          console.log(res)
          if (res.code === 0) {
            const { records, total } = res.data
            this.tableData = records
            this.totalCount = +total
            this.tableData.forEach((item) => {
              if (item.type == 2) {
                item.value = '富文本内容'
              }
            })
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      // 编辑
      handleEdit(row) {
        this.dailog.visible = true
        this.dailog.title = '编辑系统配置'
        this.$nextTick(() => {
          // this.formData = JSON.parse(JSON.stringify(row))
          configDetail({ id: row.id }).then((res) => {
            console.log(res)
            this.formData = res.data
            console.log(this.formData)
            const imgList = this.formData.value.split(',')
            console.log(imgList)
            // imgList.forEach((item) => {
            //   this.updateFileList.push({
            //     url: item,
            //   })
            // })
          })
        })
      },
      handleCheckDetail(id, type) {
        console.log(id, type)
        this.dailog.visible1 = true
        configDetail({ id }).then((res) => {
          console.log(res)
          this.drawerData = res.data
          this.drawerData_type = type
          if (type == 3) {
            const imgList = this.drawerData.value.split(',')
            this.drawerData_type_3 = imgList
          }
        })
      },
      // 删除
      handleDelete(id) {
        this.$confirm('是否删除该配置?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          configDelete({ id }).then((res) => {
            this.commonMessage(res)
            console.log(res)
          })
        })
      },
      selectType(val) {
        console.log(val)
        if (val == 2) {
          this.dailog.height = 400
        } else {
          this.dailog.height = 300
        }
      },
      handleAdd() {
        this.dailog.visible = true
        this.dailog.title = '新增系统配置'
      },
      async handleConfirm() {
        if (this.formData.type === 3) {
          console.log(this.updateFileList)
          const imgList = []
          for (let i = 0; i < this.updateFileList.length; i++) {
            if (this.updateFileList[i].file) {
              const fileName = this.updateFileList[i].file.name
              let ext = fileName.substring(
                fileName.lastIndexOf('.') + 1,
                fileName.length
              )
              // console.log(ext) // jpg
              let res = await getUploadToken({
                extension: ext,
              })
              console.log(res)
              const { token, key } = res.data
              const file = this.updateFileList[i].file
              const url = 'http://upload-z2.qiniup.com/'
              let formData = new FormData()
              formData.append('file', file)
              formData.append('data', file)
              formData.append('key', key)
              formData.append('token', token)
              let config = {
                headers: { 'Content-Type': 'multipart/form-data' },
              }
              axios.post(url, formData, config).then((res) => {
                console.log(res)
              })
              imgList.push(res.data.url)
              this.formData.value = imgList.join(',')
            }
            // console.log(imgList)
          }
        }
        console.log(this.formData)
        if (this.dailog.title === '新增系统配置') {
          configSave(this.formData).then((res) => {
            this.commonMessage(res)
            this.dailog.visible = false
          })
        } else {
          configUpdate(this.formData).then((res) => {
            this.commonMessage(res)
            this.dailog.visible = false
          })
        }
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.$refs['formData'].resetFields()
      },
      handleCloseDialog1() {
        this.dailog.visible1 = false
      },
      commonMessage(res) {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.msg,
          })
        }
      },
      handleAddImg() {
        this.form.content +=
          '<img src="https://cdn.jsdelivr.net/gh/chuzhixin/image/ewm.png" />'
      },
      handleClose() {
        this.drawer = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .index-container {
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .left {
        display: flex;
      }
    }
    .addButton {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
    }
    .value {
      width: 200px;
      height: 100%;
      // display: flex;
      .vab {
        width: 100%;
        height: 100%;
      }
      img {
        margin-right: 10px;
        width: 100%;
        height: 100%;
      }
    }
    :deep() {
      .ql-editor {
        img {
          width: 60%;
          height: 60%;
        }
      }
      .el-descriptions__body {
        img {
          width: 100%;
          height: 200px;
        }
      }
    }
  }
</style>
