<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="120px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="商户名称">
            <el-input
              v-model="listQuery.shopName"
              clearable
              placeholder="商户名称"
            />
          </el-form-item>
          <el-form-item label="银行卡号">
            <el-input
              v-model="listQuery.bankCardNo"
              clearable
              placeholder="请输入银行卡号"
            />
          </el-form-item>
          <el-form-item label="结算平台">
            <el-select
              v-model="listQuery.payChannel"
              clearable
              placeholder="请选择结算平台"
            >
              <el-option label="易票联" value="EPL" />
              <el-option label="中信银行" value="CITIC_BANK" />
            </el-select>
          </el-form-item>
          <el-form-item label="账户状态">
            <el-select
              v-model="listQuery.accountStatus"
              clearable
              placeholder="请选择账户状态"
            >
              <el-option label="未开通" :value="0" />
              <el-option label="已开通" :value="1" />
              <el-option label="冻结" :value="2" />
              <el-option label="注销" :value="3" />
              <el-option label="止付" :value="4" />
            </el-select>
          </el-form-item>
          <el-form-item label="默认账户">
            <el-select
              v-model="listQuery.isDefault"
              clearable
              placeholder="请选择默认账户"
            >
              <el-option label="是" :value="1" />
              <el-option label="否" :value="0" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="getList"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetQuery">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table v-loading="loading" border :data="tableData" stripe>
      <el-table-column
        align="center"
        label="商户名称"
        prop="shopName"
        width="160"
      />
      <el-table-column
        align="center"
        label="结算平台"
        prop="payChannel"
        width="200"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.payChannel === 'CITIC_BANK'">中信银行</span>
          <span v-if="scope.row.payChannel === 'EPL'">易票联</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="银行卡号"
        prop="bankCardNo"
        width="200"
      />
      <el-table-column
        align="center"
        label="商户号"
        prop="shopId"
        width="200"
      />
      <el-table-column
        align="center"
        label="默认账户"
        prop="isDefault"
        width="80"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.isDefault == 1">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="账户状态"
        prop="accountStatus"
        width="120"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.accountStatus == 0">未开通</span>
          <span v-if="scope.row.accountStatus == 1">已开通</span>
          <span v-if="scope.row.accountStatus == 2">冻结</span>
          <span v-if="scope.row.accountStatus == 3">注销</span>
          <span v-if="scope.row.accountStatus == 4">止付</span>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="开户银行"
        prop="openBank"
        width="120"
      />
      <el-table-column
        align="center"
        label="开户支行"
        prop="openSubBank"
        width="250"
      />

      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        width="200"
      />
      <el-table-column
        align="center"
        label="更新时间"
        prop="updateTime"
        width="200"
      />
      <el-table-column align="center" fixed="right" label="操作" width="180">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleDetail(scope.row)">
            详情
          </el-button>
          <el-button
            v-if="
              scope.row.payChannel === 'EPL' &&
              (scope.row.auditStatus == 1 ||
                scope.row.auditStatus == 3 ||
                scope.row.auditStatus == 0)
            "
            size="mini"
            type="text"
            @click="merchantApply(scope.row)"
          >
            提交易票联开户
          </el-button>
          <el-button
            v-if="scope.row.payChannel === 'EPL' && scope.row.auditStatus == 1"
            size="mini"
            type="text"
            @click="turnDown(scope.row)"
          >
            驳回
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>

    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      title="结算账户详情"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
    >
      <template #content>
        <el-row style="margin: 15px 0">
          <el-col :span="12">更新时间：{{ formData.updateTime }}</el-col>
          <el-col :span="12">创建时间：{{ formData.createTime }}</el-col>
        </el-row>
        <el-descriptions border class="margin-top" :column="2" size="medium">
          <el-descriptions-item>
            <template slot="label">商户ID</template>
            {{ formData.shopId }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">商户名称</template>
            {{ formData.shopName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">结算平台</template>
            {{ formData.payChannel == 'CITIC_BANK' ? '中信银行' : '易票联' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">银行卡号</template>
            {{ formData.bankCardNo }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">开户银行</template>
            {{ formData.openBank }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">开户支行</template>
            {{ formData.openSubBank }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">开户行联行号</template>
            {{ formData.openBankCode }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">提现方式</template>
            {{ formData.settleTarget == 2 ? '手动提现' : '自动提现' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">默认账户</template>
            {{ formData.isDefault == 1 ? '是' : '否' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">账户状态</template>
            <span v-if="formData.accountStatus == 0">未开通</span>
            <span v-if="formData.accountStatus == 1">已开通</span>
            <span v-if="formData.accountStatus == 2">冻结</span>
            <span v-if="formData.accountStatus == 3">注销</span>
            <span v-if="formData.accountStatus == 4">止付</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">银行卡附件</template>
            <el-image
              v-if="formData.settleAttachment"
              :preview-src-list="[formData.settleAttachment]"
              :src="formData.settleAttachment"
              style="width: 50px; height: 50px"
            />
            <el-image
              v-else
              :preview-src-list="[defaultImg]"
              :src="defaultImg"
              style="width: 50px; height: 50px"
            />
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">审核意见</template>
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </Dialog>
    <shopbankReasonVue
      v-if="turnDownVisible"
      :turn-down-visible.sync="turnDownVisible"
      @trunDownConfirm="trunDownConfirm"
    />
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />
    <!-- </el-card> -->
  </div>
</template>

<script>
  import { shopBank } from '@/api/merchant/newIndex'
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialogOther.vue'
  import shopbankReasonVue from './components/shopbankReason.vue'
  import { updateStatusAPI, merchantApply } from '@/api/merchant/shopList'
  export default {
    name: 'Shopbank',
    components: {
      Pagination,
      Dialog,
      shopbankReasonVue,
    },
    data() {
      return {
        turnDownVisible: false,
        disabled: true,
        loading: false,
        dailog: {
          height: 360,
          title: '',
          visible: false,
          width: '700px',
        },
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          shopName: '',
          bankCardNo: '',
          payChannel: '',
          accountStatus: '',
          isDefault: '',
        },
        defaultImg: require('@/assets/default.jpg'),
        totalCount: 0,
        formData: {
          parentName: '',
          name: '',
          pid: '',
          path: '',
          type: null, // 1菜单 2按钮
          icon: '',
          time: '',
        },
        rowData: {},
        tableData: [],
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      turnDown(row) {
        this.turnDownVisible = true
        this.rowData = row
      },
      trunDownConfirm(val) {
        updateStatusAPI({
          reason: val,
          id: this.rowData.shopId,
          status: 3,
        }).then((res) => {
          if (res.code === 0) {
            this.turnDownVisible = false
            this.getList()
            return this.$message.success(res.msg)
          }
        })
      },
      // 修改状态
      merchantApply(row) {
        this.$confirm('确定要提交开户吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.loading = true
            merchantApply({ id: row.shopId })
              .then((res) => {
                if (res.code === 0) {
                  this.$message.success(res.msg)
                }
                this.getList()
              })
              .finally(() => {
                this.loading = false
              })
          })
          .catch(() => {})
      },
      getList() {
        this.loading = true
        shopBank(this.listQuery).then((res) => {
          // console.log(res)
          if (res.code === 0) {
            this.tableData = res.data.records
            this.totalCount = +res.data.total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      // 编辑
      handleDetail(row) {
        this.dailog.visible = true
        this.dailog.title = '商户结算账号信息'
        console.log(row)
        this.$nextTick(() => {
          this.formData = JSON.parse(JSON.stringify(row))
        })
      },
      handleCloseDialog() {
        //this.$refs['formData'].resetFields()
        this.dailog.visible = false
      },
      handleConfirm() {},
      //重置
      resetQuery() {
        this.listQuery = {
          pageNumber: 1,
          pageSize: 20,
          shopName: '',
          bankCardNo: '',
          payChannel: '',
          accountStatus: '',
          isDefault: '',
        }
        this.getList()
      },
    },
  }
</script>

<style lang="scss" scoped></style>
