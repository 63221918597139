<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="110px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="地区">
            <el-cascader
              v-model="areaCode"
              clearable
              :options="areaList"
              placeholder="请选择地区"
              :props="areaProps"
              style="width: 100%"
              @change="handleChange"
            />
          </el-form-item>
          <el-form-item label="用户角色">
            <el-select v-model="listQuery.role" clearable>
              <el-option
                v-for="item in roleList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="用户姓名">
            <el-input
              v-model="listQuery.name"
              clearable
              placeholder="请输入用户姓名"
            />
          </el-form-item>
          <el-form-item label="手机号">
            <el-input
              v-model="listQuery.phone"
              clearable
              placeholder="请输入手机号"
            />
          </el-form-item>
          <el-form-item label="身份证号">
            <el-input
              v-model="listQuery.idCard"
              clearable
              placeholder="请输入身份证号"
            />
          </el-form-item>
          <el-form-item label="微信绑定状态">
            <el-select v-model="listQuery.status" clearable>
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="getList">
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="reset">
              重置
            </el-button>
            <el-button
              icon="el-icon-refresh"
              :loading="syncWxUserLoading"
              type="primary"
              @click="syncWxMpUser"
            >
              同步微信信息
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table v-loading="loading" border :data="tableData" stripe>
      <el-table-column align="center" label="序号" width="50">
        <template slot-scope="scope">
          {{
            (listQuery.pageNumber - 1) * listQuery.pageSize + scope.$index + 1
          }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="姓名" prop="name" width="120" />
      <el-table-column align="center" label="手机" prop="phone" width="120" />
      <el-table-column
        align="center"
        label="身份证号"
        prop="idcard"
        width="200"
      />
      <!-- <el-table-column align="center" label="微信" prop="weixin" width="120" /> -->
      <el-table-column
        align="center"
        label="昵称"
        prop="nickname"
        width="120"
      />
      <!-- <el-table-column align="center" label="头像" width="150">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.avatar"
            :preview-src-list="[scope.row.avatar]"
            :src="scope.row.avatar"
            style="width: 50px; height: 50px"
          />
          <el-image
            v-else
            :preview-src-list="[defaultImg]"
            :src="defaultImg"
            style="width: 50px; height: 50px"
          />
        </template>
      </el-table-column> -->
      <!-- <el-table-column align="center" label="国家" prop="country" width="120" /> -->
      <el-table-column
        align="center"
        label="省/市/区"
        show-overflow-tooltip
        width="200"
      >
        <template slot-scope="scope">
          {{ scope.row.province || '' }} / {{ scope.row.city || '' }} /
          {{ scope.row.district || '' }}
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" label="性别">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.sex">
            {{ format(scope.row.sex, sexType) }}
          </el-tag>
          <el-tag v-else type="warning">
            {{ format(scope.row.sex, sexType) }}
          </el-tag>
        </template>
      </el-table-column> -->
      <!-- <el-table-column
        align="center"
        label="出生日期"
        prop="birthDate"
        width="120"
      /> -->
      <!-- <el-table-column
        align="center"
        label="职业"
        prop="profession"
        width="120"
      /> -->
      <el-table-column align="center" label="微信绑定状态" width="120">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 0">未绑定</el-tag>
          <el-tag v-if="scope.row.status === 1" type="success">已绑定</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="用户角色">
        <template slot-scope="scope">
          <el-tag v-for="item in roleNamesCut(scope.row.roles)" :key="item">
            {{ item }}
            <br />
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="e签宝商户状态"
        prop="citicStatus"
        width="150"
      >
        <template slot-scope="scope">
          {{ scope.row.esignStatusDTO.registerStatus == 1 ? '已' : '未' }}注册、
          {{
            scope.row.esignStatusDTO.authenticateStatus == 1 ? '已' : '未'
          }}认证、
          {{
            scope.row.esignStatusDTO.authorizeStatus == 1
              ? `授权(剩余${scope.row.esignStatusDTO.remainingTermOfAuthorization})`
              : '未授权'
          }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        width="200"
      />
      <el-table-column align="center" fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="handleDetail(scope.row.id)"
          >
            详情
          </el-button>
          <el-button size="mini" type="text" @click="handleEdit(scope.row.id)">
            设置用户组
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />
    <!-- 用户详情 -->
    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :showfoot="showfoot"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <!-- <template #content>
        <el-form
          ref="formData"
          label-width="100px"
          :model="formData"
          size="small"
        >
          <el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="姓名">
                  <el-input
                    v-model="formData.name"
                    clearable
                    :disabled="disabled"
                    style="width: 300px"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="性别">
                  <el-select
                    v-model="formData.sex"
                    clearable
                    :disabled="disabled"
                    style="width: 300px"
                  >
                    <el-option
                      v-for="item in sexType"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="身份证号">
                  <el-input
                    v-model="formData.idcard"
                    clearable
                    :disabled="disabled"
                    style="width: 300px"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="手机">
                  <el-input
                    v-model="formData.phone"
                    clearable
                    :disabled="disabled"
                    style="width: 300px"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="出生日期">
                  <el-input
                    v-model="formData.birthDate"
                    clearable
                    :disabled="disabled"
                    style="width: 300px"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="职业">
                  <el-input
                    v-model="formData.profession"
                    clearable
                    :disabled="disabled"
                    style="width: 300px"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="微信绑定状态">
                  {{ formData.status === 0 ? '未绑定 ' : '已绑定' }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="用户类型">
                  {{ formData.type === 1 ? '普通用户' : '租户' }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="国家">
                  <el-input
                    v-model="formData.country"
                    clearable
                    :disabled="disabled"
                    style="width: 300px"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="省份">
                  <el-input
                    v-model="formData.province"
                    clearable
                    :disabled="disabled"
                    style="width: 300px"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="地市">
                  <el-input
                    v-model="formData.city"
                    clearable
                    :disabled="disabled"
                    style="width: 300px"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="区县">
                  <el-input
                    v-model="formData.district"
                    clearable
                    :disabled="disabled"
                    style="width: 300px"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="微信号">
                  <el-input
                    v-model="formData.weixin"
                    clearable
                    :disabled="disabled"
                    style="width: 300px"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="微信头像">
                  <el-avatar size="200" :src="formData.avatar" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="微信昵称">
                <el-input
                  v-model="formData.nickname"
                  clearable
                  :disabled="disabled"
                  style="width: 300px"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-form-item label="角色">
              <template v-if="detail">
                <el-button
                  :disabled="disabled"
                  plain
                  size="mini"
                  type="primary"
                  @click="handleCheckAllChange(1)"
                >
                  全选
                </el-button>
              </template>
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(role, index) in formData.roles"
                  :key="index"
                  :disabled="disabled"
                  :label="role.code"
                >
                  {{ role.roleName }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-row>
        </el-form>
      </template> -->

      <template #content>
        <el-descriptions border class="margin-top" :column="2" size="medium">
          <el-descriptions-item>
            <template slot="label">姓名</template>
            {{ formData.name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">性别</template>
            {{ formData.sex | sexFormat }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">身份证号</template>
            {{ formData.idcard }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">手机</template>
            {{ formData.phone }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">出生日期</template>
            {{ formData.birthDate }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">职业</template>
            {{ formData.profession }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">微信绑定状态</template>
            {{ formData.status === 0 ? '未绑定 ' : '已绑定' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">用户类型</template>
            {{ formData.type === 1 ? '普通用户' : '租户' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">国家</template>
            {{ formData.country }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">省份</template>
            {{ formData.province }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">地市</template>
            {{ formData.city }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">区县</template>
            {{ formData.district }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">微信号</template>
            {{ formData.weixin }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">微信头像</template>
            <el-avatar :src="formData.avatar" />
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">微信昵称</template>
            {{ formData.weixin }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">角色</template>
            <span v-for="(role, index) in formData.rolesList" :key="index">
              {{ role.checked ? role.roleName : '无' }}
            </span>
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </Dialog>

    <!-- 设置用户组 -->
    <Dialog
      :height="setGroupDailog.height"
      :model-value="setGroupDailog.visibleSetGroup"
      :title="setGroupDailog.title"
      :visible="setGroupDailog.visibleSetGroup"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form
          ref="formData"
          label-width="100px"
          :model="formData"
          size="small"
        >
          <el-row>
            <el-form-item label="用户名" prop="avatar">
              {{ formData.name }}
            </el-form-item>
            <el-form-item label="手机号码" prop="phone">
              {{ formData.phone }}
            </el-form-item>
            <el-form-item label="角色">
              <el-button
                plain
                size="mini"
                type="primary"
                @click="handleCheckAllChange(val)"
              >
                全选
              </el-button>
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(role, index) in formData.rolesList"
                  :key="index"
                  :label="role.code"
                  style="display: block"
                >
                  {{ role.roleName }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-row>
        </el-form>
      </template>
    </Dialog>
  </div>
</template>

<script>
  import {
    detail,
    roleList,
    updateMember,
    syncWxUser,
  } from '@/api/member/member'
  import { memberList } from '@/api/member/newMember'
  import { regionList } from '@/api/system/user'
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialog.vue'

  export default {
    name: 'Member',
    components: {
      Pagination,
      Dialog,
    },
    filters: {
      sexFormat(sex) {
        let obj = {
          1: '男',
          2: '女',
        }
        return obj[sex] ?? '未知'
      },
    },
    data() {
      return {
        size: '',
        val: 1,
        showfoot: false,
        syncWxUserLoading: false,
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          provinceCode: '',
          cityCode: '',
          districtCode: '',
          name: '',
          phone: '',
          role: '',
          idCard: '',
          status: '',
        },
        checkList: [],
        roleList: [],
        disabled: true,
        detail: false,
        dailog: {
          height: null,
          title: '用户',
          visible: false,
          width: '700px',
        },
        setGroupDailog: {
          height: 350,
          title: '设置用户组',
          visible: false,
          width: '500px',
          visibleSetGroup: false,
        },
        formData: {},
        totalCount: 0,
        tableData: [],
        sexType: [
          {
            label: '男',
            value: 1,
          },
          {
            label: '女',
            value: 2,
          },
        ],
        statusList: [
          {
            label: '未绑定',
            value: 0,
          },
          {
            label: '已绑定',
            value: 1,
          },
        ],
        areaCode: '',
        areaList: [],
        areaProps: {
          value: 'code',
          label: 'name',
          children: 'children',
        },
        loading: false,
        defaultImg: require('@/assets/default.jpg'),
        // 格式化
        format(val, list) {
          const res = list.filter((item) => item.value === val)
          if (res.length > 0) {
            return res[0].label
          } else {
            return '未知'
          }
        },
      }
    },
    created() {
      this.getList()
      this.getRegiList()
      this.getRoleList()
    },
    methods: {
      //查询列表信息
      getList() {
        this.loading = true
        memberList(this.listQuery).then((res) => {
          this.tableData = res.data.list
          this.totalCount = +res.data.totalRow
        })
        this.loading = false
      },
      //查询平台用户角色
      async getRoleList() {
        roleList().then((res) => {
          console.log(res)
          if (res.code === 0) {
            this.roleList = res.data.filter((item) => {
              return item.value !== ''
            })
          }
        })
      },
      // 详情
      handleDetail(id) {
        this.dailog.visible = true
        this.dailog.title = '用户详情'
        this.detail = false
        this.$nextTick(() => {
          detail(id).then((res) => {
            this.formData = res.data
            console.log(this.formData.rolesList, '==>')
            this.checkList = this.formData.rolesList
              .filter((item) => item.checked === true)
              .map((item) => item.code)
          })
        })
      },
      // 编辑
      handleEdit(id) {
        this.dailog.title = '设置用户组'
        this.setGroupDailog.visibleSetGroup = true
        this.$nextTick(() => {
          detail(id).then((res) => {
            this.formData = res.data
            console.log(this.formData.rolesList, '==>')
            this.checkList = this.formData.rolesList
              .filter((item) => item.checked === true)
              .map((item) => item.code)
          })
        })
      },
      // 确定新增/修改
      handleConfirm() {
        console.log(this.dailog.title)
        if (this.dailog.title === '用户详情') {
          this.handleCloseDialog()
        } else {
          let param = {
            id: this.formData.id,
            codes: this.checkList.join(','),
          }
          console.log(param, '用户设置角色==》')
          updateMember(param).then((res) => {
            this.commonMessage(res)
            this.handleCloseDialog()
          })
        }
      },
      handleCloseDialog() {
        this.detail = false
        this.setGroupDailog.visibleSetGroup = false
        this.dailog.visible = false
      },
      commonMessage(res) {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.msg,
          })
        }
      },
      reset() {
        this.listQuery = {
          pageNumber: 1,
          pageSize: 20,
          name: '',
          phone: '',
          type: '',
          idCard: '',
        }
        this.getList()
      },
      syncWxMpUser() {
        this.syncWxUserLoading = true
        syncWxUser()
          .then((res) => {
            if (res.code === 0) {
              this.$message({
                type: 'success',
                message: '同步成功',
              })
              this.getList()
            } else {
              this.$message({
                type: 'error',
                message: '同步失败',
              })
            }
          })
          .finally(() => {
            this.syncWxUserLoading = false
          })
      },
      handleCheckAllChange(val) {
        console.log('val', val)
        if (val === 0) {
          this.checkList = []
        } else {
          this.checkList = this.formData.roles.map((item) => item.code)
        }
        this.val = val === 1 ? 0 : 1
      },
      getRegiList() {
        regionList().then((res) => {
          console.log(res)
          if (res.code === 0) {
            this.areaList = res.data
          }
        })
      },
      handleChange(val) {
        console.log(val)
        this.listQuery.provinceCode = val[0]
        this.listQuery.cityCode = val[1]
        this.listQuery.districtCode = val[2]
      },
      // 切割公司列表
      roleNamesCut(name) {
        return name.split(',')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-range-editor.el-input__inner {
    width: 105%;
  }
  ::v-deep() .el-descriptions--small.is-bordered .el-descriptions-item__cell {
    font-size: 15px !important;
  }
</style>
