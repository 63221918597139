var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "personal-center-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { lg: 24, md: 24, sm: 24, xl: 24, xs: 24 } },
            [
              _c(
                "el-card",
                [
                  _c("el-page-header", {
                    attrs: { content: "商户配置" },
                    on: { back: _vm.goBack },
                  }),
                  _c(
                    "el-tabs",
                    {
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    _vm._l(_vm.tabList, function (item, index) {
                      return _c(
                        "el-tab-pane",
                        {
                          key: index,
                          attrs: { label: item.label, name: item.name },
                        },
                        [
                          _vm.activeName == item.name
                            ? _c(item.name, {
                                tag: "component",
                                attrs: { id: _vm.id, name: _vm.name },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }