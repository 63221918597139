<template>
  <div class="index-container">
    <el-form label-width="100px" :model="listQuery" size="small">
      <el-row>
        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 8 }"
        >
          <el-form-item label="用户姓名">
            <el-input
              v-model="listQuery.name"
              clearable
              placeholder="请输入用户名称"
            />
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 8 }"
        >
          <el-form-item label="角色名称">
            <el-select
              v-model="listQuery.roleId"
              clearable
              placeholder="请选择角色"
              style="width: 200px"
            >
              <el-option
                v-for="item in roleList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 8 }"
        >
          <el-form-item>
            <el-button
              icon="el-icon-search"
              size="mini"
              type="primary"
              @click="getList"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" size="mini" @click="reset">
              重置
            </el-button>
            <el-button
              icon="el-icon-plus"
              size="mini"
              type="primary"
              @click="handleAdd"
            >
              新增
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table v-loading="loading" border :data="tableData" stripe>
      <el-table-column align="center" label="序号" width="50">
        <template slot-scope="scope">
          <span>
            {{
              (listQuery.pageNumber - 1) * listQuery.pageSize + scope.$index + 1
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="姓名" prop="nickname" />
      <el-table-column align="center" label="用户名" prop="username" />
      <el-table-column align="center" label="角色" prop="roleNames" />
      <el-table-column align="center" label="是否启用">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 1" type="success">是</el-tag>
          <el-tag v-else type="danger">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleEdit(scope.row)">
            编辑
          </el-button>
          <el-button
            size="mini"
            type="text"
            @click="handleDelete(scope.row.id)"
          >
            删除
          </el-button>
          <el-button
            size="mini"
            type="text"
            @click="handleUpdatePassword(scope.row.id)"
          >
            重置密码
          </el-button>
          <el-button
            size="mini"
            type="text"
            @click="handleAssignRole(scope.row)"
          >
            分配角色
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form
          ref="formData"
          label-width="100px"
          :model="formData"
          :rules="rules"
          size="small"
        >
          <el-row>
            <el-form-item label="姓名" prop="nickname">
              <el-input
                v-model="formData.nickname"
                clearable
                placeholder="请输入用户名(例:张三)"
                style="max-width: 200px"
              />
            </el-form-item>
            <el-form-item label="用户名" prop="username">
              <el-input
                v-model="formData.username"
                clearable
                placeholder="请输入用户名(例:admin)"
                style="max-width: 200px"
              />
            </el-form-item>
            <el-form-item
              v-if="dailog.title === '新增用户'"
              label="密码"
              prop="password"
            >
              <el-input
                v-model="formData.password"
                clearable
                placeholder="请输入密码"
                style="max-width: 200px"
              />
            </el-form-item>
            <el-form-item label="状态" prop="status">
              <el-select
                v-model="formData.status"
                placeholder="请选择状态"
                style="width: 200px"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-row>
        </el-form>
      </template>
    </Dialog>
    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :visible="dailog.visibleRole"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form label-width="100px" :model="formDataRole" size="small">
          <el-row>
            <el-form-item label="角色">
              <el-select
                v-model="ids"
                multiple
                placeholder="请选择角色"
                style="width: 200px"
              >
                <el-option
                  v-for="item in roleList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-row>
        </el-form>
      </template>
    </Dialog>
    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :visible="dailog.visibleRolePassWord"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form
          ref="formDataPass"
          label-width="100px"
          :model="formDataPass"
          :rules="rules1"
          size="small"
        >
          <el-row>
            <el-form-item label="新密码" prop="newPassword">
              <el-input
                v-model="formDataPass.newPassword"
                clearable
                placeholder="请输入新密码"
                style="max-width: 200px"
              />
            </el-form-item>
          </el-row>
        </el-form>
      </template>
    </Dialog>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />
  </div>
</template>

<script>
  import {
    getUserAPI,
    addUserAPI,
    updateUserAPI,
    deleteUserAPI,
    updateRolesAPI,
    putPwdAPI,
  } from '@/api/system/newUser'
  import { getRoleAPI } from '@/api/system/newRole'
  import Dialog from '@/components/dialog.vue'
  import Pagination from '@/components/pagination.vue'
  export default {
    name: 'User',
    components: {
      Dialog,
      Pagination,
    },
    data() {
      var validatePass = (rule, value, callback) => {
        console.log(value.length)
        if (value === '') {
          callback(new Error('请输入密码'))
        } else if (value.length < 6) {
          callback(new Error('密码最少6位!'))
        } else {
          callback()
        }
      }
      return {
        totalCount: 0,
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          name: '',
          roleId: '',
        },
        sexList: [
          { value: 0, label: '男' },
          { value: 1, label: '女' },
        ],
        dailog: {
          height: null,
          title: '新增用户',
          visible: false,
          width: '400px',
          visibleRole: false,
          visibleRolePassWord: false,
        },
        rules: {
          username: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
          ],
          nickname: [
            { required: true, message: '请输入姓名', trigger: 'blur' },
          ],
          password: [
            {
              required: true,
              validator: validatePass,
              trigger: 'blur',
            },
          ],
          status: [
            { required: true, message: '请选择角色状态', trigger: 'change' },
          ],
        },
        rules1: {
          newPassword: [
            { required: true, validator: validatePass, trigger: 'blur' },
          ],
        },
        tableData: [],
        loading: false,
        formData: {
          username: '',
          nickname: '',
          status: '',
          password: '',
        },
        options: [
          { value: 1, label: '启用' },
          { value: 0, label: '禁用' },
        ],
        formDataRole: {
          id: '', // 用户id
          roleIds: '', // 角色id集合
        },
        formDataPass: {
          userId: '',
          newPassword: '',
        },
        roleList: [],
        ids: [], // 所选角色id集合
      }
    },
    mounted() {
      this.getList()
      this.getRoleList()
    },
    methods: {
      reset() {
        for (let key in this.listQuery) {
          this.listQuery[key] = ''
        }
        this.listQuery.pageNumber = 1
        this.listQuery.pageSize = 20
        this.getList()
      },
      // 列表
      getList() {
        this.loading = true
        getUserAPI(this.listQuery).then((res) => {
          // console.log(res)
          if (res.code === 0) {
            const { records, total } = res.data
            this.tableData = records
            this.totalCount = +total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      getRoleList() {
        getRoleAPI({ pageNumber: 1, pageSize: 999 }).then((res) => {
          console.log(res)
          if (res.code === 0) {
            const { records } = res.data
            this.roleList = records
            const arr = []
            this.roleList.forEach((item) => {
              arr.push({
                value: item.id,
                label: item.name,
              })
            })
            this.roleList = arr
            console.log(this.roleList)
          }
        })
      },
      // 新增
      handleAdd() {
        this.dailog.visible = true
        this.dailog.title = '新增用户'
      },
      // 删除
      handleDelete(id) {
        if (id === '1') {
          this.$message({
            type: 'warning',
            message: '管理员不可删除!',
          })
          return
        }
        this.$confirm('是否删除该用户?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          deleteUserAPI(id).then((res) => {
            this.commonMessage(res)
            console.log(res)
          })
        })
      },
      // 编辑
      handleEdit(row) {
        this.dailog.visible = true
        this.dailog.title = '编辑用户'
        this.$nextTick(() => {
          this.formData = JSON.parse(JSON.stringify(row))
        })
      },
      // 分配角色
      handleAssignRole(row) {
        console.log(row)
        this.dailog.visibleRole = true
        this.dailog.title = '分配角色'
        // this.dailog.height = 70
        this.formDataRole.id = row.id
        // 根据roleId查询角色
        this.ids = row.roleIds ? row.roleIds.split(';') : []
      },
      // 重置密码
      handleUpdatePassword(id) {
        this.formDataPass.userId = id
        this.dailog.visibleRolePassWord = true
        this.dailog.title = '重置密码'
        // alert('开发中===')
      },
      // 确定新增/修改
      handleConfirm() {
        if (this.dailog.title === '分配角色') {
          if (!this.ids.length) {
            this.$message({
              type: 'warning',
              message: '请至少选择一个角色提交!',
            })
          }
          let roleNames = this.roleList.filter((ele) => {
            return this.ids.includes(ele.value)
          })
          updateRolesAPI(
            {
              roleIdList: this.ids,
              roleIds: this.ids.join(';'),
              roleNames: roleNames
                .map((ele) => {
                  return ele.label
                })
                .join(';'),
            },
            this.formDataRole.id
          ).then((res) => {
            console.log(res)
            this.commonMessage(res)
            this.dailog.visibleRole = false
          })
          // alert('接口对接中===')
        } else if (this.dailog.title === '重置密码') {
          this.$refs['formDataPass'].validate((valid) => {
            console.log(valid)
            if (valid) {
              putPwdAPI(
                this.formDataPass.userId,
                this.formDataPass.newPassword
              ).then((res) => {
                this.commonMessage(res)
                this.handleCloseDialog()
              })
            }
          })
        } else {
          this.$refs['formData'].validate((valid) => {
            console.log(valid)
            if (valid) {
              if (this.dailog.title === '新增用户') {
                addUserAPI({
                  nickname: this.formData.nickname,
                  username: this.formData.username,
                  password: this.formData.password,
                  status: this.formData.status,
                }).then((res) => {
                  this.commonMessage(res)
                  this.handleCloseDialog()
                })
              } else {
                updateUserAPI(this.formData).then((res) => {
                  this.commonMessage(res)
                  this.handleCloseDialog()
                })
              }
            }
          })
        }
      },
      handleCloseDialog() {
        if (
          this.dailog.title === '新增用户' ||
          this.dailog.title === '编辑用户'
        ) {
          this.dailog.visible = false
          this.$refs['formData'].resetFields()
        } else if (this.dailog.title === '重置密码') {
          this.dailog.visibleRolePassWord = false
          this.$refs['formDataPass'].resetFields()
        } else {
          this.dailog.visibleRole = false
        }
      },
      commonMessage(res) {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.msg,
          })
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .index-container {
  }
</style>
