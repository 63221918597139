<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="90px"
          :model="queryParam"
          @submit.native.prevent
        >
          <el-form-item label="登录账户">
            <el-input
              v-model="queryParam.adminPhone"
              clearable
              placeholder="登录账户"
            />
          </el-form-item>
          <el-form-item label="商户名称">
            <el-input
              v-model="queryParam.name"
              clearable
              placeholder="商户名称"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="loadData"
            >
              查询
            </el-button>

            <el-button icon="el-icon-refresh-left" @click="searchReset">
              重置
            </el-button>

            <!-- <el-button icon="el-icon-refresh" type="primary" @click="refresh">
              刷新
            </el-button> -->
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :data="dataSource"
      stripe
      @select="selectRow"
      @select-all="selectRow"
    >
      <!-- <el-table-column align="center" type="selection" width="50" /> -->
      <el-table-column align="center" label="序号" width="50">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="登录账户" prop="adminPhone" />
      <el-table-column align="center" label="商户名称 " prop="name" />
      <el-table-column align="center" label="钱包余额（元）" prop="balance" />
      <el-table-column align="center" label="钱包充值">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-bank-card"
            size="mini"
            type="text"
            @click="handleRecharge(scope.row)"
          >
            充值
          </el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="80">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleDetail(scope.row)">
            详情
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>

    <Pagination
      :limit.sync="queryParam.pageSize"
      :page.sync="queryParam.pageNumber"
      :total-count="totalCount"
      @pagination="loadData"
    />

    <Dialog
      :height="wdailog.height"
      :model-value="wdailog.visible"
      :title="wdailog.title"
      :visible="wdailog.visible"
      :width="wdailog.width"
      @handleCloseDialog="rechargeHandleClose"
      @handleConfirm="rechargeHandleConfirm"
    >
      <template #content>
        <el-form
          ref="rechargeData"
          label-width="100px"
          :model="rechargeData"
          :rules="rules"
          size="small"
          style="margin-top: 40px"
        >
          <el-form-item label="充值金额" prop="rechargeAmount">
            <el-input
              v-model="rechargeData.rechargeAmount"
              clearable
              :disabled="disabled"
              placeholder="请输入充值金额"
              style="max-width: 300px"
            />
          </el-form-item>
          <el-form-item label="赠送额度" prop="givingAmount">
            <el-input
              v-model="rechargeData.givingAmount"
              clearable
              :disabled="disabled"
              placeholder="请输入赠送额度"
              style="max-width: 300px"
            />
          </el-form-item>
        </el-form>
      </template>
    </Dialog>
    <DealDetail ref="detail" />
  </div>
</template>

<script>
  import { recharge } from '@/api/merchant/wallet'
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialog.vue'
  import DealDetail from './components/dealDetail.vue'
  import { BasePostMixin } from '@/mixins/BasePostMixin'
  export default {
    name: 'Wallet',
    components: {
      Pagination,
      Dialog,
      DealDetail,
    },
    mixins: [BasePostMixin],
    data() {
      return {
        disabled: false,
        wdailog: {
          height: 200,
          title: '',
          visible: false,
          width: '500px',
        },
        dailog: {
          height: 700,
          title: '',
          visible: false,
          width: '1000px',
        },
        formData: {
          code: '',
        },
        listQuery: {
          adminPhone: '',
          name: '',
          pageNumber: 1,
          pageSize: 10,
        },
        rules: {
          rechargeAmount: [
            {
              required: true,
              message: '请输入金额',
              trigger: 'blur',
            },
          ],
          givingAmount: [
            {
              required: true,
              message: '请输入金额',
              trigger: 'blur',
            },
          ],
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        rechargeData: { rechargeAmount: '', givingAmount: '' },
        selection: [],
        url: {
          list: '/admin/merchant/wallet/page',
        },
      }
    },
    methods: {
      //表格选择
      selectRow(selection, row) {
        this.selection = selection
        // console.log('单选===', selection)
        console.log('单选===', row)
      },
      //编辑
      handleDetail(row) {
        this.$refs['detail'].showDetail(row)
        // this.dailog.visible = true
        // this.dailog.title = '推送记录明细查看'
        // this.$nextTick(() => {
        //   //var onData = JSON.parse(JSON.stringify(row))
        //   console.log('onData>>>', row)
        //   //this.loading = true
        // })
      },
      handleRecharge(row) {
        this.wdailog.visible = true
        this.wdailog.title = '充值'
        this.$nextTick(() => {
          //var onData = JSON.parse(JSON.stringify(row))
          console.log('onData>>>', row)
          this.rechargeData.shopId = row.shopId
          // this.loading = true
        })
      },
      handleCloseDialog() {
        this.dailog.visible = false
      },
      rechargeHandleClose() {
        this.wdailog.visible = false
      },
      rechargeHandleConfirm() {
        //this.wdailog.visible = false
        this.$refs['rechargeData'].validate((valid) => {
          if (valid) {
            recharge(this.rechargeData).then((res) => {
              if (res.code === 0) {
                this.wdailog.visible = false
                this.loadData(1)
              }
            })
          }
        })
      },
      // 确认新增
      handleConfirm() {
        // console.log(this.formData, 588)
        this.$refs['formData'].validate((valid) => {
          // console.log(valid)
          if (valid) {
            // menuSave(this.formData).then((res) => {
            //   this.commonMessage(res)
            // })
          }
        })
      },
      // 状态赋值
      readStatusFormat(row) {
        // console.log('单选===', row)
        let state = row.readStatus
        if (state === 0) {
          return '否'
        } else if (state === 1) {
          return '是'
        }
      },
      refundStatusFormat(status) {
        // console.log('单选===', row)
        // let state = row.readStatus
        if (status === 0) {
          return '未退款'
        } else if (status === 1) {
          return '待处理'
        } else if (status === 2) {
          return '已同意'
        } else if (status === 3) {
          return '退款失败（拒绝）'
        } else if (status === 4) {
          return '已取消'
        } else if (status === 5) {
          return '退款成功'
        }
      },
      payStatusFormat(status) {
        if (status === 0) {
          return '未付款'
        } else if (status === 1) {
          return '已支付'
        }
      },
      paymentWayFormat(status) {
        if (status === 1) {
          return '小程序'
        } else if (status === 10) {
          return '现金'
        } else if (status === 11) {
          return '微信转账'
        } else if (status === 12) {
          return '支付宝转账'
        } else if (status === 13) {
          return '银联转账'
        } else if (status === 14) {
          return '刷卡支付'
        } else if (status === 99) {
          return '其他'
        }
      },
      statusFormat(status) {
        if (status === 10) {
          return '已完成'
        } else if (status === 20) {
          return '待回款'
        } else if (status === 30) {
          return '待生效'
        } else if (status === 40) {
          return '已逾期'
        } else if (status === 50) {
          return '已作废'
        }
      },
      //刷新
      refresh() {
        this.loadData()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-range-editor.el-input__inner {
    width: 105%;
  }
  .el-dialog .el-form-item__label {
    width: 150px !important;
  }
</style>
