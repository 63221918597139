<template>
  <div class="comprehensive-table-container index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          :model="queryParam"
          @submit.native.prevent
        >
          <el-form-item label="商户名称" label-width="80px">
            <el-input
              v-model="queryParam.shopName"
              clearable
              placeholder="请输入商户名称"
            />
          </el-form-item>
          <el-form-item label="联系方式" label-width="80px">
            <el-input
              v-model="queryParam.contactPhone"
              clearable
              placeholder="请输入联系方式"
            />
          </el-form-item>
          <el-form-item label="姓名" label-width="80px">
            <el-input
              v-model="queryParam.contactPerson"
              clearable
              placeholder="请输入联系人姓名"
            />
          </el-form-item>
          <el-form-item label="易票联商户状态" label-width="120px">
            <el-select
              v-model="queryParam.accountStatus"
              clearable
              placeholder="请选择易票联商户状态"
            >
              <el-option label="未开通" :value="0" />
              <el-option label="已开通" :value="1" />
            </el-select>
          </el-form-item>
          <el-form-item label="中信银行商户状态" label-width="140px">
            <el-select
              v-model="queryParam.citicStatus"
              clearable
              placeholder="请选择中信银行商户状态"
            >
              <el-option label="未开通" :value="0" />
              <el-option label="已开通" :value="1" />
            </el-select>
          </el-form-item>
          <el-form-item label="默认结算平台" label-width="120px">
            <el-select
              v-model="queryParam.paymentPlatform"
              clearable
              placeholder="请选择默认结算平台"
            >
              <el-option label="易票联" value="EPL" />
              <el-option label="中信银行" value="CITIC_BANK" />
            </el-select>
          </el-form-item>
          <el-form-item label="结算账户类型" label-width="120px">
            <el-select
              v-model="queryParam.merchantType"
              clearable
              placeholder="请选择结算账户类型"
            >
              <el-option label="个体工商户" :value="1" />
              <el-option label="企业" :value="2" />
              <el-option label="个人(小微)" :value="3" />
              <el-option label="政府事业单位" :value="4" />
              <el-option label="其他组织" :value="9" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="getList"
            >
              查询
            </el-button>

            <el-button icon="el-icon-refresh-left" @click="searchReset">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table v-loading="loading" border :data="dataSource" stripe>
      <el-table-column
        align="center"
        label="登录账户"
        prop="adminPhone"
        width="180"
      />
      <el-table-column
        align="center"
        label="账户类型"
        prop="merchantType"
        width="150"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.merchantType === 1">个体工商户</span>
          <span v-if="scope.row.merchantType === 2">企业</span>
          <span v-if="scope.row.merchantType === 3">个人(小微)</span>
          <span v-if="scope.row.merchantType === 4">政府事业单位</span>
          <span v-if="scope.row.merchantType === 9">其他组织</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="商户全称"
        prop="name"
        width="180"
      />
      <el-table-column
        align="center"
        label="联系人姓名"
        prop="contactPerson"
        width="180"
      />
      <el-table-column
        align="center"
        label="联系人手机号"
        prop="contactPhone"
        width="150"
      />
      <el-table-column
        align="center"
        label="默认结算平台"
        prop="paymentPlatform"
        width="150"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.paymentPlatform == 'EPL'">易票联</span>
          <span v-if="scope.row.paymentPlatform == 'CITIC_BANK'">中信银行</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="易票联商户状态"
        prop="accountStatus"
        width="180"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.accountStatus === 0" type="info">
            未开通
          </el-tag>
          <el-tag v-if="scope.row.accountStatus === 1" type="success">
            已开通
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="中信银行商户状态"
        prop="citicStatus"
        width="150"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.citicStatus === 0" type="info">未开通</el-tag>
          <el-tag v-if="scope.row.citicStatus === 1" type="success">
            已开通
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="e签宝商户状态"
        prop="citicStatus"
        width="150"
      >
        <template slot-scope="scope">
          {{ scope.row.esignStatus.registerStatus == 1 ? '已' : '未' }}注册、
          {{
            scope.row.esignStatus.authenticateStatus == 1 ? '已' : '未'
          }}认证、
          {{
            scope.row.esignStatus.authorizeStatus == 1
              ? `授权(剩余${scope.row.esignStatus.remainingTermOfAuthorization})`
              : '未授权'
          }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="注册时间"
        prop="createTime"
        width="180"
      />
      <el-table-column align="center" fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleDetail(scope.row)">
            详情
          </el-button>
          <el-button size="mini" type="text" @click="handleDispose(scope.row)">
            配置
          </el-button>
          <el-button
            size="mini"
            type="text"
            @click="handleEsignAuth(scope.row)"
          >
            e签宝重新实名
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>

    <Pagination
      :limit.sync="queryParam.pageSize"
      :page.sync="queryParam.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />

    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="updateStatus"
    >
      <template #content>
        <el-form
          ref="queryParam"
          label-position="平台审核意见"
          label-width="100px"
          :model="queryParam"
          size="small"
        >
          <el-row>
            <el-row>
              <el-col :span="24">
                <div class="aa">
                  <el-input
                    v-model="queryParam.opinion"
                    placeholder="平台审核意见"
                    :rows="6"
                    style="width: 100%; resize: none"
                    type="textarea"
                  />
                  <!-- <el-form-item
                                                                                              style="width: 100%; display: block; margin-left: 0px"
                                                                                            >
                                                                                              <el-input
                                                                                                v-model="queryParam.intro"
                                                                                                placeholder="平台审核意见"
                                                                                                :rows="4"
                                                                                                style="width: 100%"
                                                                                                type="textarea"
                                                                                              />
                                                                                            </el-form-item> -->
                </div>
              </el-col>
            </el-row>
            <!-- <el-row>
                                                                                        <el-col :span="24">
                                                                                          <el-button
                                                                                            icon="el-icon-search"
                                                                                            native-type="submit"
                                                                                            style="margin-top: 10px; width: 100%"
                                                                                            type="primary"
                                                                                            @click="updateStatus"
                                                                                          >
                                                                                            设置
                                                                                          </el-button>
                                                                                        </el-col>
                                                                                      </el-row> -->
          </el-row>
        </el-form>
      </template>
    </Dialog>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialog.vue'
  import { merchantApply } from '@/api/merchant/shopList'
  import { adminShop } from '@/api/merchant/newIndex'
  import { esignReRealNameAuth } from '@/api/esign/contract'
  export default {
    name: 'Shoplist',
    components: {
      Pagination,
      Dialog,
    },
    data() {
      return {
        disabled: false,
        dataSource: [],
        dailog: {
          height: 150,
          title: '',
          visible: false,
          width: '400px',
        },
        queryParam: {
          shopName: '',
          contactPhone: '',
          contactPerson: '',
          accountStatus: '',
          citicStatus: '',
          paymentPlatform: '',
          status: 1,
          merchantType: '',
          pageNumber: 1,
          pageSize: 20,
        },
        totalCount: 0,
        loading: false,
      }
    },
    created() {
      this.getList()
    },
    methods: {
      //配置
      handleDispose(row) {
        this.$nextTick(() => {
          this.$router.push({
            path: '/admin/merchant/detail?',
            query: { id: row.id, name: row.name, tab: 'configure' },
          })
        })
      },
      handleCloseDialog() {
        this.dailog.visible = false
      },
      // 确认新增
      handleConfirm() {
        console.log('handleConfirm:', this.queryParam, 588)
      },
      searchReset() {
        this.queryParam = {
          shopName: '',
          contactPhone: '',
          contactPerson: '',
          accountStatus: '',
          citicStatus: '',
          paymentPlatform: '',
          status: 1,
          merchantType: '',
          pageNumber: 1,
          pageSize: 20,
        }
        this.getList()
      },
      //查询
      getList() {
        adminShop(this.queryParam).then((res) => {
          this.dataSource = res.data.records
          this.totalCount = +res.data.total
          console.log(res, 'res')
        })
        // this.loadData()
      },
      //显示审核意见
      showOpinion(opinion) {
        this.$alert(opinion, '审核意见', {
          showConfirmButton: false,
        })
      },
      // 修改状态
      updateStatusShowDialog(row, newStatus) {
        console.log(newStatus)
        this.dailog.visible = true
        this.dailog.title = '平台审核意见'
        this.queryParam.id = row.id
        // this.queryParam.status = row.status
        this.queryParam.newStatus = newStatus
        console.log('row:', row)
      },
      updateStatus() {
        this.editParam = {
          id: this.queryParam.id,
          status: this.queryParam.newStatus,
          reason: this.queryParam.opinion,
        }
        // console.log('123', this.editParam)
        this.handleEdit()
        this.handleCloseDialog()
        this.queryParam.opinion = ''
      },
      // 修改状态
      merchantApply(row) {
        // console.log('row:', row)
        this.editParam = {
          id: row,
        }
        this.$confirm('确定要提交开户吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          // console.log('editParam:', this.editParam)
          this.loading = true
          console.log(merchantApply)
          merchantApply(this.editParam)
            .then((res) => {
              console.log('merchantApply>>>', res)
              if (res.code === 0) {
                this.$baseMessage(
                  '开通成功！',
                  'success',
                  'vab-hey-message-success'
                )
                this.loadData()
              }
            })
            .finally(() => {
              this.loading = false
            })
        })
      },
      //刷新
      refresh() {
        this.loadData()
      },
      //查看详情
      handleDetail(row) {
        this.$nextTick(() => {
          this.$router.push({
            path: '/admin/merchant/detail',
            query: { id: row.id },
          })
        })
      },
      handleEsignAuth(row) {
        this.$confirm(
          `确定对商户账号为${row.name}进行e钱包重新实名，确定后商户用户需要重新进行e签宝实名认证操作?`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'info',
          }
        ).then(() => {
          esignReRealNameAuth({ shopId: row.id }).then(() => {
            this.$message({
              type: 'success',
              message: '操作成功!',
            })
            this.getList()
          })
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .cursor {
    cursor: pointer;
  }

  .el-range-editor.el-input__inner {
    width: 105%;
  }

  .el-dialog .el-form-item__label {
    width: 150px !important;
  }

  .aa .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__labe {
    width: 100%;
    margin-left: 0px !important;
  }

  .el-form-item__content {
    margin-left: 0px !important;
  }
</style>
