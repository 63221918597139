<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="用户名称" prop="memberName">
            <el-input
              v-model="listQuery.memberName"
              clearable
              placeholder="请输入用户名称"
              style="max-width: 300px"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="getList">
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="reset">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <!-- <div class="top">
      <div class="left">
        <el-input
          v-model="listQuery.memberName"
          clearable
          placeholder="请输入用户名称"
          style="margin-right: 20px"
        />
        <el-button
          icon="el-icon-search"
          size="mini"
          type="primary"
          @click="getList"
        >
          查询
        </el-button>
      </div>
    </div> -->
    <el-table v-loading="loading" border :data="tableData">
      <!-- <el-table-column align="center" type="selection" width="50" /> -->
      <el-table-column align="center" label="序号" width="50">
        <template slot-scope="scope">
          <span>
            {{
              (listQuery.pageNumber - 1) * listQuery.pageSize + scope.$index + 1
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="用户ID" prop="memberId" />
      <el-table-column align="center" label="用户名称" prop="memberName" />
      <el-table-column align="center" label="访问名称" prop="name" />
      <el-table-column align="center" label="访问参数" prop="params" />
      <el-table-column align="center" label="创建时间" prop="createTime" />
      <el-table-column align="center" label="操作" width="80">
        <template slot-scope="scope">
          <el-button type="text" @click="detail(scope.row)">详情</el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <DetailVue
      v-if="detailVisible"
      :detail-visible.sync="detailVisible"
      :row-data="rowData"
    />
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />
  </div>
</template>

<script>
  import { memberVisitLogList } from '@/api/log/membervisit'
  import Pagination from '@/components/pagination.vue'
  import DetailVue from './detail.vue'
  export default {
    name: 'MemberVisitLog',
    components: {
      Pagination,
      DetailVue,
    },
    data() {
      return {
        detailVisible: false,
        rowData: {},
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          memberName: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      detail(row) {
        this.rowData = { ...row }
        this.detailVisible = true
      },
      reset() {
        this.listQuery.memberName = ''
        this.getList()
      },
      // 列表
      getList() {
        this.loading = true
        console.log(this.listQuery, 'this.listQuery')
        memberVisitLogList(this.listQuery).then((res) => {
          if (res.code === 0) {
            const { records, total } = res.data
            this.tableData = records
            this.totalCount = +total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .index-container {
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .left {
        display: flex;
      }
    }
  }

  :deep() {
    .el-table--border .el-table__cell:first-child .cell {
      padding-left: 0;
    }

    .el-table th.el-table__cell > .cell {
      padding-right: 0;
    }

    .el-table .cell {
      padding: 0;
    }
  }
</style>
