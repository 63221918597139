<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="商户名称" prop="shopName">
            <el-input
              v-model="listQuery.shopName"
              clearable
              placeholder="请输入商户名称"
              style="max-width: 300px"
            />
          </el-form-item>

          <el-form-item label="商户用户" label-width="90px" prop="shopUser">
            <el-input
              v-model="listQuery.shopUser"
              clearable
              placeholder="请输入商户用户"
              style="max-width: 300px"
            />
          </el-form-item>

          <el-form-item label="访问名称" label-width="90px" prop="name">
            <el-input
              v-model="listQuery.name"
              clearable
              placeholder="请输入访问名称"
              style="max-width: 300px"
            />
          </el-form-item>

          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="getList">
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="reset">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>
    <!-- <div class="top">
      <div class="left">
        <el-input
          v-model="listQuery.shopName"
          clearable
          placeholder="请输入商户名称"
          style="margin-right: 20px"
        />
        <el-input
          v-model="listQuery.shopUser"
          clearable
          placeholder="请输入商户用户"
          style="margin-right: 20px"
        />
        <el-input
          v-model="listQuery.name"
          clearable
          placeholder="请输入访问名称"
          style="margin-right: 20px"
        />
        <el-button
          icon="el-icon-search"
          size="mini"
          type="primary"
          @click="getList"
        >
          查询
        </el-button>
      </div>
    </div> -->
    <el-table v-loading="loading" border :data="tableData">
      <!-- <el-table-column align="center" type="selection" width="50" /> -->
      <el-table-column align="center" label="序号" width="50">
        <template slot-scope="scope">
          <span>
            {{
              (listQuery.pageNumber - 1) * listQuery.pageSize + scope.$index + 1
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="商户名称" prop="shopName" />
      <el-table-column align="center" label="商户用户" prop="shopUser" />
      <el-table-column align="center" label="访问名称" prop="name" />
      <el-table-column align="center" label="创建时间" prop="createTime" />
      <el-table-column align="center" fixed="right" label="操作" width="80">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="queryDetailById(scope.row.id)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />

    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
    >
      <template #content>
        <el-descriptions border class="margin-top" :column="2" size="medium">
          <el-descriptions-item>
            <template slot="label">商户名称</template>
            {{ formData.shopName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">商户用户</template>
            {{ formData.shopUser }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">访问路径</template>
            {{ formData.actionUrl }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">访问名称</template>
            {{ formData.name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">访问参数</template>
            {{ formData.params }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">ua</template>
            {{ formData.ua }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">访问IP</template>
            {{ formData.ip }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">访问时间</template>
            {{ formData.createTime }}
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </Dialog>
  </div>
</template>

<script>
  import { shopActionLogList, queryDetail } from '@/api/log/shopaction'
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialogOther.vue'
  export default {
    name: 'ShopActionLog',
    components: {
      Pagination,
      Dialog,
    },
    data() {
      return {
        dailog: {
          height: 300,
          title: '',
          visible: false,
          width: '700px',
        },
        formData: {
          actionUrl: '',
          createTime: '',
          id: '',
          ip: '',
          name: '',
          params: '',
          shopId: '',
          shopName: '',
          shopUser: '',
          shopUserId: '',
          ua: '',
        },
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          shopName: '',
          shopUser: '',
          name: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      reset() {
        this.listQuery.shopUser = ''
        this.listQuery.shopName = ''
        this.listQuery.name = ''
        this.getList()
      },
      // 列表
      getList() {
        this.loading = true
        shopActionLogList(this.listQuery).then((res) => {
          if (res.code === 0) {
            const { records, total } = res.data
            this.tableData = records
            this.totalCount = +total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      queryDetailById(id) {
        console.log(id)
        queryDetail({ id }).then((res) => {
          console.log(res)
          this.formData = res.data
        })
        this.dailog.visible = true
        this.dailog.title = '商户操作日志详情查看'
      },
      handleCloseDialog() {
        this.dailog.visible = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .index-container {
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .left {
        display: flex;
      }
    }
  }

  :deep() {
    .el-table--border .el-table__cell:first-child .cell {
      padding-left: 0;
    }
    .el-table th.el-table__cell > .cell {
      padding-right: 0;
    }
    .el-table .cell {
      padding: 0;
    }
  }
</style>
