var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        title: _vm.dialogType === "add" ? "新增短信套餐" : "修改短信套餐",
        visible: _vm.smsComboVisible,
        width: "500px",
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            "label-width": "100px",
            model: _vm.ruleForm,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "套餐名称", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入套餐名称" },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "name", $$v)
                  },
                  expression: "ruleForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "充值金额(元)", prop: "price" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入充值金额" },
                model: {
                  value: _vm.ruleForm.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "price", $$v)
                  },
                  expression: "ruleForm.price",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "短信数量", prop: "quantity" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入短信数量" },
                model: {
                  value: _vm.ruleForm.quantity,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "quantity", $$v)
                  },
                  expression: "ruleForm.quantity",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入备注", type: "textarea" },
                model: {
                  value: _vm.ruleForm.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "remark", $$v)
                  },
                  expression: "ruleForm.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleConfirm } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }