<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    title="短信充值"
    :visible="rechargeVisible"
    width="500px"
  >
    <el-form
      ref="ruleForm"
      class="demo-ruleForm"
      label-width="100px"
      :model="ruleForm"
    >
      <el-form-item label="充值账户" prop="name">
        {{ rowData.shopName }}/{{ rowData.adminPhone }}
      </el-form-item>
      <el-form-item label="短信剩余余量" prop="price">
        {{ rowData.smsQuantity }}条
      </el-form-item>
      <el-form-item label="充值套餐" prop="quantity">
        <span style="color: red">0.1</span>
        元/条
      </el-form-item>
      <el-form-item label="支付方式" prop="remark">
        <el-radio v-model="payWay" border label="1">微信支付</el-radio>
        <el-radio v-model="payWay" border label="2">余额支付</el-radio>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">立即充值</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    name: 'Dialog',
    props: {
      rechargeVisible: {
        type: Boolean,
        default: false,
      },
      rowData: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data() {
      return {
        ruleForm: {
          name: '',
          price: '',
          quantity: '',
          remark: '',
        },
        payWay: '1',
      }
    },
    mounted() {
      this.ruleForm = { ...this.ruleForm, ...this.rowData }
    },
    methods: {
      // 确定新增/修改
      handleConfirm() {
        this.$emit('update:rechargeVisible', false)
      },
      // 取消
      handleClose() {
        this.$emit('update:rechargeVisible', false)
      },
    },
  }
</script>

<style lang="scss">
  .container {
    overflow-x: initial;
    overflow-y: auto;
  }

  .el-dialog {
    border-top-left-radius: 7px !important;
    border-top-right-radius: 7px !important;

    .el-dialog__header {
      margin-right: 0;
      background-color: #1890ff !important;
      border-top-left-radius: 7px !important;
      border-top-right-radius: 7px !important;

      .el-dialog__title {
        font-size: 16px;
        font-weight: 600;
        color: #fff;
      }

      .el-dialog__close {
        color: #fff !important;
      }
    }

    .el-dialog__body {
      padding: 30px 10px;
    }

    .el-dialog__footer {
      padding: 10px;
      border-top: 1px solid #e8eaec !important;
    }

    .el-icon svg {
      color: #fff;
    }
  }
</style>
