var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-top-panel",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    inline: true,
                    "label-width": "110px",
                    model: _vm.listQuery,
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "地区" } },
                    [
                      _c("el-cascader", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          clearable: "",
                          options: _vm.areaList,
                          placeholder: "请选择地区",
                          props: _vm.areaProps,
                        },
                        on: { change: _vm.handleChange },
                        model: {
                          value: _vm.areaCode,
                          callback: function ($$v) {
                            _vm.areaCode = $$v
                          },
                          expression: "areaCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户角色" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.listQuery.role,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "role", $$v)
                            },
                            expression: "listQuery.role",
                          },
                        },
                        _vm._l(_vm.roleList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户姓名" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入用户姓名" },
                        model: {
                          value: _vm.listQuery.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "name", $$v)
                          },
                          expression: "listQuery.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入手机号" },
                        model: {
                          value: _vm.listQuery.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "phone", $$v)
                          },
                          expression: "listQuery.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "身份证号" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入身份证号" },
                        model: {
                          value: _vm.listQuery.idCard,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "idCard", $$v)
                          },
                          expression: "listQuery.idCard",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "微信绑定状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.listQuery.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "status", $$v)
                            },
                            expression: "listQuery.status",
                          },
                        },
                        _vm._l(_vm.statusList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-search", type: "primary" },
                          on: { click: _vm.getList },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-refresh-left" },
                          on: { click: _vm.reset },
                        },
                        [_vm._v(" 重置 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-refresh",
                            loading: _vm.syncWxUserLoading,
                            type: "primary",
                          },
                          on: { click: _vm.syncWxMpUser },
                        },
                        [_vm._v(" 同步微信信息 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { border: "", data: _vm.tableData, stripe: "" },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png"),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "序号", width: "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm.listQuery.pageNumber - 1) *
                            _vm.listQuery.pageSize +
                            scope.$index +
                            1
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "姓名",
              prop: "name",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "手机",
              prop: "phone",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "身份证号",
              prop: "idcard",
              width: "200",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "昵称",
              prop: "nickname",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "省/市/区",
              "show-overflow-tooltip": "",
              width: "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(scope.row.province || "") +
                        " / " +
                        _vm._s(scope.row.city || "") +
                        " / " +
                        _vm._s(scope.row.district || "") +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "微信绑定状态", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status === 0
                      ? _c("el-tag", [_vm._v("未绑定")])
                      : _vm._e(),
                    scope.row.status === 1
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("已绑定"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "用户角色" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(
                    _vm.roleNamesCut(scope.row.roles),
                    function (item) {
                      return _c("el-tag", { key: item }, [
                        _vm._v(" " + _vm._s(item) + " "),
                        _c("br"),
                      ])
                    }
                  )
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "e签宝商户状态",
              prop: "citicStatus",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.esignStatusDTO.registerStatus == 1
                            ? "已"
                            : "未"
                        ) +
                        "注册、 " +
                        _vm._s(
                          scope.row.esignStatusDTO.authenticateStatus == 1
                            ? "已"
                            : "未"
                        ) +
                        "认证、 " +
                        _vm._s(
                          scope.row.esignStatusDTO.authorizeStatus == 1
                            ? `授权(剩余${scope.row.esignStatusDTO.remainingTermOfAuthorization})`
                            : "未授权"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "创建时间",
              prop: "createTime",
              width: "200",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDetail(scope.row.id)
                          },
                        },
                      },
                      [_vm._v(" 详情 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(scope.row.id)
                          },
                        },
                      },
                      [_vm._v(" 设置用户组 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          limit: _vm.listQuery.pageSize,
          page: _vm.listQuery.pageNumber,
          "total-count": _vm.totalCount,
        },
        on: {
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "pageSize", $event)
          },
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "pageNumber", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c("Dialog", {
        attrs: {
          height: _vm.dailog.height,
          "model-value": _vm.dailog.visible,
          showfoot: _vm.showfoot,
          title: _vm.dailog.title,
          visible: _vm.dailog.visible,
          width: _vm.dailog.width,
        },
        on: {
          handleCloseDialog: _vm.handleCloseDialog,
          handleConfirm: _vm.handleConfirm,
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-descriptions",
                  {
                    staticClass: "margin-top",
                    attrs: { border: "", column: 2, size: "medium" },
                  },
                  [
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v("姓名")]),
                        _vm._v(" " + _vm._s(_vm.formData.name) + " "),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v("性别")]),
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("sexFormat")(_vm.formData.sex)) +
                            " "
                        ),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v("身份证号")]),
                        _vm._v(" " + _vm._s(_vm.formData.idcard) + " "),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v("手机")]),
                        _vm._v(" " + _vm._s(_vm.formData.phone) + " "),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v("出生日期")]),
                        _vm._v(" " + _vm._s(_vm.formData.birthDate) + " "),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v("职业")]),
                        _vm._v(" " + _vm._s(_vm.formData.profession) + " "),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [
                          _vm._v("微信绑定状态"),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formData.status === 0 ? "未绑定 " : "已绑定"
                            ) +
                            " "
                        ),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v("用户类型")]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formData.type === 1 ? "普通用户" : "租户"
                            ) +
                            " "
                        ),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v("国家")]),
                        _vm._v(" " + _vm._s(_vm.formData.country) + " "),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v("省份")]),
                        _vm._v(" " + _vm._s(_vm.formData.province) + " "),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v("地市")]),
                        _vm._v(" " + _vm._s(_vm.formData.city) + " "),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v("区县")]),
                        _vm._v(" " + _vm._s(_vm.formData.district) + " "),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v("微信号")]),
                        _vm._v(" " + _vm._s(_vm.formData.weixin) + " "),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v("微信头像")]),
                        _c("el-avatar", {
                          attrs: { src: _vm.formData.avatar },
                        }),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v("微信昵称")]),
                        _vm._v(" " + _vm._s(_vm.formData.weixin) + " "),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v("角色")]),
                        _vm._l(_vm.formData.rolesList, function (role, index) {
                          return _c("span", { key: index }, [
                            _vm._v(
                              " " +
                                _vm._s(role.checked ? role.roleName : "无") +
                                " "
                            ),
                          ])
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Dialog", {
        attrs: {
          height: _vm.setGroupDailog.height,
          "model-value": _vm.setGroupDailog.visibleSetGroup,
          title: _vm.setGroupDailog.title,
          visible: _vm.setGroupDailog.visibleSetGroup,
        },
        on: {
          handleCloseDialog: _vm.handleCloseDialog,
          handleConfirm: _vm.handleConfirm,
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    ref: "formData",
                    attrs: {
                      "label-width": "100px",
                      model: _vm.formData,
                      size: "small",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "用户名", prop: "avatar" } },
                          [_vm._v(" " + _vm._s(_vm.formData.name) + " ")]
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "手机号码", prop: "phone" } },
                          [_vm._v(" " + _vm._s(_vm.formData.phone) + " ")]
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "角色" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  plain: "",
                                  size: "mini",
                                  type: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleCheckAllChange(_vm.val)
                                  },
                                },
                              },
                              [_vm._v(" 全选 ")]
                            ),
                            _c(
                              "el-checkbox-group",
                              {
                                model: {
                                  value: _vm.checkList,
                                  callback: function ($$v) {
                                    _vm.checkList = $$v
                                  },
                                  expression: "checkList",
                                },
                              },
                              _vm._l(
                                _vm.formData.rolesList,
                                function (role, index) {
                                  return _c(
                                    "el-checkbox",
                                    {
                                      key: index,
                                      staticStyle: { display: "block" },
                                      attrs: { label: role.code },
                                    },
                                    [_vm._v(" " + _vm._s(role.roleName) + " ")]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }