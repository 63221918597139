<template>
  <div class="index-container sms-buy">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="80px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="交易编号">
            <el-input
              v-model="listQuery.tradeNo"
              clearable
              placeholder="商户名称"
            />
          </el-form-item>
          <el-form-item label="商户名称">
            <el-input
              v-model="listQuery.shopName"
              clearable
              placeholder="商户名称"
            />
          </el-form-item>
          <el-form-item label="登录帐号">
            <el-input
              v-model="listQuery.adminPhone"
              clearable
              placeholder="登录帐号"
            />
          </el-form-item>
          <el-form-item label="支付方式">
            <el-select v-model="listQuery.paymentMethod" placeholder="请选择">
              <el-option label="全部" value="" />
              <el-option label="微信支付" value="WECHAT" />
              <el-option label="钱包余额" value="BALANCE" />
            </el-select>
          </el-form-item>
          <el-form-item label="充值时间">
            <el-date-picker
              v-model="payTime"
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetQuery">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @selection-change="setSelectRows"
    >
      <!-- <el-table-column align="center" type="selection" width="50" /> -->
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          <span>
            {{ (listQuery.pageNumber - 1) * listQuery.pageSize + $index + 1 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="交易编号" prop="tradeNo" />
      <el-table-column align="center" label="商户名称" prop="shopName" />
      <el-table-column align="center" label="登录帐号" prop="adminPhone" />
      <el-table-column align="center" label="购买套餐" prop="productName" />
      <el-table-column align="center" label="支付方式">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.paymentMethod === 'WECHAT'" type="success">
            微信支付
          </el-tag>
          <el-tag v-else type="primary">钱包余额</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="剩余数量(充值前)"
        prop="frontCount"
        width="140"
      />
      <el-table-column
        align="center"
        label="剩余数量(充值后)"
        prop="afterCount"
        width="140"
      />
      <el-table-column
        align="center"
        label="充值时间"
        prop="payTime"
        width="150"
      />
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <div class="statistics-box">
      <span class="statistics-label">合计充值金额：</span>
      <span class="statistics-number">{{ statistics.paymentAmount }}</span>
      <span class="statistics-label">元</span>
      <span class="statistics-label statistics-label-right">
        合计充值短信数量：
      </span>
      <span class="statistics-number">{{ statistics.smsCount }}</span>
      <span class="statistics-label">条</span>
    </div>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="fetchData"
    />
  </div>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import { getSmsOrder, getSmsOrderStatistics } from '@/api/smsBuy/index'
  const dayjs = require('dayjs')
  import { numberToCurrencyNo } from '../../utils/index'
  export default {
    name: 'SmsBuy',
    components: {
      Pagination,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        payTime: null,
        statistics: { paymentAmount: 0, smsCount: 0 },
        listQuery: {
          tradeNo: '',
          pageNumber: 1,
          pageSize: 20,
          shopName: '',
          adminPhone: '',
          paymentMethod: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        options: [],
        fold: false,
      }
    },
    beforeMount() {
      window.addEventListener('resize', this.handleHeight)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleHeight)
    },
    created() {
      if (this.$route.query?.adminPhone) {
        this.listQuery.adminPhone = this.$route.query?.adminPhone
      }
      this.fetchData()
      this.getSmsOrderStatisticsData()
    },
    methods: {
      getSmsOrderStatisticsData() {
        let params = {
          ...this.queryForm,
          ...this.listQuery,
          gePayTime: this.payTime
            ? dayjs(this.payTime[0]).format('YYYY-MM-DD')
            : undefined,
          lePayTime: this.payTime
            ? dayjs(this.payTime[1]).format('YYYY-MM-DD')
            : undefined,
        }
        delete params.pageNumber
        delete params.pageSize
        getSmsOrderStatistics(params).then((res) => {
          this.statistics = {
            paymentAmount: numberToCurrencyNo(res.data.paymentAmount / 100),
            smsCount: numberToCurrencyNo(res.data.smsCount),
          }
        })
      },
      handleFold() {
        this.fold = !this.fold
        this.handleHeight()
      },
      handleHeight() {
        if (this.fold) this.height = this.$baseTableHeight(2) - 47
        else this.height = this.$baseTableHeight(3) - 30
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleview(row) {
        this.$router.push({
          path: '/merchant/device/manage',
          query: {
            id: row.id,
            shopName: row.shopName,
          },
        })
      },

      handleQuery() {
        this.listQuery.pageNumber = 1
        this.fetchData()
        this.getSmsOrderStatisticsData()
      },
      async fetchData() {
        let params = {
          ...this.listQuery,
          gePayTime: this.payTime
            ? dayjs(this.payTime[0]).format('YYYY-MM-DD')
            : undefined,
          lePayTime: this.payTime
            ? dayjs(this.payTime[1]).format('YYYY-MM-DD')
            : undefined,
        }
        if (this.loading) return
        try {
          this.loading = true
          const {
            data: { records, total },
          } = await getSmsOrder({ ...this.queryForm, ...params })
          this.tableData = records
          this.totalCount = +total
        } finally {
          this.loading = false
        }
      },
      toggleSelection(rows) {
        if (rows) {
          rows.forEach((row) => {
            this.$refs.tableSort.toggleRowSelection(row)
          })
        } else {
          this.$refs.tableSort.clearSelection()
        }
      },
      //重置
      resetQuery() {
        this.listQuery = {
          pageNumber: 1,
          pageSize: 20,
          shopName: '',
          adminPhone: '',
          paymentMethod: '',
          tradeNo: '',
        }
        this.payTime = null
        this.fetchData()
        this.getSmsOrderStatisticsData()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .sms-buy {
    .statistics-box {
      margin-top: 10px;

      .statistics-label {
        font-weight: 500;
      }

      .statistics-number {
        font-weight: 500;
        color: red;
      }

      .statistics-label-right {
        margin-left: 20px;
      }
    }
  }

  .box-card.el-card {
    border: none !important;
  }
</style>
