<template>
  <div>
    <el-form
      ref="formData"
      label-width="150px"
      :model="formData"
      :rules="rules"
      size="small"
    >
      <el-col :span="24" style="height: 50px">
        <el-form-item label="商户名称">
          {{ name }}
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="交易比率" prop="stageFeeRate">
          <el-input
            v-model="formData.stageFeeRate"
            clearable
            :disabled="disabled"
            style="max-width: 300px"
            type="number"
          />
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="保底手续费" prop="feeMin">
          <el-input
            v-model="formData.feeMin"
            :disabled="disabled"
            min="0"
            style="max-width: 300px"
            type="number"
          />
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="退款手续费费率" prop="refundFeeRate">
          <el-input
            v-model="formData.refundFeeRate"
            :disabled="disabled"
            min="0"
            style="max-width: 300px"
            type="number"
          />
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="最大单笔交易金额" prop="maxTxsAmount">
          <el-input
            v-model="formData.maxTxsAmount"
            :disabled="disabled"
            min="0"
            style="max-width: 300px"
            type="number"
          />
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="结算周期" prop="settleCycle">
          <el-input
            v-model="formData.settleCycle"
            :disabled="disabled"
            style="max-width: 300px"
          />
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="易票联商户号" prop="acqMerId">
          <el-input
            v-model="formData.acqMerId"
            clearable
            :disabled="disabled"
            style="max-width: 300px"
          />
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="单笔交易计费" prop="stageFeePer">
          <el-input
            v-model="formData.stageFeePer"
            clearable
            :disabled="disabled"
            min="0"
            style="max-width: 300px"
            type="number"
          />
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="封顶手续费" prop="feeMax">
          <el-input
            v-model="formData.feeMax"
            :disabled="disabled"
            min="0"
            style="max-width: 300px"
            type="number"
          />
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="退款手续费单笔" prop="refundFeePer">
          <el-input
            v-model="formData.refundFeePer"
            :disabled="disabled"
            min="0"
            style="max-width: 300px"
            type="number"
          />
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="最小单笔交易金额" prop="minTxsAmount">
          <el-input
            v-model="formData.minTxsAmount"
            :disabled="disabled"
            min="0"
            style="max-width: 300px"
            type="number"
          />
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="允许退款" prop="refundEnabled">
          <el-radio-group v-model="formData.refundEnabled">
            <el-radio
              v-for="item in refundEnabledList"
              :key="item.value"
              :label="item.value"
            >
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="允许信用卡支付" prop="creditcardsEnabled">
          <el-radio-group v-model="formData.creditcardsEnabled">
            <el-radio
              v-for="item in creditcardsEnabledList"
              :key="item.value"
              :label="item.value"
            >
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item>
          <el-button size="mini" @click="cancel()">取消</el-button>
          <el-button size="mini" @click="resetData()">重置</el-button>
          <el-button size="mini" type="primary" @click="submitData()">
            确定
          </el-button>
        </el-form-item>
      </el-col>
    </el-form>
  </div>
</template>

<script>
  import { rateDetail, rateAdd, rateUpdate } from '@/api/merchant/index'
  export default {
    name: 'RateSetting',
    props: {
      id: {
        type: String,
        default: '',
      },
      name: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        disabled: false,
        dateId: '',
        dataInfo: {},
        formData: {},
        rules: {
          shopId: { required: true, message: '请选择', trigger: 'blur' },
          code: {
            required: true,
            message: '请输入',
            trigger: 'blur',
          },
          refundEnabled: {
            required: true,
            message: '请输入',
            trigger: 'change',
          },
          refundFeeRate: {
            required: true,
            message: '请输入',
            trigger: 'blur',
          },
          refundFeePer: {
            required: true,
            message: '请输入',
            trigger: 'blur',
          },
          creditcardsEnabled: {
            required: true,
            message: '请输入',
            trigger: 'change',
          },
          settleCycle: {
            required: true,
            message: '请输入',
            trigger: 'blur',
          },
        },
        creditcardsEnabledList: [
          {
            label: '禁用 ',
            value: 0,
          },
          {
            label: '允许 ',
            value: 1,
          },
        ],
        refundEnabledList: [
          {
            label: '不允许 ',
            value: 0,
          },
          {
            label: '允许 ',
            value: 1,
          },
        ],
      }
    },
    created() {
      this.getRateData()
    },
    methods: {
      async getRateData() {
        let param = {
          id: this.id,
        }
        const res = await rateDetail(param)
        let data = res.data || {}
        this.dataInfo = Object.assign({}, data)
        this.formData = data
        this.dateId = data.id
      },
      async cancel() {
        await this.$router.push('/admin/merchant/shopList')
      },
      resetData() {
        this.$message.success('重置成功')
        this.formData = Object.assign({}, this.dataInfo)
      },
      submitData() {
        this.$refs['formData'].validate(async (valid) => {
          if (valid) {
            this.formData.shopId = this.id
            if (this.dateId) {
              this.formData.id = this.dateId
              let res = await rateUpdate(this.formData)
              if (res.code == 0) {
                this.$message.success('提交成功')
              }
            } else {
              let res = await rateAdd(this.formData)
              if (res.code == 0) {
                this.$message.success('新增成功')
              } else {
                this.$message.error(res.msg)
              }
            }
          }
        })
      },
    },
  }
</script>
