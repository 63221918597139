var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        title: "结算账户详情",
        visible: _vm.dialogVisible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-descriptions",
        {
          staticClass: "margin-top",
          attrs: { border: "", column: 2, size: "medium" },
        },
        [
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("易票联审核状态")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.auditStatusFormat(_vm.accountDetailInfo?.auditStatus)
                  ) +
                  " "
              ),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("易票联商户编号")]),
              _vm._v(" " + _vm._s(_vm.accountDetailInfo?.acqMerId) + " "),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("结算账号类型")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.settleAccountTypeFormat(
                      _vm.accountDetailInfo?.settleAccountType
                    )
                  ) +
                  " "
              ),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("开户人")]),
              _vm._v(" " + _vm._s(_vm.accountDetailInfo?.settleAccount) + " "),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("银行卡号")]),
              _vm._v(" " + _vm._s(_vm.accountDetailInfo?.bankCardNo) + " "),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("开户银行")]),
              _vm._v(" " + _vm._s(_vm.accountDetailInfo?.openBank) + " "),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("开户支行")]),
              _vm._v(" " + _vm._s(_vm.accountDetailInfo?.openSubBank) + " "),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("开户银联号")]),
              _vm._v(" " + _vm._s(_vm.accountDetailInfo?.openBankCode) + " "),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("结算方式")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.settleTargetTypeFormat(
                      _vm.accountDetailInfo?.settleTarget
                    )
                  ) +
                  " "
              ),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("创建时间")]),
              _vm._v(" " + _vm._s(_vm.accountDetailInfo?.createTime) + " "),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("银行卡附件")]),
              _vm.accountDetailInfo.settleAttachment
                ? _c("el-image", {
                    staticStyle: { width: "200px", height: "100px" },
                    attrs: {
                      "preview-src-list": [
                        _vm.accountDetailInfo.settleAttachment,
                      ],
                      src: _vm.accountDetailInfo.settleAttachment,
                    },
                  })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }