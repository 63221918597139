<template>
  <div class="index-container">
    <el-form label-width="100px" :model="listQuery" size="small">
      <el-row>
        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 8 }"
        >
          <el-form-item label="角色名称">
            <el-input
              v-model="listQuery.name"
              clearable
              placeholder="请输入角色名称"
              style="margin-right: 20px"
            />
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 8 }"
        >
          <el-form-item>
            <el-button
              icon="el-icon-search"
              size="mini"
              type="primary"
              @click="getList"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" size="mini" @click="reset">
              重置
            </el-button>
            <el-button
              icon="el-icon-plus"
              size="mini"
              type="primary"
              @click="handleAdd"
            >
              新增
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @select="selectRow"
      @select-all="selectRow"
    >
      <!-- <el-table-column align="center" type="selection" width="50" /> -->
      <el-table-column align="center" label="序号" width="50">
        <template slot-scope="scope">
          <span>
            {{
              (listQuery.pageNumber - 1) * listQuery.pageSize + scope.$index + 1
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="角色" prop="name" />
      <el-table-column align="center" label="编码" prop="id" />
      <el-table-column align="center" fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleEdit(scope.row)">
            编辑
          </el-button>
          <el-button
            size="mini"
            type="text"
            @click="handleSetting(scope.row.id)"
          >
            分配权限
          </el-button>
          <el-button
            size="mini"
            type="text"
            @click="handleDelete(scope.row.id)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form
          ref="formData"
          label-width="100px"
          :model="formData"
          :rules="rules"
          size="small"
        >
          <el-row>
            <el-form-item label="角色名称 :" prop="name">
              <el-input
                v-model="formData.name"
                clearable
                placeholder="请输入角色名称"
                style="max-width: 200px"
              />
            </el-form-item>
            <el-form-item label="状态 :" prop="status">
              <el-select
                v-model="formData.status"
                placeholder="请选择"
                style="width: 200px"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-row>
        </el-form>
      </template>
    </Dialog>
    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :visible="dailog.visibleAssign"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-tree
          ref="tree"
          :check-strictly="checkStrictly"
          :data="treeData"
          :default-checked-keys="assignTreeData"
          default-expand-all
          empty-text="暂无数据"
          :highlight-current="true"
          node-key="id"
          :props="{
            label: 'name',
            children: 'children',
          }"
          :show-checkbox="true"
          @check="check"
        />
      </template>
    </Dialog>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />
  </div>
</template>

<script>
  import store from '@/store'
  import {
    getRoleAPI,
    addRoleAPI,
    pudateRoleAPI,
    deleteRoleAPI,
    getMenusAPI,
    updateRoleAPI,
  } from '@/api/system/newRole'
  import Dialog from '@/components/dialog.vue'
  import Pagination from '@/components/pagination.vue'
  export default {
    name: 'Role',
    components: {
      Dialog,
      Pagination,
    },
    data() {
      return {
        totalCount: 0,
        listQuery: {
          name: '',
          pageNumber: 1,
          pageSize: 20,
        },
        formData: {
          name: '',
          status: '',
          remark: '',
        },
        dailog: {
          height: 130,
          title: '',
          visible: false,
          visibleAssign: false,
          width: '400px',
        },
        tableData: [],
        options: [
          { value: 1, label: '是' },
          { value: 0, label: '否' },
        ],
        rules: {
          name: [
            { required: true, message: '请输入角色名称', trigger: 'blur' },
          ],
          status: [
            { required: true, message: '请选择角色状态', trigger: 'change' },
          ],
        },
        loading: false,
        treeData: [], // 菜单树集合
        assignTreeData: [], // 菜单id集合
        halfNodeIds: [], // 半选中父级节点集合
        roleId: '',
        checkStrictly: false,
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      reset() {
        for (let key in this.listQuery) {
          this.listQuery[key] = ''
        }
        this.listQuery.pageNumber = 1
        this.listQuery.pageSize = 20
        this.getList()
      },
      // 列表
      getList() {
        this.loading = true
        getRoleAPI(this.listQuery).then((res) => {
          if (res.code === 0) {
            const { records, total } = res.data
            this.tableData = records
            this.totalCount = +total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      // 新增
      handleAdd() {
        this.dailog.visible = true
        this.dailog.title = '新增角色'
      },
      // 删除
      handleDelete(id) {
        // if (id === '1') {
        //   this.$message({
        //     type: 'warning',
        //     message: '管理员不可删除!',
        //   })
        //   return
        // }
        this.$confirm('是否删除该角色?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          deleteRoleAPI(id).then((res) => {
            this.commonMessage(res)
            console.log(res)
          })
        })
      },
      // 编辑
      handleEdit(row) {
        this.dailog.height = 120
        this.dailog.visible = true
        this.dailog.title = '编辑角色'
        this.$nextTick(() => {
          this.formData = JSON.parse(JSON.stringify(row))
        })
      },
      // 递归遍历找出所有的id
      findIds(treeData, arr = []) {
        for (let item of treeData) {
          if (item.checked) {
            arr.push(item.id)
          }
          if (item.children && item.children.length)
            this.findIds(item.children, arr)
        }
        return arr
      },
      // 权限分配
      handleSetting(id) {
        this.dailog.height = 300
        this.dailog.visibleAssign = true
        this.dailog.title = '角色权限分配'
        this.roleId = id
        // 根据角色id获取对应的菜单
        getMenusAPI(id).then((res) => {
          if (res.code === 0) {
            this.treeData = res.data
            this.checkStrictly = true
            this.$nextTick(() => {
              this.assignTreeData = this.findIds(res.data)
              console.log(
                '🚀 ~ file: role.vue:316 ~ this.$nextTick ~ this.assignTreeData',
                this.assignTreeData
              )
              this.$refs.tree.setCheckedKeys(this.assignTreeData) //给树节点赋值回显
              this.checkStrictly = false //重点： 赋值完成后 设置为false
            })
          }
        })
      },
      check(node, data) {
        console.log(node)
        console.log(data)
        // this.checkStrictly = false
        this.halfNodeIds = data.halfCheckedKeys
      },
      // 确定新增/修改
      handleConfirm() {
        if (this.dailog.title === '角色权限分配') {
          const currentNodeList = this.$refs.tree.getCheckedNodes()
          console.log(currentNodeList)
          const arr = []
          currentNodeList.forEach((item) => {
            arr.push(item.id)
          })
          let ids = arr.concat(this.halfNodeIds)
          console.log(ids)
          if (!ids.length) {
            this.$message({
              type: 'warning',
              message: '不能为空!',
            })
          }
          updateRoleAPI({ menuIdList: ids }, this.roleId).then(async (res) => {
            this.commonMessage(res)
            this.handleCloseDialog()
            const reload = await store.dispatch('routes/getMenuList') // (新改造)
            await store.dispatch('routes/setRoutes', reload)
          })
        } else {
          this.$refs['formData'].validate((valid) => {
            if (valid) {
              if (this.dailog.title === '新增角色') {
                addRoleAPI({
                  name: this.formData.name,
                  status: this.formData.status,
                }).then((res) => {
                  this.commonMessage(res)
                  this.handleCloseDialog()
                })
              } else {
                pudateRoleAPI(this.formData).then((res) => {
                  this.commonMessage(res)
                  this.handleCloseDialog()
                })
              }
            }
          })
        }
      },
      handleCloseDialog() {
        if (this.dailog.title === '角色权限分配') {
          this.dailog.visibleAssign = false
          this.checkStrictly = false
          this.halfNodeIds = []
        } else {
          this.$refs['formData'].resetFields()
          this.dailog.visible = false
        }
      },
      selectRow(selection, row) {
        console.log('单选===', selection)
        console.log('单选===', row)
      },
      commonMessage(res) {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.msg,
          })
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  :deep() {
    .el-table--border .el-table__cell:first-child .cell {
      padding-left: 0;
    }
    .el-table th.el-table__cell > .cell {
      padding-right: 0;
    }
    .el-table .cell {
      padding: 0;
    }
  }
</style>
